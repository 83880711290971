import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { bottomBarAtom } from "./state/atoms";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-common";
import axios, { AxiosResponse } from "axios";

import { Divider, List, ListItem, ListItemText, Box, Button, styled, Typography } from "@mui/material";

import TopBar from "./TopBar";
import BottomBar, { drawerHeight } from "./components/SwipeableBottomBar";
import CancelDialog from "./components/CancelDialog";
import ErrorDialog from "./components/ErrorDialog";

import { ReservationsAtom } from "./state/atoms";
import { categorizeVehicle, categorizeImg } from "./utils/categorizedVehicle";

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
}));

const ReturnedBottomButton = styled(StyledButton)({
  borderRadius: "30px",
  width: "70%",
});

const CanceledBottomButton = styled(StyledButton)({
  borderRadius: "30px",
  minWidth: "50px",
  backgroundColor: "#FADFCC",
  "&:hover": {
    backgroundColor: "#FADFCC",
  },
});

const APIM_ENDPOINT = process.env.REACT_APP_APIM_ENDPOINT!;

const ReservationDetailForNoriaigo = () => {
  const { instance, accounts } = useMsal();
  const params = useParams();
  const navigate = useNavigate();
  const open = useRecoilValue(bottomBarAtom);
  const reservations = useRecoilValue(ReservationsAtom);
  const [isDialog, setIsDialog] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isErrorDialog, setIsErrorDialog] = useState<boolean>(false);

  useEffect(() => {
    if (!reservations) {
      setIsErrorDialog(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cancelReservation = async () => {
    const scopes: string[] = (process.env.REACT_APP_ADB2C_SCOPES ?? "").split(",");
    const accessTokenRequest = {
      scopes: scopes,
      account: accounts[0],
    };
    let authorization = "";
    await instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        authorization = `Bearer ${accessTokenResponse.accessToken}`;
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenPopup(accessTokenRequest).then((accessTokenResponse) => {
            authorization = `Bearer ${accessTokenResponse.accessToken}`;
          });
        }
      });
    try {
      const response: AxiosResponse = await axios.delete(`${APIM_ENDPOINT}/noriaigo/reservations/${params.id}`, {
        timeout: 100000,
        headers: {
          Authorization: authorization,
        },
      });
      return response;
    } catch (e) {
      if (axios.isAxiosError(e) && e.response) {
        return e.response;
      }
    }
  };
  const getSelectedReservaion = () => {
    reservations &&
      reservations.forEach((content, index) => {
        if (String(content.id) === params.id) {
        }
      });
    const selectedReservation = reservations?.filter((element) => String(element.id) === params.id);
    if (selectedReservation) {
      return (
        <>
          <List sx={{ top: "72px" }}>
            <ListItem>
              <ListItemText primary={"内容確認"} primaryTypographyProps={{ fontWeight: "bold", fontSize: "18px" }} />
            </ListItem>
            <ListItem sx={{ paddingBottom: 0 }}>
              <ListItemText primary={"予約番号"} primaryTypographyProps={{ fontWeight: "bold", fontSize: "16px" }} />
            </ListItem>
            <Divider sx={{ borderStyle: "dashed", borderColor: "#F2A762" }} />
            <ListItem sx={{ paddingBottom: 0 }}>
              <ListItemText primary={selectedReservation[0].id} />
            </ListItem>
            <ListItem sx={{ paddingBottom: 0 }}>
              <ListItemText primary={"運行日"} primaryTypographyProps={{ fontWeight: "bold", fontSize: "16px" }} />
            </ListItem>
            <Divider sx={{ borderStyle: "dashed", borderColor: "#F2A762" }} />
            <ListItem sx={{ paddingBottom: 0 }}>
              <ListItemText
                primary={`${selectedReservation[0] && new Date(selectedReservation[0].departure_time).getFullYear()}年${
                  selectedReservation[0] && new Date(selectedReservation[0].departure_time).getMonth() + 1
                }月${selectedReservation[0] && new Date(selectedReservation[0].departure_time).getDate()}日（${
                  selectedReservation[0] &&
                  ["日", "月", "火", "水", "木", "金", "土"][new Date(selectedReservation[0].departure_time).getDay()]
                }）`}
              />
            </ListItem>
            <ListItem sx={{ paddingBottom: 0 }}>
              <ListItemText primary={"乗客数"} primaryTypographyProps={{ fontWeight: "bold", fontSize: "16px" }} />
            </ListItem>
            <Divider sx={{ borderStyle: "dashed", borderColor: "#F2A762" }} />
            <ListItem>
              <ListItemText primary={`${selectedReservation[0] && selectedReservation[0].passenger_count}人`} />
            </ListItem>
            <ListItem sx={{ paddingBottom: 0 }}>
              <ListItemText primary={"経路"} primaryTypographyProps={{ fontWeight: "bold", fontSize: "16px" }} />
            </ListItem>
            <Divider sx={{ borderStyle: "dashed", borderColor: "#F2A762" }} />
            <ListItem>
              <ListItemText
                primary={`${selectedReservation[0] && selectedReservation[0].departure_platform_name} → ${
                  selectedReservation[0] && selectedReservation[0].arrival_platform_name
                }`}
              />
            </ListItem>
            <ListItem sx={{ paddingBottom: 0 }}>
              <ListItemText primary={"予約時刻"} primaryTypographyProps={{ fontWeight: "bold", fontSize: "16px" }} />
            </ListItem>
            <Divider sx={{ borderStyle: "dashed", borderColor: "#F2A762" }} />
            <ListItem>
              <ListItemText
                primary={`現時点予定 ${
                  selectedReservation[0] &&
                  new Date(selectedReservation[0].departure_time).getHours().toString().padStart(2, "0")
                }:${
                  selectedReservation[0] &&
                  new Date(selectedReservation[0].departure_time).getMinutes().toString().padStart(2, "0")
                } → ${
                  selectedReservation[0] &&
                  new Date(selectedReservation[0].arrival_time).getHours().toString().padStart(2, "0")
                }:${
                  selectedReservation[0] &&
                  new Date(selectedReservation[0].arrival_time).getMinutes().toString().padStart(2, "0")
                }`}
              />
            </ListItem>
            <ListItem>
              <ListItemText primary={"※予約時刻は、前後の予約状況に応じて変更される可能性があります"} />
            </ListItem>
            <ListItem sx={{ paddingBottom: 0 }}>
              <ListItemText primary={"号車"} primaryTypographyProps={{ fontWeight: "bold", fontSize: "16px" }} />
            </ListItem>
            <Divider sx={{ borderStyle: "dashed", borderColor: "#F2A762" }} />
            <ListItem sx={{ justifyContent: "center" }}>
              {selectedReservation[0] && categorizeImg(categorizeVehicle(selectedReservation[0].vehicle_id))}
            </ListItem>
          </List>
        </>
      );
    }
  };
  return (
    <>
      <Box sx={{ overflow: "scroll", height: `${window.innerHeight}px` }}>
        <TopBar title={"デマンド予約"} hasMargin={false} showReturnIcon={true} opacity="1" />
        {getSelectedReservaion()}
        <Box mt={10}>
          {isLoading ? (
            <ReturnedBottomButton disabled>
              <Typography variant="h6" fontWeight="bold">
                予約メニューに戻る
              </Typography>
            </ReturnedBottomButton>
          ) : (
            <ReturnedBottomButton
              onClick={() => {
                navigate("/dispach-noriaigo");
              }}
            >
              <Typography variant="h6" fontWeight="bold">
                予約メニューに戻る
              </Typography>
            </ReturnedBottomButton>
          )}
        </Box>
        <Box mt={5} mb={5}>
          {isLoading ? (
            <CanceledBottomButton disabled>
              <Typography variant="h6" fontWeight="bold">
                キャンセル中
              </Typography>
            </CanceledBottomButton>
          ) : (
            <CanceledBottomButton
              onClick={() => {
                setIsDialog(true);
              }}
            >
              <Typography variant="h6" fontWeight="bold">
                予約をキャンセルする
              </Typography>
            </CanceledBottomButton>
          )}
        { 
          open &&
          <Box sx={{height: `${drawerHeight}px`}}></Box>
        }
        <BottomBar defaultOpen={true}/>
        </Box>
        {isDialog && (
          <CancelDialog
            primaryText={"デマンド予約をキャンセルしますか？"}
            secondaryText={
              "一度キャンセルされた予約は取り消しできません。必要な場合は予約メニューから再度予約してください。"
            }
            open={isDialog}
            onClose={() => setIsDialog(false)}
            handleClick={() => {
              setIsLoading(true);
              cancelReservation().then((res) => {
                if (res && res.status === 204) {
                  navigate("/noriaigo/reservation/cancel");
                };
              });
            }}
          />
        )}
        {isErrorDialog ? (
          <ErrorDialog
            primaryText={"予期せぬエラーが発生しました。再度予約を選択してください。"}
            handleClick={() => {
              navigate("/dispach-noriaigo");
            }}
          />
        ) : null}
      </Box>
    </>
  );
};

export default ReservationDetailForNoriaigo;
