import axios from "axios";

export const getGraphToken = async (authorization: string) => {
  const APIM_ENDPOINT = process.env.REACT_APP_APIM_ENDPOINT!;

  const TENANT_ID = process.env.REACT_APP_GRAPH_TENANT_ID;
  const CLIENT_ID = process.env.REACT_APP_ADB2C_CLIENT_ID;
  const CLIENT_SECRET = process.env.REACT_APP_GRAPH_CLIENT_SECRET;
  const params = new URLSearchParams();

  params.append("grant_type", "client_credentials");
  CLIENT_ID && params.append("client_id", CLIENT_ID);
  CLIENT_SECRET && params.append("client_secret", CLIENT_SECRET);
  params.append("resource", "https://graph.microsoft.com");

  return await axios.post(`${APIM_ENDPOINT}/noriaigo/ad/${TENANT_ID}`, params, {
    headers: { Authorization: authorization },
  });
};

export const getAdb2cUser = async (local_id: string, access_token: string) => {
  return await axios.get(`https://graph.microsoft.com/beta/users/${local_id}`, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
};

export const updateAdb2cUser = async (
  local_id: string,
  params: {
    login_id?: string;
    is_kaga_address?: string;
  },
  access_token: string
) => {
  const userResponse = await axios.patch(`https://graph.microsoft.com/beta/users/${local_id}`, params, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  return userResponse;
};

export const generate_login_id = () => {
  return ("00000000000" + (Math.floor(Math.random() * 99999999999) + 1)).slice(-11);
};
