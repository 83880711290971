import { PositionType } from "../types";

export const defaultLocatioin: PositionType = {
  latitude: 36.320526,
  longitude: 136.350325,
};
export const defaultZoomLevel = 14;

export const drawerWidth = 240;
// topBarHeight + 10px
export const headerMarginTop = 50;
export const topBarHeight = 50;
