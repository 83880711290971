import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { Box, Typography, CircularProgress, styled } from "@mui/material";

import TopBar from "./TopBar";
import SwipeableBottomBar, { drawerHeight } from "./components/SwipeableBottomBar";

import getIconForType from "./RouteStepIcons";
import { queryState, routesState } from "./store";
import { RootObject, ResultSet, Course } from "./types/routing";
import { LocationType } from "./types/search";

import { selectedCourseAtom, bottomBarAtom } from "./state/atoms";

import { topBarHeight } from "./utils/constants";

import "./RouteSearchResults.css";

const ScrolableMain = styled("main")({
  overflow: "scroll",
  height: `${window.innerHeight - topBarHeight}px`,
});

type CourseItemProps = {
  value: Course;
};

class CourseItem extends React.Component<CourseItemProps, {}> {
  render() {
    return (
      <>
        <div className="result">
          <div className="time">
            {this.props.value.FirstDeparture} → {this.props.value.LastArrival}
          </div>
          <div className="info">
            {this.props.value.Duration}分{"　"}
            {this.props.value.Cost}円
          </div>
          <div className="route">
            <ul className="routeSteps">
              <li>
                <span className="terminal">発</span>
                {" — "}
              </li>
              {this.props.value.Route.Steps.map((step, i, array) => (
                <li key={i}>
                  {getIconForType(step.Type, step.Name)}
                  {i === array.length ? "" : " — "}
                </li>
              ))}
              <li>
                <span className="terminal">着</span>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  }
}

function RouteSearchResults() {
  let navigate = useNavigate();

  const { instance, inProgress, accounts } = useMsal();

  const [query] = useRecoilState(queryState);

  const [routes, setRoutes] = useRecoilState(routesState);
  const setSelectedCourse = useSetRecoilState(selectedCourseAtom);
  const open = useRecoilValue(bottomBarAtom);
  const [isLoading, setIsLoading] = useState(false);

  function onPageClick(course: any) {
    setSelectedCourse(course);
    navigate("/search/results/details");
  }

  useEffect(() => {
    async function getRouteResults(accessToken: string) {
      // url where routing is hosted at, e.g. http://localhost:56733
      const host = process.env.REACT_APP_ROUTING_HOST;
      const url = `${host}/search/`;
      if (
        query.startLocation.type === LocationType.CurrentLocation &&
        !query.startLocation.latitude &&
        !query.startLocation.longitude
      ) {
        alert("現在地の位置情報が取得できません。位置情報の設定を確認してください。");
      } else {
        axios
          .post(url, query, {
            headers: { Authorization: `Bearer ${accessToken}` },
          })
          .then((response) => {
            const results: RootObject = response.data;
            const resultSet: ResultSet = results.ResultSet;
            const courses: Array<Course> = resultSet.Course;
            setIsLoading(false);
            setRoutes(courses);
          })
          .catch((error) => {
            setIsLoading(false);
            console.error("Uh-oh. there was an error: ", error);
            alert(error);
          });
      }
    }

    const scopes: string[] = (process.env.REACT_APP_ADB2C_SCOPES ?? "").split(",");
    const accessTokenRequest = {
      scopes: scopes,
      account: accounts[0],
    };
    if (routes && routes.length > 0) {
      // already has routes; not searching again. this is the case when user comes back from details
      return;
    }
    setIsLoading(true);
    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        getRouteResults(accessTokenResponse.accessToken);
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance
            .acquireTokenPopup(accessTokenRequest)
            .then(function (accessTokenResponse) {
              getRouteResults(accessTokenResponse.accessToken);
            })
            .catch(function (error) {
              alert(error);
              setIsLoading(false);
            });
        }
      });
  }, [instance, accounts, inProgress, routes, setRoutes, query]);

  const courses =
    routes &&
    routes.map((course: Course, index: number) => (
      <div key={index} onClick={() => onPageClick(course)}>
        <CourseItem value={course} />
      </div>
    ));

  return (
    <ScrolableMain>
      <TopBar title="移動経路検索" hasMargin={false} showReturnIcon={true} />
      <div className="results-div1">
        <div className="results-div2">
          {isLoading ? (
            <div className="loadingIndicator">
              <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress />
              </Box>
            </div>
          ) : courses ? (
            <div className="resultsList">{courses}</div>
          ) : (
            <Typography sx={{ position: "fixed", width: "100%", top: "80px", color: "#3a1d0b" }}>
              適当なルートがありません。
              <br />
              時間帯等を変更してください。
            </Typography>
          )}
        </div>
        {open && <Box sx={{ height: `${drawerHeight}px` }}></Box>}
        <SwipeableBottomBar defaultOpen={true} />
      </div>
    </ScrolableMain>
  );
}

export default RouteSearchResults;
