import React from "react";
import { Button, Divider, Dialog, DialogActions, DialogTitle, styled } from "@mui/material";

interface AlertDialogProps {
  isOpen: boolean;
  description: string;
  submitText: string;
  closeText: string;
  handleSubmit: () => void;
  handleClose: () => void;
}

const StyledButton = styled(Button)({
  fontSize: 16,
});

function AlertDialog(props: AlertDialogProps) {
  const { isOpen, description, submitText, closeText, handleSubmit, handleClose } = props;

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ m: 2, fontSize: 18 }}>
          {description}
        </DialogTitle>
        <Divider />
        <DialogActions sx={{ justifyContent: "space-around" }}>
          <StyledButton onClick={handleClose}>{closeText}</StyledButton>
          <StyledButton onClick={handleSubmit} autoFocus>
            {submitText}
          </StyledButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AlertDialog;
