import { Configuration, PopupRequest, LogLevel } from "@azure/msal-browser";

export const b2cPolicies: any = {
  names: {
    signUpSignIn: "B2C_1_SignInUp",
    forgotPassword: "B2C_1_PasswardReset",
    editProfile: "B2C_1_EditProfile",
  },
  authorities: {
    signUpSignIn: {
      authority: process.env.REACT_APP_ADB2C_AUTHORITY,
    },
    editProfile: {
      authority: process.env.REACT_APP_ADB2C_EDIT_PROFILE,
    },
    forgotPassword: {
      authority: process.env.REACT_APP_ADB2C_FORGOT_PASSWORD,
    },
  },
  authorityDomain: process.env.REACT_APP_ADB2C_KNOWN_AUTHORITIES?.split(",") || [""],
};

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_ADB2C_CLIENT_ID || "",
    authority: process.env.REACT_APP_ADB2C_AUTHORITY,
    knownAuthorities: b2cPolicies.authorityDomain,
    redirectUri: process.env.REACT_APP_ADB2C_REDIRECT_URI || "/",
    postLogoutRedirectUri: process.env.REACT_APP_ADB2C_POST_LOGOUT_REDIRECT_URI || "/",
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: process.env.REACT_APP_ADB2C_SCOPES?.split(",") || [""],
};

export const editProfile: any = {
  authority: process.env.REACT_APP_ADB2C_EDIT_PROFILE,
};

export const forgotPassword: any = {
  authority: process.env.REACT_APP_ADB2C_FORGOT_PASSWORD,
};

export const policyNames = {
  signUpSignIn: process.env.REACT_APP_ADB2C_SIGNUP_SIGNIN_POLICY,
};
