export interface SearchQuery {
  startLocation: SearchLocation;
  destinationLocation: SearchLocation;
  searchType: string;
  time?: string;
  methods?: string[];
}

export type SearchLocation = {
  title: string;
  code?: string;
  latitude?: number;
  longitude?: number;
  stationCode?: number;
  type: LocationType;
};

export enum LocationType {
  Regular = 1,
  CurrentLocation,
}
