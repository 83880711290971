import {
  Box,
  Typography
} from "@mui/material";

const ResignMembershipNote = () => {
  return (
    <>
      <Box mt={3} sx={{textAlign: "center" }}>
        <Typography mr={5} ml={5} variant="h5" sx={{ color: "#333333", fontWeight: "bold" }}>
          注意事項と同意
        </Typography>
        <Typography mr={5} ml={5} variant="body1" sx={{ color: "#333333" }}>
          退会処理を行うと、再度復元させることはできません。
          退会後、現在ログイン中のメールアドレスにてログインができなくなります。<br />
        </Typography>
      </Box>
    </>
  )
}

export default ResignMembershipNote;