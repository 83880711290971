import { Card, CardContent, CardMedia } from "@mui/material";

type Sx = {
  width?: string;
  height?: string;
  marginRight?: string;
  marginLeft?: string;
}

interface DialogCardProps {
  image: string;
  sx?: Sx;
}

const DialogCard = (props: DialogCardProps) => {
  const { image, sx } = props;
  return (
    <>
      <Card variant="outlined">
        <CardContent>
          <CardMedia 
            component={"img"}
            image={image}
            sx={sx}
          />
        </CardContent>
      </Card>
    </>
  )
}


export default DialogCard;