import React from "react";
import { Box, List, Divider, ListItemButton, ListItemText } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { createGlobalStyle } from "styled-components";

import TopBar from "./TopBar";

import "./About.css";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #E5E5E5;
  }
`;

const ListItems = [
  {
    primaryText: "利用規約",
    url: "/static/UsePolicy.html",
  },
  {
    primaryText: "プライバシーポリシー",
    url: "https://www.maas.co.jp/privacy.html",
  },
  {
    primaryText: "掲載している情報について",
    url: "/static/PublishedInformation.html",
  },
  {
    primaryText: "退会する",
    url: "/resign",
  }
];

function About() {
  return (
    <>
      <main>
        <TopBar title="アプリについて" hasMargin={true} showReturnIcon={true} />
        <Box>
          <List>
            {ListItems.map((item, index) => (
              <React.Fragment key={index}>
                <ListItemButton
                  component="a"
                  href={item.url}
                  sx={{ backgroundColor: "grey.50", color: "secondary.main" }}
                >
                  <ListItemText primary={item.primaryText} />
                  <ArrowForwardIosIcon fontSize="small" />
                </ListItemButton>
                {index !== ListItems.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
          <GlobalStyle />
        </Box>
      </main>
    </>
  );
}

export default About;
