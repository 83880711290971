import React, { useState, useEffect } from "react";
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-common";
import {
  styled,
  Box,
  Button,
  Typography,
  OutlinedInput,
  InputAdornment,
  IconButton,
  MenuItem,
  Select,
  SvgIcon,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";

import TopBar from "./TopBar";
import BottomBar, { drawerBleeding, drawerHeight } from "./components/SwipeableBottomBar";

import {
  noriaigoOriginAtom,
  noriaigoDestinationAtom,
  ReservationCandidateAtom,
  SelectedTimeAtom,
  TimesInfoAtom,
  SelectedDateAtom,
  bottomBarAtom
} from "./state/atoms";

import { getGraphToken, getAdb2cUser } from "./utils/loginForNoriaigo";

import "./RouteSearchForNoriaigo.css";

const StyledOutlineInput = styled(OutlinedInput)(({ theme }) => ({
  background: "#eeeeee",
  borderRadius: "5px",
  borderWidth: "0px",
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "16px",
  boxSizing: "border-box",

  height: "4vh",
  width: "80%",
  marginTop: "auto",
  marginBottom: "auto",
  marginLeft: "8px",
  backgroundColor: theme.palette.grey.A200,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
  "&.Mui-disabled": {
    background: "lightgray",
  },
}));

const StyledChangeButton = styled(StyledButton)({
  borderRadius: "3px",
  fontSize: "14px",
});

const StyledLabelBox = styled(Box)({
  width: "20%",
  textAlign: "left",
});

type NumberFormType = {
  number: number;
  setNumber: any;
};

type DateFormType = {
  date: string;
  setDate: any;
  setTime: any;
};

type TimeFormType = {
  time: Date;
  setTime: any;
};

type FormType = {
  id: string;
  type?: string;
  placeholder?: string;
  iconTag: string;
  numberForm?: NumberFormType;
  dateForm?: DateFormType;
  timeForm?: TimeFormType;
  setAxiosErrorType?: any;
};

type TimeTableFormProps = {
  iconTag: string;
  timesInfo: string[];
  value: string;
  onChange: (v: string) => void;
  setAxiosErrorType?: any;
};

const handleIcon = (iconTag: string) => {
  switch (iconTag) {
    case "location":
      return <LocationOnIcon />;
    case "calendar":
      return <DateRangeIcon />;
    case "people":
      return <PeopleAltIcon />;
    case "time":
      return <AccessTimeIcon />;
  }
};

const handleTitle = (id: string): string => {
  switch (id) {
    case "origin":
      return "出発地";
    case "destination":
      return "目的地";
    case "count":
      return "人数　";
    case "date":
      return "日付　";
    case "time":
      return "時刻　";
  }
  return "";
};

const APIM_ENDPOINT = process.env.REACT_APP_APIM_ENDPOINT!;

const NumberFormComponent = (props: FormType) => {
  const { id, type, placeholder, iconTag, numberForm, setAxiosErrorType } = props;

  const title = handleTitle(id);

  return (
    <>
      <Box m={1} mt={4} mb={2} sx={{ display: "flex" }}>
        <StyledLabelBox>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "#3a1d0b" }}>
            {title}
          </Typography>
        </StyledLabelBox>
        <StyledOutlineInput
          type={type}
          placeholder={placeholder}
          value={numberForm?.number}
          inputProps={{ min: 1, max: 9 }}
          onChange={(e) => {
            setAxiosErrorType("");
            numberForm?.setNumber(e.target.value);
          }}
          startAdornment={
            <InputAdornment position="start" sx={{ padding: "0px" }}>
              <IconButton edge="start">{handleIcon(iconTag)}</IconButton>
            </InputAdornment>
          }
        />
      </Box>
    </>
  );
};

const TimeTableFormComponent = (props: TimeTableFormProps) => {
  const { iconTag, timesInfo, value, onChange, setAxiosErrorType } = props;
  return (
    <Box m={1} mt={4} mb={2} sx={{ display: "flex" }}>
      <StyledLabelBox sx={{ marginTop: "10px" }}>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "#3a1d0b" }}>
          時刻　
        </Typography>
      </StyledLabelBox>
      <Select
        sx={{ width: "80%", marginLeft: "8px", backgroundColor: "#eeeeee" }}
        defaultValue={value}
        displayEmpty
        value={value}
        disabled={timesInfo.length > 0 ? false : true}
        onChange={(e) => {
          setAxiosErrorType("");
          onChange(e.target.value);
        }}
        renderValue={(value) => {
          return (
            <Box sx={{ display: "flex", gap: 1 }}>
              <SvgIcon>{handleIcon(iconTag)}</SvgIcon>
              {value}
            </Box>
          );
        }}
      >
        {timesInfo.map((t: string, index: number) => (
          <MenuItem key={index} value={t}>
            {t}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

const DateFormComponent = (props: FormType) => {
  const { id, iconTag, dateForm } = props;

  const handleChange = (newValue: string) => {
    dateForm?.setTime("");
    dateForm?.setDate(newValue);
  };

  const title = handleTitle(id);

  return (
    <>
      <Box m={1} mt={4} mb={2} sx={{ display: "flex" }}>
        <StyledLabelBox>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "#3a1d0b" }}>
            {title}
          </Typography>
        </StyledLabelBox>
        <StyledOutlineInput
          type={"date"}
          id="datePicker"
          value={dateForm?.date}
          onChange={(e) => {
            handleChange(e.target.value)
          }}
          startAdornment={
            <InputAdornment position="start" sx={{ padding: "0px" }}>
              <IconButton edge="start">{handleIcon(iconTag)}</IconButton>
            </InputAdornment>
          }
        />
      </Box>
    </>
  );
};

const RouteSearchForNoriaigo = () => {
  const { instance, accounts } = useMsal();
  const noriaigoOrigin = useRecoilValue(noriaigoOriginAtom);
  const noriaigoDestination = useRecoilValue(noriaigoDestinationAtom);
  const setNoriaigoOrigin = useSetRecoilState(noriaigoOriginAtom);
  const setNoriaigoDestination = useSetRecoilState(noriaigoDestinationAtom);
  const setReservationCandidate = useSetRecoilState(ReservationCandidateAtom);
  const [time, setTime] = useRecoilState(SelectedTimeAtom);
  const [timesInfo, setTimesInfo] = useRecoilState(TimesInfoAtom);
  const [date, setDate] = useRecoilState(SelectedDateAtom);
  const open = useRecoilValue(bottomBarAtom);

  const [number, setNumber] = useState<number>(1);
  const [axiosErrorType, setAxiosErrorType] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  let navigate = useNavigate();

  const FixedBottomButton = styled(StyledButton)({
    borderRadius: "30px",
    position: "fixed",
    bottom: open ? (window.innerHeight < 700 ? drawerHeight : drawerHeight + drawerBleeding + 10) : drawerBleeding + 10,
    height: "5vh",
    width: "70%",
    left: "50%",
    transform: "translateX(-50%);",
  });
  
  useEffect(() => {
    const f = async () => {
      const scopes: string[] = (process.env.REACT_APP_ADB2C_SCOPES ?? "").split(",");
      const accessTokenRequest = {
        scopes: scopes,
        account: accounts[0],
      };
      let authorization = "";
      await instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          authorization = `Bearer ${accessTokenResponse.accessToken}`;
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenPopup(accessTokenRequest).then((accessTokenResponse) => {
              authorization = `Bearer ${accessTokenResponse.accessToken}`;
            });
          }
        });
      try {
        const response: AxiosResponse = await axios.get(`${APIM_ENDPOINT}/noriaigo/time_table_times`, {
          timeout: 100000,
          headers: {
            Authorization: authorization,
          },
          params: {
            departure_platform_id: noriaigoOrigin?.id,
            arrival_platform_id: noriaigoDestination?.id,
            date: date,
          },
        });
        setTimesInfo(response.data);
        return response;
      } catch (e) {
        if (axios.isAxiosError(e) && e.response) {
          return e.response;
        }
      }
    };
    setAxiosErrorType("");
    if (noriaigoOrigin?.id && noriaigoDestination?.id && date) {
      f();
    } else {
      setTimesInfo([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, noriaigoOrigin, noriaigoDestination]);

  const searchReservationCandidate = async () => {
    const scopes: string[] = (process.env.REACT_APP_ADB2C_SCOPES ?? "").split(",");
    const accessTokenRequest = {
      scopes: scopes,
      account: accounts[0],
    };
    let authorization = "";
    await instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        authorization = `Bearer ${accessTokenResponse.accessToken}`;
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenPopup(accessTokenRequest).then((accessTokenResponse) => {
            authorization = `Bearer ${accessTokenResponse.accessToken}`;
          });
        }
      });
    try {
      console.table(noriaigoOrigin);
      console.table(noriaigoDestination);

      const local_id = accounts[0].localAccountId;
      const token = await getGraphToken(authorization).then(res => {
        return res.data.access_token;
      });
      const userInfo = await getAdb2cUser(local_id, token).then(res => {
        return res.data;
      });
      const key_arr = Object.keys(userInfo);
      const login_id_key = key_arr.find(element => element.includes("login_id"));
      const login_id = login_id_key && userInfo[login_id_key];
      console.log(login_id)

      const hoursMinutesArray = time.split(":");
      const dateObj = new Date(date);
      let demand_time = new Date(
        dateObj.getFullYear(),
        dateObj.getMonth(),
        dateObj.getDate(),
        Number(hoursMinutesArray[0]),
        Number(hoursMinutesArray[1])
      );
      const response: AxiosResponse = await axios.get(`${APIM_ENDPOINT}/noriaigo/reservation_candidates`, {
        timeout: 100000,
        headers: {
          Authorization: authorization,
        },
        params: {
          login_id:  APIM_ENDPOINT === "https://cirx-kaga-dev.azure-api.net" ? "09043510082" : login_id,
          demand_time: demand_time,
          type: "departure",
          departure_platform_id: noriaigoOrigin?.id,
          arrival_platform_id: noriaigoDestination?.id,
          passenger_count: Number(number),
        },
      });
      return response;
    } catch (e) {
      if (axios.isAxiosError(e) && e.response) {
        return e.response;
      }
    }
  };

  const validateResponse = (res: any) => {
    let validationResult = true;
    if (res === undefined) validationResult = false;
    if (res.status === 404) {
      // capacity_over 空席なし
      // no_schedulable_time　他の予約との兼ね合いで候補が見つからない
      setAxiosErrorType(res.data[0]);
      validationResult = false;
      setIsLoading(false);
    } else if (res.status === 401) {
      validationResult = false;
      setIsLoading(false);
    } else if (res.status === 422) {
      // 運行ルートにない乗降場の組み合わせ
      setAxiosErrorType("unprocessable_Entity");
      validationResult = false;
      setIsLoading(false);
    } else if (res.status === 200) {
      validationResult = true;
    } else {
      validationResult = true;
      setIsLoading(false);
    }
    return validationResult;
  };

  const handleAxiosErrorType = () => {
    switch (axiosErrorType) {
      case "capacity_over":
        return (
          <Typography variant="subtitle1" sx={{ color: "#E86100" }}>
            この人数で乗車可能な車両はありません
          </Typography>
        );
      case "unprocessable_Entity":
        return (
          <Typography variant="subtitle1" sx={{ color: "#E86100" }}>
            予約候補が見つかりませんでした
          </Typography>
        );
      case "no_schedulable_time":
        return (
          <Typography variant="subtitle1" sx={{ color: "#E86100" }}>
            他の予約との兼ね合いで候補が見つかりません
          </Typography>
        );
    }
    return (
      <Typography variant="subtitle1" sx={{ color: "#E86100" }}>
        予期せぬエラーが発生しました
      </Typography>
    );
  };

  const TextFormComponent = (props: FormType) => {
    const { id, type, placeholder, iconTag } = props;

    const title = handleTitle(id);

    function changeOriginLocation(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
      e.preventDefault();
      navigate("/noriaigo/search/map/origin");
    }

    function changeDestinationLocation(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
      e.preventDefault();
      navigate("/noriaigo/search/map/destination");
    }

    const OriginInput = () => {
      return (
        <StyledOutlineInput
          value={noriaigoOrigin ? noriaigoOrigin.name : ""}
          type={type}
          placeholder={placeholder}
          onFocus={changeOriginLocation}
          startAdornment={
            <InputAdornment position="start" sx={{ padding: "0px" }}>
              <IconButton edge="start">{handleIcon(iconTag)}</IconButton>
            </InputAdornment>
          }
        />
      );
    };

    const DestinationInput = () => {
      return (
        <StyledOutlineInput
          value={noriaigoDestination ? noriaigoDestination.name : ""}
          type={type}
          placeholder={placeholder}
          onFocus={changeDestinationLocation}
          startAdornment={
            <InputAdornment position="start" sx={{ padding: "0px" }}>
              <IconButton edge="start">{handleIcon(iconTag)}</IconButton>
            </InputAdornment>
          }
        />
      );
    };

    return (
      <>
        <Box m={1} mt={2} mb={2} sx={{ display: "flex" }}>
          <StyledLabelBox>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "#3a1d0b" }}>
              {title}
            </Typography>
          </StyledLabelBox>
          {id === "origin" ? <OriginInput /> : <DestinationInput />}
        </Box>
      </>
    );
  };

  const SwapButtonComponent = () => {
    const swapLocation = () => {
      const tempOrigin = noriaigoOrigin;
      const tempDestination = noriaigoDestination;
      setNoriaigoOrigin(tempDestination);
      setNoriaigoDestination(tempOrigin);
    };

    return (
      <Box ml={25}>
        <StyledChangeButton onClick={() => swapLocation()}>
          <Typography variant="h6" fontWeight="bold" sx={{ fontSize: "14px" }}>
            入れ替え
          </Typography>
        </StyledChangeButton>
      </Box>
    );
  };

  return (
    <Box sx={{ overflow: "scroll", height: `${window.innerHeight}px` }}>
      <TopBar title="デマンド予約" showReturnIcon={true} hasMargin={true} />
      <TextFormComponent id="origin" type="text" placeholder="乗降場名の一部を入力" iconTag="location" />
      <SwapButtonComponent />
      <TextFormComponent id="destination" type="text" placeholder="乗降場名の一部を入力" iconTag="location" />
      <NumberFormComponent
        id="count"
        type="number"
        placeholder="1"
        iconTag="people"
        numberForm={{ number: number, setNumber: setNumber }}
        setAxiosErrorType={setAxiosErrorType}
      />
      <DateFormComponent
        id="date"
        iconTag="calendar"
        dateForm={{ date: date, setDate: setDate, setTime: setTime }}
      />
      <TimeTableFormComponent
        iconTag="time"
        timesInfo={timesInfo}
        value={time}
        onChange={(v: string) => setTime(v)}
        setAxiosErrorType={setAxiosErrorType}
      />
      {axiosErrorType ? handleAxiosErrorType() : null}
      {isLoading ? (
        <FixedBottomButton disabled>
          <Typography variant="h6" fontWeight="bold">
            検索中
          </Typography>
        </FixedBottomButton>
      ) : (
        <FixedBottomButton
          disabled={noriaigoOrigin && noriaigoDestination && time ? false : true}
          onClick={() => {
            setIsLoading(true);
            searchReservationCandidate().then((res) => {
              const result = validateResponse(res);
              if (res !== undefined && result) {
                setReservationCandidate(res.data);
                navigate("/noriaigo/reservation/candidate", { state: { number: number, date: new Date(date) } });
              }
            });
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            予約候補を検索する
          </Typography>
        </FixedBottomButton>
      )}
      <BottomBar defaultOpen={true}/>
    </Box>
  );
};

export default RouteSearchForNoriaigo;
