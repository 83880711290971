import { Box, Typography } from "@mui/material";

const AlertTextMailAddress = () => {
  return (
    <>
      <Box mt={3} sx={{textAlign: "center" }}>
        <Typography mr={5} ml={5} variant="body1" sx={{ color: "#333333", fontWeight: "bold" }}>
          端末に迷惑メール設定をしている場合、確認コードが届かない場合があります。<br />
          「kaga-operation@maas.co.jp」を受信許可に設定してください。<br />
          また、「迷惑メールフォルダ」に移動している可能性がありますので、ご確認ください。
        </Typography>
      </Box>
    </>
  )
}

export default AlertTextMailAddress;