import React, { useState } from "react";
import { Marker, Popup } from "react-map-gl";

import { Typography } from "@mui/material";

import { PinType } from "../types";
import SelectLocationButton from "../elements/SelectLocationButton";
import FmdGoodIcon from "@mui/icons-material/FmdGood";

import { ReactComponent as StationIcon } from "../img/station.svg";
import { ReactComponent as CanbusIcon } from "../img/canbus.svg";
import { ReactComponent as BusStopIcon } from "../img/busstop.svg";
import { ReactComponent as NoriaigoIcon } from "../img/noriaigo.svg";

const SIZE = 30;

const CostomIcon = (props: { type: string | undefined; handleClick: () => void }) => {
  const { type, handleClick } = props;
  if (type === "train") {
    return (
      <StationIcon
        height={SIZE}
        width={SIZE}
        style={{
          cursor: "pointer",
          stroke: "none",
          transform: `translate(${-SIZE / 2}px,${-SIZE / 2}px)`,
        }}
        onClick={() => handleClick()}
      />
    );
  } else if (type === "bus") {
    return (
      <BusStopIcon
        height={SIZE}
        width={SIZE}
        style={{
          cursor: "pointer",
          stroke: "none",
          transform: `translate(${-SIZE / 2}px,${-SIZE / 2}px)`,
        }}
        onClick={() => handleClick()}
      />
    );
  } else if (type === "canbus") {
    return (
      <CanbusIcon
        height={SIZE}
        width={SIZE}
        style={{
          cursor: "pointer",
          stroke: "none",
          transform: `translate(${-SIZE / 2}px,${-SIZE / 2}px)`,
        }}
        onClick={() => handleClick()}
      />
    );
  } else if (type === "noriaigo") {
    return (
      <NoriaigoIcon
        height={SIZE}
        width={SIZE}
        style={{
          cursor: "pointer",
          stroke: "none",
          transform: `translate(${-SIZE / 2}px,${-SIZE / 2}px)`,
        }}
        onClick={() => handleClick()}
      />
    );
  } else {
    return (
      <svg
        height={SIZE}
        viewBox="0 0 24 24"
        style={{
          cursor: "pointer",
          stroke: "none",
          transform: `translate(${-SIZE / 2}px,${-SIZE}px)`,
        }}
        onClick={() => handleClick()}
      >
        <FmdGoodIcon sx={{ color: "primary.light" }} />
      </svg>
    );
  }
};

// Important for perf: the markers never change, avoid rerender when the map viewport changes
const PinLayer: React.FC<{ data: PinType[]; type?: string; handleClick?: () => void; setSelectedLocation?: any }> = (
  props
) => {
  const { data, type, handleClick, setSelectedLocation } = props;
  const [popupInfo, setPopupInfo] = useState<PinType | null>(null);

  return (
    <>
      {data.map((d, index) => (
        <Marker
          key={type ? `marker-${type}-${index}` : `marker-${index}`}
          longitude={d.longitude}
          latitude={d.latitude}
        >
          <CostomIcon type={d.iconType} handleClick={() => setPopupInfo(d)} />
        </Marker>
      ))}
      {popupInfo && (
        <Popup
          anchor="top"
          longitude={popupInfo.longitude}
          latitude={popupInfo.latitude}
          closeOnClick={false}
          onClose={setPopupInfo}
          onClick={setSelectedLocation && setSelectedLocation(popupInfo)}
        >
          <Typography variant="subtitle1" sx={{ color: "secondary.main" }}>
            {popupInfo.title}
          </Typography>
          {setSelectedLocation && <SelectLocationButton onClick={handleClick} />}
        </Popup>
      )}
    </>
  );
};

export default PinLayer;
