import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Drawer, DrawerProps, Divider, List, ListItem, ListItemText, styled } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-common";

import AlertDialog from "./AlertDialog";

import { drawerWidth } from "../utils/constants";
import { editProfile, forgotPassword, msalConfig } from "../authConfig";

interface DraweMenuProps extends DrawerProps {
  isOpen: boolean;
  handleClose: () => void;
  marginTop: number;
}

const CONTACT_URL = process.env.REACT_APP_CONTACT_URL!;

const StyledDrawer = styled(Drawer)({
  width: drawerWidth,
  flexShrink: 0,
  [`& .MuiDrawer-paper`]: {
    width: drawerWidth,
    boxSizing: "border-box",
  },
  "& .MuiBackdrop-root": {
    opacity: "0 !important",
  },
});

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

const TitleDivider = styled(Divider)(({ theme }) => ({
  background: theme.palette.primary.main,
  marginLeft: "0.8rem",
}));

const SubtitleDivider = styled(TitleDivider)({
  opacity: "0.2",
});

function DrawerMenu(props: DraweMenuProps) {
  const { isOpen, handleClose, marginTop } = props;
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  async function handleLogout() {
    const scopes: string[] = (process.env.REACT_APP_ADB2C_SCOPES ?? "").split(",");
    const accessTokenRequest = {
      scopes: scopes,
      account: accounts[0],
    };
    let idToken = "";
    await instance
    .acquireTokenSilent(accessTokenRequest)
    .then((tokenResponse) => {
      idToken = tokenResponse.idToken;
    })
    .catch((error) => {
      if (error instanceof InteractionRequiredAuthError) {
        instance.acquireTokenPopup(accessTokenRequest).then((tokenResponse) => {
          idToken = tokenResponse.idToken;
        });
      }
    });
    const logoutRequest = {
      postLogoutRedirectUri: msalConfig.auth.redirectUri,
      idTokenHint: idToken,
      mainWindowRedirectUri: msalConfig.auth.redirectUri
    }
    instance.logoutRedirect(logoutRequest);
}

  function handleMyPage() {
    instance.loginRedirect(editProfile);
  }

  function handleAbout() {
    navigate("/about");
  }

  function handleContact() {
    window.location.href = CONTACT_URL;
  }

  function handleMailAddress() {
    navigate("/mail");
  }

  function handlePasswordReset() {
    instance.loginRedirect(forgotPassword);
  }

  return (
    <>
      <StyledDrawer anchor="left" open={isOpen} onClose={handleClose} PaperProps={{ sx: { mt: marginTop + "px" } }}>
        <Box role="presentation" onClick={handleClose} onKeyDown={handleClose}>
          <List>
            <ListItem>
              <StyledListItemText
                primary="各種設定"
                primaryTypographyProps={{
                  fontWeight: "700",
                }}
              />
            </ListItem>
            <TitleDivider />
            <ListItem button onClick={handleMyPage}>
              <StyledListItemText primary="マイページ" />
            </ListItem>
            <SubtitleDivider />
            <ListItem button onClick={handleMailAddress}>
              <StyledListItemText primary="メールアドレス変更" />
            </ListItem>
            <SubtitleDivider />
            <ListItem button onClick={handlePasswordReset}>
              <StyledListItemText primary="パスワード変更" />
            </ListItem>
            <SubtitleDivider />
            <ListItem button onClick={handleAbout}>
              <StyledListItemText primary="アプリについて" />
            </ListItem>
            <SubtitleDivider />
            <ListItem
              button
              onClick={() => {
                setIsOpenDialog(true);
              }}
            >
              <StyledListItemText primary="ログアウト" />
            </ListItem>
            <SubtitleDivider />
            <ListItem button onClick={handleContact}>
              <StyledListItemText primary="問い合わせ" />
            </ListItem>
          </List>
        </Box>
      </StyledDrawer>
      <AlertDialog
        isOpen={isOpenDialog}
        description="ログアウトしますか？"
        submitText="ログアウト"
        closeText="キャンセル"
        handleSubmit={() => {
          handleLogout();
        }}
        handleClose={() => {
          setIsOpenDialog(false);
        }}
      />
    </>
  );
}

export default DrawerMenu;
