import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, List, ListItem, ListItemText, Typography, Divider, Button, styled } from "@mui/material";
import { topBarHeight } from "./utils/constants";
import { categorizeVehicle, categorizeImg, categorizeAria } from "./utils/categorizedVehicle";
import ErrorDialog from "./components/ErrorDialog";
import TopBar from "./TopBar";
import BottomBar, { drawerHeight, drawerBleeding } from "./components/SwipeableBottomBar";
import { useRecoilValue } from "recoil";
import { noriaigoOriginAtom, noriaigoDestinationAtom, ReservationCandidateAtom, bottomBarAtom } from "./state/atoms";

const ScrolableMain = styled("main")({
  overflow: "scroll",
  height: `${window.innerHeight - topBarHeight}px`,
});

const StyledItemsBox = styled(Box)({
  display: "flex",
  width: "100%",
  padding: "2px 0 2px 21px",
  background: "#3A1D0B",
  color: "#F9E1C9",
});

const StyledListItemText = styled(ListItemText)({
  color: "#3A1D0B",
});

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.primary.main,
  borderRadius: "30px",

  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
}));

const ReservationCandidateForNoriaigo = () => {
  const location = useLocation();

  const noriaigoOrigin = useRecoilValue(noriaigoOriginAtom);
  const noriaigoDestination = useRecoilValue(noriaigoDestinationAtom);
  const noriaigoCandidate = useRecoilValue(ReservationCandidateAtom);
  const number: number = location.state.number;
  const date: Date = location.state.date;
  const [isErrorDialog, setIsErrorDialog] = useState<boolean>(false);
  const open = useRecoilValue(bottomBarAtom);

  let navigate = useNavigate();

  useEffect(() => {
    if (!noriaigoCandidate) {
      setIsErrorDialog(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ReservationInputListComponent = () => {
    const dayOfWeekStr = ["日", "月", "火", "水", "木", "金", "土"][date.getDay()];

    return (
      <List sx={{ display: "block", position: "absolute", top: "95px", width: "100%", padding: 0 }}>
        <Box sx={{ position: "fixed", width: "100%", height: "249px", backgroundColor: "#FFFFFF", zIndex: 1 }}>
          <ListItem sx={{ display: "flex", position: "relative", width: "100%" }}>
            <StyledListItemText
              primary="出発地"
              primaryTypographyProps={{ fontWeight: "bold" }}
              sx={{ position: "fixed" }}
            />
            <ListItemText primary={noriaigoOrigin && noriaigoOrigin.name} sx={{ marginLeft: "56px" }} />
          </ListItem>
          <Divider sx={{ borderStyle: "dashed", borderColor: "#F2A762" }} />
          <ListItem sx={{ display: "flex", position: "relative", width: "100%" }}>
            <StyledListItemText
              primary="目的地"
              primaryTypographyProps={{ fontWeight: "bold" }}
              sx={{ position: "fixed" }}
            />
            <ListItemText primary={noriaigoDestination && noriaigoDestination.name} sx={{ marginLeft: "56px" }} />
          </ListItem>
          <Divider sx={{ borderStyle: "dashed", borderColor: "#F2A762" }} />
          <ListItem sx={{ display: "flex", position: "relative", width: "100%" }}>
            <StyledListItemText
              primary="人数"
              primaryTypographyProps={{ fontWeight: "bold" }}
              sx={{ position: "fixed" }}
            />
            <ListItemText
              primary={`${number}人`}
              sx={{ marginLeft: "56px" }}
            />
          </ListItem>
          <Divider sx={{ borderStyle: "dashed", borderColor: "#F2A762" }} />
          <ListItem sx={{ display: "flex", position: "relative", width: "100%" }}>
            <StyledListItemText
              primary="日付"
              primaryTypographyProps={{ fontWeight: "bold" }}
              sx={{ position: "fixed" }}
            />
            <ListItemText
              primary={`${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日（${dayOfWeekStr}）`}
              sx={{ marginLeft: "56px" }}
            />
          </ListItem>
          <Divider sx={{ borderStyle: "dashed", borderColor: "#F2A762" }} />
        </Box>
      </List>
    );
  };

  const ReservationCandidateListComponent = () => {
    return (
      <>
        <List sx={{ top: "380px" }}>
          {noriaigoCandidate &&
            (noriaigoCandidate.length > 0 ? (
              noriaigoCandidate.map((reservation, index) => (
                <React.Fragment key={index}>
                  <ListItem>
                    <ListItemText
                      primary={`${new Date(reservation.departure_time)
                        .getHours()
                        .toString()
                        .padStart(2, "0")}:${new Date(reservation.departure_time)
                        .getMinutes()
                        .toString()
                        .padStart(2, "0")} → ${new Date(reservation.arrival_time)
                        .getHours()
                        .toString()
                        .padStart(2, "0")}:${new Date(reservation.arrival_time)
                        .getMinutes()
                        .toString()
                        .padStart(2, "0")}`}
                      primaryTypographyProps={{ fontSize: "22px" }}
                    />
                    <ListItemText
                      primary={`${reservation.vehicle_capacity}人乗り`}
                      primaryTypographyProps={{ fontSize: "18px" }}
                    />
                    <StyledButton
                      onClick={() => {
                        navigate(`/noriaigo/reservation/confirmation/${reservation.id}`, { state: { number: number, date: date } });
                      }}
                    >
                      <Typography variant="h6" fontWeight="bold">
                        予約する
                      </Typography>
                    </StyledButton>
                  </ListItem>
                  <ListItem sx={{ justifyContent: "center" }}>
                    {categorizeImg(categorizeVehicle(reservation.vehicle_id))}
                  </ListItem>
                  <ListItem sx={{ textAlign: "center" }}>
                    <ListItemText primary={categorizeAria(categorizeVehicle(reservation.vehicle_id))} />
                  </ListItem>
                  {index !== noriaigoCandidate.length - 1 && <Divider />}
                </React.Fragment>
              ))
            ) : (
              <Typography>検索結果はありませんでした</Typography>
            ))}
          <Box sx={{height: `${drawerBleeding}px`}}></Box>
        </List>
        {
          open &&
          <Box sx={{height: `${drawerHeight}px`}}></Box>
        }
      </>
    );
  };

  return (
    <>
      <TopBar title={"デマンド予約"} hasMargin={false} showReturnIcon={true} opacity="1" />
      <ScrolableMain>
      <StyledItemsBox sx={{ position: "fixed", top: "64px", zIndex: 1 }}>
        <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>予約条件</Typography>
      </StyledItemsBox>
      <ReservationInputListComponent />
      <StyledItemsBox sx={{ position: "fixed", top: "344px", zIndex: 1 }}>
        <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>予約候補</Typography>
      </StyledItemsBox>
      <ReservationCandidateListComponent />
      </ScrolableMain>
      <BottomBar defaultOpen={true}/>
      {isErrorDialog ? (
        <ErrorDialog
          primaryText={"予期せぬエラーが発生しました。再度検索してください。"}
          handleClick={() => {
            navigate("/dispach-noriaigo");
          }}
        />
      ) : null}
    </>
  );
};

export default ReservationCandidateForNoriaigo;
