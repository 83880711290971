import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
import { useRecoilValue } from "recoil";

import {
  Box,
  Chip,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  Stack,
  styled,
  Button,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import TopBar from "./TopBar";
import DialogCard from "./elements/DialogCard";
import TutorialDialog from "./components/TutorialDialog";
import SwipeableBottomBar, { drawerHeight }  from "./components/SwipeableBottomBar";

import ImageNotFound from "./img/image-not-found.svg";
import tutorial from "./img/poi-tutorial.png";

import { PoiCategories, DefaultPoiCategory } from "./store";
import { ErrorType, initialLError } from "./types";
import { poiSearchTextAtom, bottomBarAtom } from "./state/atoms";

import { topBarHeight } from "./utils/constants";

const CMS_ENDPOINT = process.env.REACT_APP_CMS_ENDPOINT || "https://kaga.cirx.dev/cms";

const ScrolableMain = styled("main")({
  overflow: "scroll",
  height: `${window.innerHeight - topBarHeight}px`,
});

const StyledImage = styled("img")({
  width: "28%",
});

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.light,
}));

type ChipProps = {
  selected: boolean;
};

const StyledChip = styled(Chip)<ChipProps>(({ theme, selected }) => ({
  color: theme.palette.secondary.main,
  backgroundColor: selected ? theme.palette.primary.main : theme.palette.grey.A100,
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
  fontWeight: selected ? "bold" : "normal",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
}));

const FETCH_LIMIT = 30;

function Pois() {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isMoreLoading, setIsMoreLoading] = useState(false);
  const [error, setError] = useState<ErrorType>(initialLError);
  const [pois, setPois] = useState<any[]>([]);
  const [tag, setTag] = useState<string>(window.history.state?.tag ? window.history.state.tag : DefaultPoiCategory);
  const [offset, setOffset] = useState<number>(0);
  const [isButtonShow, setIsButtonShow] = useState<boolean>(true);
  const [isTutorial, setIsTutorial] = useState<boolean>(true);
  const poiSearchValue = useRecoilValue(poiSearchTextAtom);
  const open = useRecoilValue(bottomBarAtom);

  const fetchPois = async (searchValue: string, tag: string, ofs: number) => {
    setError(initialLError);
    let url = `${CMS_ENDPOINT}/events?_sort=sort_number,id&location.name_contains=加賀市&genre_null=false`;
    if (searchValue) {
      url += `&title_contains=${searchValue}`;
    }
    // tag
    if (tag !== "") {
      if (tag === "その他") {
        PoiCategories.forEach((elem, index) => {
          if (elem.isOthers) {
            url += `&genre.primary_name_in=${elem.name}`;
          }
        });
      } else {
        url += `&genre.primary_name_eq=${tag}`;
      }
    }
    url += `&_limit=${FETCH_LIMIT}&_start=${ofs}`;
    try {
      const response: AxiosResponse = await axios.get(url, {
        timeout: 100000,
      });
      return response;
    } catch (e) {
      if (axios.isAxiosError(e) && e.response) {
        return e.response;
      }
    }
  };
  const getPoiInfo = async (ofs: number) => {
    fetchPois(poiSearchValue, tag, ofs).then((res) => {
      if (res === undefined || res.status !== 200) {
        setError({
          isError: true,
          title: `${res?.status} ${res?.data.error}`,
          message: res?.data.message,
        });
        setPois([]);
      } else {
        if (res.data.length < FETCH_LIMIT) {
          setIsButtonShow(false);
        }
        if (ofs === 0) {
          // 初回fetch
          setPois(res.data);
          setOffset(0);
        } else {
          // 2回目以降fetch
          let newArray = pois;
          newArray = newArray.concat(res.data);

          setPois(newArray);
          setOffset(ofs);
        }
      }
      setIsMoreLoading(false);
      setIsLoading(false);
    });
  };
  useEffect(() => {
    setIsLoading(true);
    setIsButtonShow(true);
    getPoiInfo(0); // offsetは0
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poiSearchValue, tag]);

  const handleClickTag = (targetTag: string) => {
    if (targetTag === tag) {
      setTag("");
    } else {
      setTag(targetTag);
    }
  };

  const getMorePois = () => {
    setIsMoreLoading(true);
    const ofs: number = offset + FETCH_LIMIT; // offsetの指定
    getPoiInfo(ofs);
  };

  const DisplayContent = () => {
    return pois.length !== 0 ? (
      <>
        <List>
          {pois.map((poi: any, index) => (
            <React.Fragment key={index}>
              <ListItemButton
                onClick={() => {
                  const state = { ...window.history.state, tag: tag };
                  window.history.replaceState(state, "");
                  navigate(`/pois/${poi.id}`);
                }}
                sx={{ color: "secondary.main" }}
                alignItems="flex-start"
              >
                <StyledImage alt={poi.title} src={poi.images.length > 0 ? poi.images[0].url : ImageNotFound} />
                <Box mx={2}>
                  <ListItemText
                    primary={poi.title}
                    secondary={
                      <React.Fragment>
                        {poi.location.name && poi.location.name}
                        <br />
                        {poi.location.zip_code && `〒${poi.location.zip_code}`}
                      </React.Fragment>
                    }
                    primaryTypographyProps={{
                      color: "secondary.main",
                      fontWeight: "bold",
                    }}
                    secondaryTypographyProps={{
                      color: "secondary.main",
                      fontSize: "12px",
                    }}
                  />
                </Box>
              </ListItemButton>
              {index !== pois.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
        {isMoreLoading ? (
          <Box mb={3}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {isButtonShow && (
              <StyledButton sx={{ marginBottom: `${topBarHeight}px` }} onClick={getMorePois}>
                もっと表示する
              </StyledButton>
            )}
          </>
        )}
      </>
    ) : !error.isError ? (
      <Box m={2}>
        <Typography>該当データがありません</Typography>
      </Box>
    ) : (
      <Box m={2}>
        <Typography>エラーが発生しました。<br/>もう一度お試しください。エラーが続くようであればお問い合わせください。</Typography>
      </Box>
    );
  };

  return (
    <>
      <TopBar hasMargin={true} showReturnIcon={true} opacity="1" />
      <ScrolableMain>
        <Box px={2} pt={1} pb={2} component="div" sx={{ overflow: "auto" }}>
          <Stack direction="row" spacing={1}>
            {PoiCategories.map((category, index) => {
              if (!category.isOthers) {
                return (
                  <StyledChip
                    key={index}
                    label={category.displayName}
                    variant={category.name === tag ? "filled" : "outlined"}
                    onClick={() => handleClickTag(category.name)}
                    selected={category.name === tag}
                    size="small"
                  />
                );
              }
              return null;
            })}
          </Stack>
        </Box>
        {poiSearchValue && (
          <>
            <Box sx={{ my: 0.5 }}>
              <StyledTitle fontWeight="bold">検索結果</StyledTitle>
            </Box>
            <Divider />
          </>
        )}
        {isLoading ? (
          <Box mt={2}>
            <CircularProgress />
          </Box>
        ) : (
          <DisplayContent />
        )}
        {
          open &&
          <Box className="empty-box" sx={{height: `${drawerHeight}px`}}></Box>
        }
        <SwipeableBottomBar defaultOpen={true} />
      </ScrolableMain>
      { !localStorage.getItem("KAGA-POI-TUTORIAL") && isTutorial &&
       <TutorialDialog
          isChecked={false}
          title={"行きたい施設をジャンルごとに探せます"}
          description={"おいしいレストランや病院、塾...etc.\n行きたい場所をジャンル分けしました。目的地をリストから探して設定できます。"}
          buttonText={"施設情報を見る"}
          card={<DialogCard image={tutorial}/>}
          handleClick={(isAgree) => {
            if (isAgree) {
              localStorage.setItem("KAGA-POI-TUTORIAL", "agree");
            }
            setIsTutorial(false);
          }}
        />
      }
    </>
  );
}

export default Pois;
