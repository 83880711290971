import { useNavigate } from "react-router-dom";
import HomeGuidance from "../elements/HomeGuidance";
import BackButton from "../elements/BackButton";
import StyledButton from "../elements/StyledButton";
import chrome from "../img/chrome.png";
import guidanceAndroidTop from "../img/guidanceAndroidTop.png";
import guidanceAndroidSelect from "../img/guidanceAndroidSelect.png";
import guidanceAndroidInstall from "../img/guidanceAndroidInstall.png";
import guidanceAndroidResult from "../img/guidanceAndroidResult.png";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const HomeGuidanceAndroid = () => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/");
  }

  return (
    <>
      <BackButton onClick={handleButtonClick}/>
      <HomeGuidance
        mainText="1:ChromeでNoludayを開きます"
        img={chrome}
        sx={{width: "25%", height: "25%", marginLeft: "auto", marginRight: "auto"}}
      />
      <ArrowDropDownIcon sx={{ width: "20%", height: "20%"}} />
      <HomeGuidance
        mainText="2:右上のメニューアイコンをタップします"
        img={guidanceAndroidTop}
      />
      <ArrowDropDownIcon sx={{ width: "20%", height: "20%"}} />
      <HomeGuidance
        mainText="3:「アプリをインストール」をタップ"
        img={guidanceAndroidSelect}
      />
      <ArrowDropDownIcon sx={{ width: "20%", height: "20%"}} />
      <HomeGuidance
        mainText="4:「インストール」をタップ"
        img={guidanceAndroidInstall}
      />
      <ArrowDropDownIcon sx={{ width: "20%", height: "20%"}} />
      <HomeGuidance
        mainText="5:アイコンが表示されたら成功です"
        img={guidanceAndroidResult}
      />
      <StyledButton
        text="戻る"
        onClick={handleButtonClick} />
    </>
  )
};

export default HomeGuidanceAndroid;