import noriaigoNo1 from "../img/noriaigoNo1.jpeg";
import noriaigoNo2 from "../img/noriaigoNo2.jpeg";
import noriaigoNo3 from "../img/noriaigoNo3.jpeg";
import noriaigoNo4 from "../img/noriaigoNo4.jpeg";
import noriaigoNo5 from "../img/noriaigoNo5.jpeg";

export const categorizeVehicle = (vehicleId: number): number => {
  if (vehicleId === 215 || vehicleId === 216) {
    return 1;
  } else if (vehicleId === 217 || vehicleId === 218) {
    return 2;
  } else if (vehicleId === 219 || vehicleId === 220) {
    return 3;
  } else if (vehicleId === 221 || vehicleId === 222) {
    return 4;
  } else if (vehicleId === 446) {
    return 5;
  }
  return 99;
};

export const categorizeImg = (vehicleNumber: number) => {
  switch (vehicleNumber) {
    case 1:
      return <img src={noriaigoNo1} width="90%" alt="1号車" />;
    case 2:
      return <img src={noriaigoNo2} width="90%" alt="2号車" />;
    case 3:
      return <img src={noriaigoNo3} width="90%" alt="3号車" />;
    case 4:
      return <img src={noriaigoNo4} width="90%" alt="4号車" />;
    case 5:
      return <img src={noriaigoNo5} width="90%" alt="5号車" />;
  }
  return <img src={noriaigoNo1} width="90%" alt="1号車(dev環境用)" />; // 富士通APIdev環境接続時用
};

export const categorizeAria = (vehicleNumber: number) => {
  switch (vehicleNumber) {
    case 1:
      return "大聖寺エリア";
    case 2:
      return "片山津エリア";
    case 3:
      return "山代山中エリア";
    case 4:
      return "山代山中エリア";
    case 5:
      return "エリア横断便";
  }
  return "その他のエリア";
};
