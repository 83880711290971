import React from "react";

interface ImageNotFoundProps {
  width?: number;
  height?: number;
}

function ImageNotFound(props: ImageNotFoundProps) {
  const { width, height } = props;

  return (
    <svg width={width || 134} height={height || 81} viewBox="0 0 134 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={width || 134} height={height || 81} fill="#EEEEEE" />
      <path
        d="M76 32H58C57 32 56 33 56 34V48C56 49.1 56.9 50 58 50H76C77 50 78 49 78 48V34C78 33 77 32 76 32ZM60 46L63.5 41.5L66 44.51L69.5 40L74 46H60Z"
        fill="#3A1D0B"
        fillOpacity="0.15"
      />
    </svg>
  );
}

export default ImageNotFound;
