import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
import { useMsal } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { InteractionRequiredAuthError } from "@azure/msal-common";

import { 
  Box,
  Typography,
  TextField,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import BackgroundHeader from "./components/BackgroundHeader";
import AlertTextMailAddress from "./elements/AlertTextMailAddress";

import { getGraphToken, getAdb2cUser, deleteUser } from "./utils/adb2c";
import ErrorDialog from "./components/ErrorDialog";
import ResignMembershipNote from "./components/ResignMembershipNote";

const styledTextField = {
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#FFFFFF"
    },
    "&:hover fieldset": {
      borderColor: "#FFFFFF"
    }
  },
  "& .MuiOutlinedInput-input": {
    height: "5px"
  },
  borderRadius: "10px",
  backgroundColor: "grey.A200",
  width: "80%"
}

const ResignMembership = () => {
  const { instance, accounts } = useMsal();
  const localId = accounts[0].localAccountId;
  const [to, setTo] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [isAgreement, setIsAgreement] = useState<boolean>(false);
  const [invalidMail, setInvalidMail] = useState<boolean>(false);
  const [invalidCode, setInvalidCode] = useState<boolean>(false);
  const [invalidAgreement, setInvalidAgreement] = useState<boolean>(false);
  const [visibleInputCode, setVisibleInputCode] = useState<boolean>(false);
  const [visibleResignMembership, setVisibleResignMembership] = useState<boolean>(false);
  const [isErrorDialog, setIsErrorDialog] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    setVisibleInputCode(false);
    setVisibleResignMembership(false);
  }, []);

  const verifyMail = (mail: string) => {
    const regex = /^[a-zA-Z0-9!#$%&'+^_`{}~-]+(?:\.[a-zA-Z0-9!#$%&'+^_`{}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/;
    return regex.test(mail);
  };

  const generateOtp = () => {
    const httpGenerateOtp = async () => {
      const scopes: string[] = (process.env.REACT_APP_ADB2C_SCOPES ?? "").split(",");
      const accessTokenRequest = {
        scopes: scopes,
        account: accounts[0],
      };
      let authorization = "";
      await instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          authorization = `Bearer ${accessTokenResponse.accessToken}`;
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenPopup(accessTokenRequest).then((accessTokenResponse) => {
              authorization = `Bearer ${accessTokenResponse.accessToken}`;
            });
          }
        });
      try {
        const isCorrectMail = await getGraphToken(authorization).then( async (res) => {
          if (res && res.status === 200) {
            const token = res.data.access_token;
            const user = await getAdb2cUser(localId, token).then((res) => {
              return res.data;
            });
            const emailIdentities = user.identities.filter((element: any) => element.signInType.includes("emailAddress"));
            if (emailIdentities[0].issuerAssignedId === to) {
              return true;
            }else {
              return false;
            }
          }else {
            return false;
          }
        });
        if (!isCorrectMail) {
          setInvalidMail(true);
          return;
        }
        const FUNCTIONS_ENDPOINT = process.env.REACT_APP_FUNCTIONS_ENDPOINT;
        const response: AxiosResponse = await axios.post(`${FUNCTIONS_ENDPOINT}/api/otp/generation`,
          {
            to: to,
          },
          {
            timeout: 100000,
            headers: {
              Authorization: authorization,
            },
          }
        )
        return response;
      } catch (e) {
        if (axios.isAxiosError(e) && e.response) {
          return e.response;
        }
      }
    }
    httpGenerateOtp().then((res) => {
      if (res !== undefined && res.status === 204) {
        setVisibleInputCode(true);
      }
    });
  };

  const verifyOtp = (code: string) => {
    const httpVerifyOtp = async () => {
      const scopes: string[] = (process.env.REACT_APP_ADB2C_SCOPES ?? "").split(",");
      const accessTokenRequest = {
        scopes: scopes,
        account: accounts[0],
      };
      let authorization = "";
      await instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          authorization = `Bearer ${accessTokenResponse.accessToken}`;
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenPopup(accessTokenRequest).then((accessTokenResponse) => {
              authorization = `Bearer ${accessTokenResponse.accessToken}`;
            });
          }
        });
      try {
        const FUNCTIONS_ENDPOINT = process.env.REACT_APP_FUNCTIONS_ENDPOINT;
        const response: AxiosResponse = await axios.post(`${FUNCTIONS_ENDPOINT}/api/otp/verification?code=${code}`,
          {
            to: to,
          },
          {
            timeout: 100000,
            headers: {
              Authorization: authorization,
            },
          }
        )
        return response;
      } catch (e) {
        if (axios.isAxiosError(e) && e.response) {
          return e.response;
        }
      }
    }
    if (Number.isNaN(Number(code))) {
      setInvalidCode(true)
      return
    }
    httpVerifyOtp().then((res) => {
      if (res !== undefined && res.status === 200) {
        const payload = res.data;
        if (payload.otp === "OK") {
          setInvalidCode(false);
          setVisibleResignMembership(true);
          setVisibleInputCode(false);
        }else {
          setInvalidCode(true);
        }
      }
    });
  };

  const resignUser = () => {
    const getAccessToken = async () => {
      const scopes: string[] = (process.env.REACT_APP_ADB2C_SCOPES ?? "").split(",");
      const accessTokenRequest = {
        scopes: scopes,
        account: accounts[0],
      };
      let authorization = "";
      await instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        authorization = `Bearer ${accessTokenResponse.accessToken}`;
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenPopup(accessTokenRequest).then((accessTokenResponse) => {
            authorization = `Bearer ${accessTokenResponse.accessToken}`;
          });
        }
      });
      try {
        const response = getGraphToken(authorization).then((res) => {
          return res;
        });

        return response;
      } catch (e) {
        if (axios.isAxiosError(e) && e.response) {
          return e.response;
        }
      }
    };

    const httpAdb2c = async (token: string) => {
      const user = await getAdb2cUser(localId, token).then((res) => {
        return res.data;
      });
      if (user) {
        return deleteUser(localId, token);
      }else {
        alert("アカウントがありません。")
      }
    };
  
    const httpSendMail = async (mail: string) => {
      const scopes: string[] = (process.env.REACT_APP_ADB2C_SCOPES ?? "").split(",");
      const accessTokenRequest = {
        scopes: scopes,
        account: accounts[0],
      };
      let authorization = "";
      await instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          authorization = `Bearer ${accessTokenResponse.accessToken}`;
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenPopup(accessTokenRequest).then((accessTokenResponse) => {
              authorization = `Bearer ${accessTokenResponse.accessToken}`;
            });
          }
        });
      try {
        const FUNCTIONS_ENDPOINT = process.env.REACT_APP_FUNCTIONS_ENDPOINT;
        const response: AxiosResponse = await axios.post(`${FUNCTIONS_ENDPOINT}/api/mail/resign`,
          {
            to: mail,
          },
          {
            timeout: 100000,
            headers: {
              Authorization: authorization,
            },
          }
        )
        return response;
      } catch (e) {
        if (axios.isAxiosError(e) && e.response) {
          return e.response;
        }
      }
    }
  
    getAccessToken().then((res) => {
      if (res && res.status === 200) {
        const token = res.data.access_token;
        httpAdb2c(token).then((res) => {
          if (res !== undefined && res.status === 204) {
            httpSendMail(to).then(() => {
              handleLogOut();
            })
          } else {
            setIsErrorDialog(true);
          }
        });
      } else {
        setIsErrorDialog(true);
      }
    });
  };

  const handleLogOut = async () => {
    const scopes: string[] = (process.env.REACT_APP_ADB2C_SCOPES ?? "").split(",");
    const accessTokenRequest = {
      scopes: scopes,
      account: accounts[0],
    };
    let idToken = "";
    await instance
    .acquireTokenSilent(accessTokenRequest)
    .then((tokenResponse) => {
      idToken = tokenResponse.idToken;
    })
    .catch((error) => {
      if (error instanceof InteractionRequiredAuthError) {
        instance.acquireTokenPopup(accessTokenRequest).then((tokenResponse) => {
          idToken = tokenResponse.idToken;
        });
      }
    });
    const logoutRequest = {
      postLogoutRedirectUri: msalConfig.auth.redirectUri,
      idTokenHint: idToken,
      mainWindowRedirectUri: msalConfig.auth.redirectUri
    }
    instance.logoutRedirect(logoutRequest);
    };

  return (
    <>
      <BackgroundHeader />
      <Box>
        <Box mt={2}>
          <Typography variant="h4" sx={{ color: "#333333", fontWeight: "bold" }}>退会の手続き</Typography>
        </Box>
        {
          visibleResignMembership &&
            <>
              <Box mt={1}>
                <Typography mr={5} ml={5} variant="body1" sx={{ color: "#333333" }}>
                  会員の退会手続きを行います。注意事項の記載内容をご確認の上チェックいただき「退会する」ボタンを押してください。
                </Typography>
              </Box>
              <ResignMembershipNote />
              {invalidAgreement && <Typography variant="body2" sx={{ width: "100%", color: "red" }}>注意事項の同意にチェックしてください</Typography> }
              <FormGroup>
                <FormControlLabel
                  sx={{ justifyContent: "center" }}
                  control={
                    <Checkbox onChange={() => {setIsAgreement(!isAgreement)}}/>
                  } label="同意する"/>
              </FormGroup>
              <Box mt={5}>
                <Button
                    onClick={() => { 
                      if (isAgreement) {
                        setInvalidAgreement(false);
                        resignUser();
                      }else {
                        setInvalidAgreement(true);
                      };
                    }}
                    sx={{
                      height: "5vh",
                      width: "70%",
                      borderRadius: "30px",
                      color: "secondary.main",
                      backgroundColor: "primary.main",
                      "&:hover": {
                        backgroundColor: "primary.main",
                      }
                    }}
                  >
                    <Typography variant="h6" fontWeight="bold">
                      退会する
                    </Typography>
                </Button>
              </Box>
            </>
        }
        {
          visibleInputCode &&
            <>
              <Box mt={1}>
                <TextField
                  value={to}
                  onChange={(e: any) => { setTo(e.target.value) }}
                  variant="outlined"
                  placeholder="現在の電子メールアドレスを入力"
                  sx={styledTextField}
                />
              </Box>
              <Box mt={3} sx={{ textAlign: "center", width: "100%" }}>
                <Typography variant="h6" sx={{ color: "#333333", fontWeight: "bold" }}>確認コード</Typography>
                { invalidCode && <Typography variant="body2" sx={{ width: "100%", color: "red" }}>確認コードが正しくありません</Typography> }
              </Box>
              <Box mt={1}>
                <TextField
                  value={code}
                  onChange={(e: any) => { setCode(e.target.value) }}
                  variant="outlined"
                  placeholder="確認コードを入力"
                  sx={styledTextField}
                />
              </Box>
              <Box mt={5}>
                <Button
                  onClick={() => { verifyOtp(code) }}
                  sx={{
                    height: "5vh",
                    width: "70%",
                    borderRadius: "30px",
                    color: "secondary.main",
                    backgroundColor: "primary.main",
                    "&:hover": {
                      backgroundColor: "primary.main",
                    }
                  }}
                >
                  <Typography variant="h6" fontWeight="bold">
                    コードの確認
                  </Typography>
                </Button>
              </Box>
              <Box mt={5}>
                <Button
                  onClick={() => {
                    setCode("");
                    setInvalidCode(false);
                    generateOtp();
                  }}
                  sx={{
                    height: "5vh",
                    width: "70%",
                    borderRadius: "30px",
                    color: "secondary.main",
                    backgroundColor: "primary.main",
                    "&:hover": {
                      backgroundColor: "primary.main",
                    }
                  }}
                >
                  <Typography variant="h6" fontWeight="bold">
                    新しいコードを送信
                  </Typography>
                </Button>
              </Box>
            </>
        }
        {
          !visibleInputCode && !visibleResignMembership && 
            <>
              <Box mt={3} sx={{ textAlign: "center", width: "100%" }}>
                <Typography variant="h6" sx={{ color: "#333333", fontWeight: "bold" }}>現在の電子メールアドレス</Typography>
                { invalidMail && !visibleResignMembership && <Typography variant="body2" sx={{ width: "100%", color: "red" }}>正しいメールアドレスではありません</Typography> }
              </Box>
              <Box mt={1}>
                <TextField
                  value={to}
                  onChange={(e: any) => { setTo(e.target.value) }}
                  variant="outlined"
                  placeholder="現在の電子メールアドレスを入力"
                  sx={styledTextField}
                />
              </Box>
              <Box mt={5}>
                <Button
                  onClick={() => {
                    if (verifyMail(to)) {
                      setInvalidCode(false);
                      setInvalidMail(false);
                      generateOtp();
                    }else {
                      setInvalidMail(true);
                    };
                  }}
                  sx={{
                    height: "5vh",
                    width: "70%",
                    borderRadius: "30px",
                    color: "secondary.main",
                    backgroundColor: "primary.main",
                    "&:hover": {
                      backgroundColor: "primary.main",
                    }
                  }}
                >
                  <Typography variant="h6" fontWeight="bold">
                    確認コードを送信
                  </Typography>
                </Button>
              </Box>
            </>
        }
        <Box mt={3} mb={2}>
          <Button
            onClick={() => { navigate("/") }}
            variant="outlined"
            sx={{
              height: "5vh",
              width: "70%",
              borderRadius: "30px",
              color: "#3a1d0b",
              backgroundColor: "#FFFFFF",
              "&:hover": {
                backgroundColor: "#FFFFFF",
              }
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              キャンセル
            </Typography>
          </Button>
        </Box>
        {
          !visibleResignMembership && 
            <AlertTextMailAddress />
        }
        {
          isErrorDialog &&
            <>
              <ErrorDialog 
                primaryText={"退会手続きに失敗しました。最初からやり直してください。"}
                handleClick={() => { navigate("/") }}
              />
            </>
        }
      </Box>
    </>
  )
}

export default ResignMembership;