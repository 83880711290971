import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import { InteractionRequiredAuthError } from "@azure/msal-common";
import { useMsal } from "@azure/msal-react";

import TopBar from "./TopBar";
import DialogCard from "./elements/DialogCard";
import BottomBar from "./components/SwipeableBottomBar";
import TutorialDialog from "./components/TutorialDialog";
import { Box, Typography, styled } from "@mui/material";

import IconButton from "./elements/IconButton";
import DisabledIconButton from "./elements/DisabledIconButton";
import busIcon from "./img/bus.svg";
import taxiIcon from "./img/taxi.svg";
import tutorial from "./img/noriaigo-tutorial.png";

import { getGraphToken, getAdb2cUser } from "./utils/loginForNoriaigo";

import { KagaAddressAtom } from "./state/atoms";

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: "bold",
}));

function DispachMenu() {
  const { instance, accounts } = useMsal();
  const [isTutorial, setIsTutorial] = useState<boolean>(true);
  const [isKagaAddress, setIsKagaAddress] = useRecoilState(KagaAddressAtom);
  const local_id = accounts[0].localAccountId;
  const navigate = useNavigate();
  /*
  function handleClickBus() {
    navigate("/dispach-noriaigo");
  }
  */

  function handleClickTaxi() {
    navigate("/dispach-taxi");
  }
  useEffect(() => {
    if (isKagaAddress === undefined) {
      const getAccessToken = async () => {
        const scopes: string[] = (process.env.REACT_APP_ADB2C_SCOPES ?? "").split(",");
        const accessTokenRequest = {
          scopes: scopes,
          account: accounts[0],
        };
        let authorization = "";
        await instance
          .acquireTokenSilent(accessTokenRequest)
          .then((accessTokenResponse) => {
            authorization = `Bearer ${accessTokenResponse.accessToken}`;
          })
          .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
              instance.acquireTokenPopup(accessTokenRequest).then((accessTokenResponse) => {
                authorization = `Bearer ${accessTokenResponse.accessToken}`;
              });
            }
          });
        try {
          const response = getGraphToken(authorization).then((res) => {
            return res;
          });

          return response;
        } catch (e: any) {
          if (axios.isAxiosError(e) && e.response) {
            return e.response;
          }
        }
      };

      const setIsKagaAddressAtom = async (token: string) => {
        const userInfo = await getAdb2cUser(local_id, token).then((res) => {
          return res.data;
        });
        const key_arr = Object.keys(userInfo);
        const extension_key = key_arr.find((element) => element.includes("Gender"))?.split("_")[1];
        const is_kaga_address_flag = userInfo[`extension_${extension_key}_is_kaga_address`];
        setIsKagaAddress(is_kaga_address_flag === undefined ? false : is_kaga_address_flag);
      };

      getAccessToken().then((res) => {
        if (res && res.status === 200) {
          const token = res.data.access_token;
          setIsKagaAddressAtom(token);
        } else {
          setIsKagaAddress(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main>
      <TopBar title="配車" hasMargin={true} />
      <Box m={4}>
        <StyledTypography variant="h5">配車種類をお選びください</StyledTypography>
      </Box>
      <Box my={4} mx={3}>
        <>
          <Typography fontSize={"0.7rem"} color={"red"}>
            のりあい号は只今休止中です
          </Typography>
          <DisabledIconButton
            textElement={<StyledTypography>のりあい号を予約する</StyledTypography>}
            iconElement={<img alt="busIcon" src={busIcon} />}
            justifyContent="left"
            height="4rem"
          />
        </>
      </Box>
      <Box my={4} mx={3}>
        <IconButton
          textElement={<StyledTypography>タクシーを配車する</StyledTypography>}
          iconElement={<img alt="taxiIcon" src={taxiIcon} />}
          onClick={() => {
            handleClickTaxi();
          }}
          justifyContent="left"
          height="4rem"
        />
      </Box>
      <BottomBar />
      {!localStorage.getItem("KAGA-NORIAIGO-TUTORIAL") && isTutorial && (
        <TutorialDialog
          isChecked={false}
          title={"タクシー事業者電話帳から直接配車依頼もできます！"}
          description={
            "電話アイコンをタップすればすぐ電話！電話帳から探す手間がかかりません。（回線ご契約プランに応じた通話料金が発生します）"
          }
          buttonText={"配車機能を使う"}
          card={<DialogCard image={tutorial} />}
          handleClick={(isAgree) => {
            if (isAgree) {
              localStorage.setItem("KAGA-NORIAIGO-TUTORIAL", "agree");
            }
            setIsTutorial(false);
          }}
        />
      )}
    </main>
  );
}

export default DispachMenu;
