import { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Box, Button, List, ListItem, ListItemText, Divider, styled, Typography } from "@mui/material";
import { noriaigoOriginAtom, noriaigoDestinationAtom, ReservationCandidateAtom, bottomBarAtom } from "./state/atoms";
import { ReservationCandidate } from "./types/noriaigo";
import ErrorDialog from "./components/ErrorDialog";
import TopBar from "./TopBar";
import BottomBar, { drawerHeight } from "./components/SwipeableBottomBar";
import { categorizeVehicle, categorizeImg } from "./utils/categorizedVehicle";

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: "bold",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
}));

const FixedBottomButton = styled(StyledButton)({
  borderRadius: "30px",
  width: "70%",
});

const ReservationCompletionForNoriaigo = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const open = useRecoilValue(bottomBarAtom);
  const noriaigoOrigin = useRecoilValue(noriaigoOriginAtom);
  const noriaigoDestination = useRecoilValue(noriaigoDestinationAtom);
  const setNoriaigoOrigin = useSetRecoilState(noriaigoOriginAtom);
  const setNoriaigoDestination = useSetRecoilState(noriaigoDestinationAtom);
  const noriaigoCandidate = useRecoilValue(ReservationCandidateAtom);
  const [selectedNoriaigoCandidate, setSelectedNoriaigoCandidate] = useState<ReservationCandidate | null>(null);
  const [isErrorDialog, setIsErrorDialog] = useState<boolean>(false);
  const number: Number = location.state.number;
  const date: Date = location.state.date;
  const candidateId: string | null = location.state.candidateId;
  const dayOfWeekStr = date && ["日", "月", "火", "水", "木", "金", "土"][date.getDay()];

  const resetNoriaigoData = () => {
    setNoriaigoOrigin(null);
    setNoriaigoDestination(null);
  };

  const swapNoriaigoData = () => {
    const tempOrigin = noriaigoOrigin;
    const tempDestination = noriaigoDestination;
    setNoriaigoOrigin(tempDestination);
    setNoriaigoDestination(tempOrigin);
  };

  useEffect(() => {
    if (!noriaigoCandidate) {
      setIsErrorDialog(true);
    }
    noriaigoCandidate?.forEach((selectedReservation) => {
      if (Number(candidateId) === selectedReservation.id) {
        setSelectedNoriaigoCandidate(selectedReservation);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ overflow: "scroll", height: `${window.innerHeight}px` }}>
      <TopBar title={"デマンド予約"} hasMargin={false} showReturnIcon={false} opacity="1" />
      <List sx={{ top: "72px" }}>
        <ListItem>
          <StyledListItemText primary={"予約確定"} primaryTypographyProps={{ fontWeight: "bold", fontSize: "18px" }} />
        </ListItem>
        <ListItem sx={{ paddingBottom: 0 }}>
          <StyledListItemText primary={"予約番号"} primaryTypographyProps={{ fontWeight: "bold", fontSize: "16px" }} />
        </ListItem>
        <Divider sx={{ borderStyle: "dashed", borderColor: "#F2A762" }} />
        <ListItem>
          <ListItemText primary={params.id} />
        </ListItem>
        <ListItem sx={{ paddingBottom: 0 }}>
          <StyledListItemText primary={"運行日"} primaryTypographyProps={{ fontWeight: "bold", fontSize: "16px" }} />
        </ListItem>
        <Divider sx={{ borderStyle: "dashed", borderColor: "#F2A762" }} />
        <ListItem>
          <ListItemText
            primary={date && `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日（${dayOfWeekStr}）`}
          />
        </ListItem>
        <ListItem sx={{ paddingBottom: 0 }}>
          <StyledListItemText primary={"乗客数"} primaryTypographyProps={{ fontWeight: "bold", fontSize: "16px" }} />
        </ListItem>
        <Divider sx={{ borderStyle: "dashed", borderColor: "#F2A762" }} />
        <ListItem>
          <ListItemText primary={`${number}人`} />
        </ListItem>
        <ListItem sx={{ paddingBottom: 0 }}>
          <StyledListItemText primary={"経路"} primaryTypographyProps={{ fontWeight: "bold", fontSize: "16px" }} />
        </ListItem>
        <Divider sx={{ borderStyle: "dashed", borderColor: "#F2A762" }} />
        <ListItem>
          <ListItemText
            primary={`${noriaigoOrigin && noriaigoOrigin.name}→${noriaigoDestination && noriaigoDestination.name}`}
          />
        </ListItem>
        <ListItem sx={{ paddingBottom: 0 }}>
          <StyledListItemText primary={"予約時刻"} primaryTypographyProps={{ fontWeight: "bold", fontSize: "16px" }} />
        </ListItem>
        <Divider sx={{ borderStyle: "dashed", borderColor: "#F2A762" }} />
        <ListItem>
          <ListItemText
            primary={`現時点予定 ${
              selectedNoriaigoCandidate &&
              new Date(selectedNoriaigoCandidate.departure_time).getHours().toString().padStart(2, "0")
            }:${
              selectedNoriaigoCandidate &&
              new Date(selectedNoriaigoCandidate.departure_time).getMinutes().toString().padStart(2, "0")
            } → ${
              selectedNoriaigoCandidate &&
              new Date(selectedNoriaigoCandidate.arrival_time).getHours().toString().padStart(2, "0")
            }:${
              selectedNoriaigoCandidate &&
              new Date(selectedNoriaigoCandidate.arrival_time).getMinutes().toString().padStart(2, "0")
            }`}
          />
        </ListItem>
        <ListItem>
          <ListItemText primary={"※予約時刻は、前後の予約状況に応じて変更される可能性があります"} />
        </ListItem>
        <ListItem sx={{ paddingBottom: 0 }}>
          <StyledListItemText primary={"号車"} primaryTypographyProps={{ fontWeight: "bold", fontSize: "16px" }} />
        </ListItem>
        <Divider sx={{ borderStyle: "dashed", borderColor: "#F2A762" }} />
        <ListItem sx={{ justifyContent: "center" }}>
          {selectedNoriaigoCandidate && categorizeImg(categorizeVehicle(selectedNoriaigoCandidate.vehicle_id))}
        </ListItem>
      </List>
      <Box mt={10}>
        <FixedBottomButton
          onClick={() => {
            resetNoriaigoData();
            navigate("/dispach-noriaigo");
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            予約メニューに戻る
          </Typography>
        </FixedBottomButton>
      </Box>
      <Box mt={5} mb={5}>
        <FixedBottomButton
          onClick={() => {
            swapNoriaigoData();
            navigate("/noriaigo/search");
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            続けて帰りも予約する
          </Typography>
        </FixedBottomButton>
        { 
          open &&
          <Box sx={{height: `${drawerHeight}px`}}></Box>
        }
        <BottomBar defaultOpen={true}/>
      </Box>
      {isErrorDialog ? (
        <ErrorDialog
          primaryText={"予約は完了しています。メニュー画面へ戻ります。"}
          handleClick={() => {
            navigate("/dispach-noriaigo");
          }}
        />
      ) : null}
    </Box>
  );
};
export default ReservationCompletionForNoriaigo;
