import React from "react";
import { useSetRecoilState } from "recoil";

import {
  Backdrop,
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Paper,
  Typography,
  styled,
} from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

import { poiSearchTextAtom } from "../state/atoms";
import { appendLocalStorageItem } from "../utils/localStorage";

interface PoiSearchHistoryProps {
  isOpen: boolean;
  handleClose: () => void;
  marginTop: number;
  searchHistories: string[];
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  background: theme.palette.grey.A100,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.light,
}));

function PoiSearchHistory(props: PoiSearchHistoryProps) {
  const { isOpen, handleClose, marginTop, searchHistories } = props;
  const setPoiSearchValue = useSetRecoilState(poiSearchTextAtom);

  return isOpen ? (
    <Backdrop open={isOpen} invisible={true} onClick={handleClose} sx={{ mt: `${marginTop}px` }}>
      <StyledPaper elevation={16}>
        <Box sx={{ p: 0.5, backgroundColor: "grey.A200" }}>
          <StyledTypography align="left" fontSize={14}>
            履歴
          </StyledTypography>
        </Box>
        <Divider />
        <List sx={{ padding: 0 }}>
          {searchHistories.map((searchHistory: string, index) => (
            <React.Fragment key={index}>
              <ListItemButton
                onClick={() => {
                  appendLocalStorageItem("KAGA-POI-SEARCH-HISTORY", searchHistory);
                  setPoiSearchValue(searchHistory);
                }}
                sx={{ backgroundColor: "grey.A100" }}
                alignItems="flex-start"
              >
                <ListItemIcon
                  sx={{
                    minWidth: "40px",
                    margin: 0,
                  }}
                >
                  <LocationOnOutlinedIcon
                    sx={{
                      color: "secondary.light",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary={<StyledTypography>{searchHistory}</StyledTypography>} sx={{ margin: 0 }} />
              </ListItemButton>
              {index !== searchHistories.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </StyledPaper>
    </Backdrop>
  ) : (
    <></>
  );
}

export default PoiSearchHistory;
