import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import { format } from "date-fns-tz";

import { defaultLocatioin, defaultZoomLevel } from "../utils/constants";
import { SearchNoriaigo } from "../types/noriaigo";
import { ReservationCandidate } from "../types/noriaigo";
import { Reservation } from "../types/noriaigo";

const { persistAtom } = recoilPersist();

export const selectedCourseAtom = atom({
  key: "selectedCourseAtom",
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export const viewportAtom = atom<any>({
  key: "viewportAtom",
  default: {
    latitude: defaultLocatioin.latitude,
    longitude: defaultLocatioin.longitude,
    zoom: defaultZoomLevel,
    bearing: 0,
    pitch: 0,
  },
});

export const poiSearchTextAtom = atom<string>({
  key: "poiSearchTextAtom",
  default: "",
});

export const bottomBarAtom = atom<boolean>({
  key: "bottomBarAtom",
  default: true,
});

export const noriaigoOriginAtom = atom<SearchNoriaigo | null>({
  key: "noriaigoOriginAtom",
  default: null,
});

export const noriaigoDestinationAtom = atom<SearchNoriaigo | null>({
  key: "noriaigoDestinationAtom",
  default: null,
});

export const ReservationCandidateAtom = atom<ReservationCandidate[] | null>({
  key: "reservationCandidateAtom",
  default: null,
});

export const SelectedReservationAtom = atom<Reservation | null>({
  key: "selectedReservationAtom",
  default: null,
});

export const ReservationsAtom = atom<Reservation[] | null>({
  key: "reservationAtom",
  default: null,
});

export const SelectedTimeAtom = atom<string>({
  key: "selectedTimeAtom",
  default: "",
});

export const TimesInfoAtom = atom<string[]>({
  key: "timesInfoAtom",
  default: [],
});

export const SelectedDateAtom = atom<string>({
  key: "selectedDateAtom",
  default: format(new Date(), "yyyy-MM-dd"),
});

export const KagaAddressAtom = atom<boolean | undefined>({
  key: "kagaAddressAtom",
  default: undefined,
});