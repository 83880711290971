import React, { useState, useEffect } from "react";
import axios, { AxiosResponse } from "axios";
// import { InteractionRequiredAuthError } from "@azure/msal-browser";
// import { useMsal } from "@azure/msal-react";

import { useRecoilValue } from "recoil";
import { bottomBarAtom } from "./state/atoms";

import { Button, IconButton, Typography, Box, styled } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import TopBar from "./TopBar";
import DialogCard from "./elements/DialogCard";
import TutorialDialog from "./components/TutorialDialog";
import SwipeableBottomBar, { drawerBleeding, drawerHeight } from "./components/SwipeableBottomBar";
import Map from "./components/Map";
import LocationControl from "./elements/LocationControl";
import MapNavigationControl from "./elements/MapNavigationControl";
import PinLayer from "./components/PinLayer";
import { PinType } from "./types";
import { viewportAtom } from "./state/atoms";

import StationIcon from "./img/station.svg";
import CanbusIcon from "./img/canbus.svg";
import BusStopIcon from "./img/busstop.svg";
// import NoriaigoIcon from "./img/noriaigo.svg";
import tutorial from "./img/bus-tutorial.png";

import DisabledStationIcon from "./img/disabled-station.svg";
import DisabledCanbusIcon from "./img/disabled-canbus.svg";
import DisabledBusStopIcon from "./img/disabled-busstop.svg";
// import DisabledNoriaigoIcon from "./img/disabled-noriaigo.svg";

const MIXWAY_ACCESS_KEY = process.env.REACT_APP_MIXWAY_ACCESS_KEY!;
// const APIM_ENDPOINT = process.env.REACT_APP_APIM_ENDPOINT!;

const StyledButton = styled(Button)(({ theme }) => ({
  height: "5vh",
  width: "70%",
  borderRadius: "30px",
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
}));

const DisabledStyledButton = styled(Button)(({ theme }) => ({
  height: "5vh",
  width: "70%",
  borderRadius: "30px",
  color: "#3a1d0b",
  backgroundColor: "#eeeeee",
  "&:hover": {
    backgroundColor: "#eeeeee",
  },
}));

// const R = Math.PI / 180;

/*
function getDistance(lat1: number, lng1: number, lat2: number, lng2: number) {
  lat1 *= R;
  lng1 *= R;
  lat2 *= R;
  lng2 *= R;
  return 6371 * Math.acos(Math.cos(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1) + Math.sin(lat1) * Math.sin(lat2));
}
*/

function BusStop() {
  // const { instance, accounts } = useMsal();

  const [enableIcon, setEnableIcon] = useState({
    train: true,
    bus: true,
    canbus: true,
    noriaigo: true,
  });
  const [trainData, setTrainData] = useState<PinType[]>([]);
  const [busData, setBusData] = useState<PinType[]>([]);
  const [canbusData, setCanbusData] = useState<PinType[]>([]);
  // const [noriaigoData, setNoriaigoData] = useState<PinType[]>([]);
  const viewport = useRecoilValue(viewportAtom);
  const open = useRecoilValue(bottomBarAtom);
  const [busLoading, setBusLoading] = useState<boolean>(false);
  const [trainLoading, setTrainLoading] = useState<boolean>(false);
  // const [noriaigoLoading, setNoriaigoLoading] = useState<boolean>(false);
  const [isTutorial, setIsTutorial] = useState<boolean>(true);
  const [searchableZoomLevel, setSearchableZoomLevel] = useState<boolean>(true);

  useEffect(() => {
    if (viewport.zoom < 13.5) {
      setSearchableZoomLevel(false);
    } else {
      setSearchableZoomLevel(true);
    }
  }, [viewport.zoom]);

  const StyledDiv = styled("div")({
    position: "fixed",
    bottom: open ? drawerHeight + drawerBleeding + 10 : drawerBleeding + 10,
    width: "100%",
  });

  const getPoints = async (latitude: number, longitude: number, radius: number, type: string) => {
    const url = "https://mixway.ekispert.jp/v1/json/geo/point";
    const response: AxiosResponse = await axios.get(
      `${url}?key=${MIXWAY_ACCESS_KEY}&geoPoint=${latitude},${longitude},${radius}&type=${type}`,
      {
        timeout: 100000,
      }
    );
    return response;
  };
  /*
  const getNoriaiGoBusStops = async () => {
    const scopes: string[] = (process.env.REACT_APP_ADB2C_SCOPES ?? "").split(",");
    const accessTokenRequest = {
      scopes: scopes,
      account: accounts[0],
    };

    let authorization = "";
    await instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        authorization = `Bearer ${accessTokenResponse.accessToken}`;
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance
            .acquireTokenPopup(accessTokenRequest)
            .then(function (accessTokenResponse) {
              authorization = `Bearer ${accessTokenResponse.accessToken}`;
            })
            .catch(function (error) {
              alert(error);
            });
        }
        setNoriaigoLoading(false);
      });
    const response: AxiosResponse = await axios.get(`${APIM_ENDPOINT}/noriaigo`, {
      timeout: 100000,
      headers: {
        Authorization: authorization,
      },
    });
    return response;
  };
  */

  const searchBusStops = (lat: number, lon: number) => {
    getPoints(lat, lon, 1500, "train").then((res) => {
      const responseData = res.data.ResultSet.Point;
      let targetData: PinType[] = [];
      responseData.forEach((d: any) => {
        targetData.push({
          title: d.Station.Name,
          latitude: Number(d.GeoPoint.lati_d),
          longitude: Number(d.GeoPoint.longi_d),
          iconType: d.Station.Type.text === undefined ? d.Station.Type : d.Station.Type.text,
        });
      });
      setTrainData(targetData);
      setTrainLoading(false);
    });
    getPoints(lat, lon, 1500, "bus").then((res) => {
      const responseData = res.data.ResultSet.Point;
      let targetData: PinType[] = [];
      let targetCanBusData: PinType[] = [];
      responseData.forEach((d: any) => {
        const operator = d.Station.Name.substring(d.Station.Name.lastIndexOf("／") + 1);
        if (operator === "まちづくり加賀") {
          targetCanBusData.push({
            title: d.Station.Name,
            latitude: Number(d.GeoPoint.lati_d),
            longitude: Number(d.GeoPoint.longi_d),
            iconType: "canbus",
          });
        } else if (operator === "北陸鉄道バス") {
          targetData.push({
            title: d.Station.Name,
            latitude: Number(d.GeoPoint.lati_d),
            longitude: Number(d.GeoPoint.longi_d),
            iconType: d.Station.Type.text === undefined ? d.Station.Type : d.Station.Type.text,
          });
        }
      });
      setBusData(targetData);
      setCanbusData(targetCanBusData);
      setBusLoading(false);
    });
    /*
    getNoriaiGoBusStops().then((res) => {
      const responseData = res.data;
      let targetData: PinType[] = [];
      responseData.forEach((d: any) => {
        const distance = getDistance(lat, lon, Number(d.latitude), Number(d.longitude));
        if (distance <= 1.5) {
          targetData.push({
            title: d.name,
            latitude: Number(d.latitude),
            longitude: Number(d.longitude),
            iconType: "noriaigo",
          });
        }
      });
      setNoriaigoData(targetData);
      setNoriaigoLoading(false);
    });
    */
  };

  return (
    <>
      <main>
        <TopBar title="乗り場情報" hasMargin={false} />
        {(busLoading || trainLoading) && (
          <Box sx={{ zIndex: 10, bgcolor: "#000000", opacity: "0.6", pointerEvents: "none", position: "fixed" }}>
            <Box sx={{ width: "100vw", height: "100vh" }}>
              <Box sx={{ position: "absolute", top: 0, right: 0, bottom: 0, left: 0, margin: "auto", height: "50px" }}>
                <CircularProgress />
              </Box>
            </Box>
          </Box>
        )}
        <Box sx={{ pointerEvents: busLoading || trainLoading ? "none" : "auto" }}>
          <Map>
            <LocationControl style={{ right: 10, top: 60 }} />
            <MapNavigationControl style={{ right: 10, top: 100 }} />
            {enableIcon.train && trainData.length >= 0 && <PinLayer data={trainData} type="train" />}
            {enableIcon.bus && busData.length >= 0 && <PinLayer data={busData} type="bus" />}
            {enableIcon.canbus && canbusData.length >= 0 && <PinLayer data={canbusData} type="canbus" />}
          </Map>
          <StyledDiv>
            <div>
              <IconButton
                aria-label="train"
                onClick={() => {
                  setEnableIcon({ ...enableIcon, train: !enableIcon.train });
                }}
              >
                {enableIcon.train ? (
                  <img src={StationIcon} alt="train" />
                ) : (
                  <img src={DisabledStationIcon} alt="train" />
                )}
              </IconButton>
              <IconButton
                aria-label="canbus"
                onClick={() => {
                  setEnableIcon({ ...enableIcon, canbus: !enableIcon.canbus });
                }}
              >
                {enableIcon.canbus ? (
                  <img src={CanbusIcon} alt="canbus" />
                ) : (
                  <img src={DisabledCanbusIcon} alt="canbus" />
                )}
              </IconButton>
              <IconButton
                aria-label="busstop"
                onClick={() => {
                  setEnableIcon({ ...enableIcon, bus: !enableIcon.bus });
                }}
              >
                {enableIcon.bus ? (
                  <img src={BusStopIcon} alt="busstop" />
                ) : (
                  <img src={DisabledBusStopIcon} alt="busstop" />
                )}
              </IconButton>
            </div>
            {searchableZoomLevel ? (
              <StyledButton
                onClick={() => {
                  setBusLoading(true);
                  setTrainLoading(true);
                  // setNoriaigoLoading(true);
                  searchBusStops(viewport.latitude, viewport.longitude);
                }}
              >
                <Typography variant="h6" fontWeight="bold">
                  このエリアで検索する
                </Typography>
              </StyledButton>
            ) : (
              <DisabledStyledButton>
                <Typography variant="h6" fontWeight="bold">
                  地図を拡大してください
                </Typography>
              </DisabledStyledButton>
            )}
          </StyledDiv>
          <SwipeableBottomBar defaultOpen={true} />
        </Box>
        {!localStorage.getItem("KAGA-BUSSTOP-TUTORIAL") && isTutorial && (
          <TutorialDialog
            isChecked={false}
            title={"乗り場情報はいろんな種類の乗り物がわかりやすい！"}
            description={
              "どの乗り場で乗ればいいの？を解消。乗りたい路線を地図上で確認できるから、外出しても安心して乗り場を探せます。"
            }
            buttonText={"乗り場情報を見る"}
            card={<DialogCard image={tutorial} />}
            handleClick={(isAgree) => {
              if (isAgree) {
                localStorage.setItem("KAGA-BUSSTOP-TUTORIAL", "agree");
              }
              setIsTutorial(false);
            }}
          />
        )}
      </main>
    </>
  );
}

export default BusStop;
