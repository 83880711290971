import React from "react";
import { NavigationControl } from "react-map-gl";

type NavigationControlStyle = {
  right: number;
  top: number;
};

type NavigationControlType = {
  style: NavigationControlStyle;
};

function MapNavigationControl(props: NavigationControlType) {
  const { style } = props;
  return (
    <NavigationControl
      style={style}
      showCompass={false}
    />
  );
}

export default MapNavigationControl;
