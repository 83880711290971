import React, { useState, useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { useParams, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-common";
import axios, { AxiosResponse } from "axios";

import { useRecoilValue } from "recoil";
import { viewportAtom } from "./state/atoms";
import { Box, Button, styled, Typography, OutlinedInput, InputAdornment, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from "@mui/material/CircularProgress";

import TopBar from "./TopBar";
import BottomBar, { drawerBleeding, drawerHeight } from "./components/SwipeableBottomBar";
import Map from "./components/Map";
import LocationControl from "./elements/LocationControl";
import PinLayer from "./components/PinLayer";
import { SearchNoriaigo } from "./types/noriaigo";
import { PositionType, PinType } from "./types";
import { defaultLocatioin } from "./utils/constants";
import { noriaigoOriginAtom, noriaigoDestinationAtom, bottomBarAtom } from "./state/atoms";

const APIM_ENDPOINT = process.env.REACT_APP_APIM_ENDPOINT!;

const StyledButton = styled(Button)(({ theme }) => ({
  height: "5vh",
  width: "70%",
  borderRadius: "30px",
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
}));

const R = Math.PI / 180;

function getDistance(lat1: number, lng1: number, lat2: number, lng2: number) {
  lat1 *= R;
  lng1 *= R;
  lat2 *= R;
  lng2 *= R;
  return 6371 * Math.acos(Math.cos(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1) + Math.sin(lat1) * Math.sin(lat2));
}

const LocationMapForNoriaigo = () => {
  const { instance, accounts } = useMsal();
  const { id } = useParams();
  const [mapCenter, setMapCenter] = useState<PositionType>(defaultLocatioin);
  const [noriaigoData, setNoriaigoData] = useState<PinType[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<PinType>();
  const [noriaigoLocations, setNoriaigoLocations] = useState<SearchNoriaigo[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const viewport = useRecoilValue(viewportAtom);
  const setNoriaigoOrigin = useSetRecoilState(noriaigoOriginAtom);
  const setNoriaigoDestination = useSetRecoilState(noriaigoDestinationAtom);
  const open = useRecoilValue(bottomBarAtom);

  let navigate = useNavigate();

  const StyledDiv = styled("div")({
    position: "fixed",
    bottom: open ? drawerHeight + drawerBleeding + 10 : drawerBleeding + 10,
    width: "100%",
  });
  
  function searchOrigin(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    e.preventDefault();
    navigate("/noriaigo/search/location/origin");
  }

  function searchDestination(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    e.preventDefault();
    navigate("/noriaigo/search/location/destination");
  }

  const handleSelectedLocation = () => {
    let location: SearchNoriaigo | null = null;
    noriaigoLocations &&
      noriaigoLocations.forEach((element) => {
        if (selectedLocation?.title === element.name) {
          location = element;
        }
      });
    id === "origin" ? setNoriaigoOrigin(location) : setNoriaigoDestination(location);
    navigate("/noriaigo/search");
  };

  useEffect(() => {
    const getNoriaigoBusStops = async () => {
      const scopes: string[] = (process.env.REACT_APP_ADB2C_SCOPES ?? "").split(",");
      const accessTokenRequest = {
        scopes: scopes,
        account: accounts[0],
      };
      let authorization = "";
      await instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          authorization = `Bearer ${accessTokenResponse.accessToken}`;
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenPopup(accessTokenRequest).then((accessTokenResponse) => {
              authorization = `Bearer ${accessTokenResponse.accessToken}`;
            });
          }
          setIsLoading(false);
        });
      const response: AxiosResponse = await axios.get(`${APIM_ENDPOINT}/noriaigo`, {
        timeout: 100000,
        headers: {
          Authorization: authorization,
        },
      });
      return response;
    };
    getNoriaigoBusStops().then((res) => {
      const responseData = res.data;
      let targetData: PinType[] = [];
      setNoriaigoLocations(responseData);
      responseData.forEach((d: SearchNoriaigo) => {
        const distance = getDistance(mapCenter.latitude, mapCenter.longitude, Number(d.latitude), Number(d.longitude));
        if (distance <= 1) {
          targetData.push({
            title: d.name,
            latitude: Number(d.latitude),
            longitude: Number(d.longitude),
          });
        }
      });
      setNoriaigoData(targetData);
      setIsLoading(false);
    });
  }, [mapCenter, instance, accounts]);
  return (
    <>
      <main>
        <TopBar title={id === "origin" ? "出発地" : "目的地"} hasMargin={false} showReturnIcon={true} opacity="1" />
        {isLoading &&
          <Box sx={{zIndex: 20, bgcolor: "#000000", opacity:'0.6', pointerEvents: 'none', position: 'fixed'}}>
            <Box sx={{width: '100vw',height: '100vh'}}>
              <Box sx={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, margin: "auto", height: "50px"}}>
                <CircularProgress />
              </Box>
            </Box>
          </Box>
        }
        <Box sx={{pointerEvents: isLoading ? 'none' : 'auto'}}>
          <Box
            sx={{
              display: "flex",
              position: "fixed",
              top: "48px",
              width: "100%",
              height: "12vh",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 10,
              backgroundColor: "#FFFFFF",
            }}
          >
            <OutlinedInput
            sx={{ position: "relative", width: "90%", height: "40px" }}
            type="text"
              placeholder="バス停名で検索してください"
              onFocus={id === "origin" ? searchOrigin : searchDestination}
              startAdornment={
                <InputAdornment position="start" sx={{ padding: "0px" }}>
                  <IconButton edge="start">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Box>
          <Map>
            <LocationControl
              style={{ right: 10, top: 160 }}
              onGeolocate={(position: GeolocationPosition) => {
                setMapCenter({
                  latitude: position["coords"].latitude,
                  longitude: position["coords"].longitude,
                });
              }}
            />
            {noriaigoData.length >= 0 && (
              <PinLayer
                data={noriaigoData}
                handleClick={() => {
                  handleSelectedLocation();
                }}
                setSelectedLocation={setSelectedLocation}
              />
            )}
          </Map>
          <StyledDiv>
            <StyledButton
              onClick={() => {
                setIsLoading(true);
                setMapCenter({
                  latitude: viewport.latitude,
                  longitude: viewport.longitude,
                });
              }}
            >
              <Typography variant="h6" fontWeight="bold">
                このエリアで検索する
              </Typography>
            </StyledButton>
          </StyledDiv>
        </Box>
      </main>
      <BottomBar defaultOpen={true}/>
    </>
  );
};

export default LocationMapForNoriaigo;
