import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { bottomBarAtom } from "./state/atoms";
import { useRecoilState, useRecoilValue } from "recoil";
import { styled } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from "@mui/material/InputAdornment";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import { format } from "date-fns-tz";
import { useForm, Controller } from "react-hook-form";

import TopBar from "./TopBar";
import SwipeableBottomBar, { drawerBleeding, drawerHeight } from "./components/SwipeableBottomBar";
import { queryState, ListItems, SearchTypes } from "./store";

import "./RouteOptions.css";

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
  "&.Mui-disabled": {
    background: "lightgray",
  },
}));

const RouteOptions = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useRecoilState(queryState);
  const open = useRecoilValue(bottomBarAtom);

  const FixedBottomButton = styled(StyledButton)({
    borderRadius: "30px",
    position: "fixed",
    bottom: open ? drawerHeight + drawerBleeding + 10 : drawerBleeding + 10,
    height: "5vh",
    width: "70%",
    left: "50%",
    transform: "translateX(-50%);",
  });  

  function anyUncheckedMethod(newCheckedMethods: string[]) {
    if (newCheckedMethods && newCheckedMethods.length > 0) {
      const defaultMethodsSorted: string[] = defaultMethods.slice().sort();
      const newCheckedMethodsSorted: string[] = newCheckedMethods.slice().sort();
      return !(
        newCheckedMethodsSorted.length === defaultMethods.length &&
        newCheckedMethodsSorted.every(function (value, index) {
          return value === defaultMethodsSorted[index];
        })
      );
    } else {
      return true;
    }
  }

  const parseStringTime = (time: string | undefined) => {
    const dateTimePickerFromat = "yyyy-MM-dd'T'HH:mm";
    const now_str = format(new Date(), dateTimePickerFromat);
    if (!time) { return now_str; }
    return format(new Date(time), dateTimePickerFromat);
  };

  // local state before committing by
  const [time, setTime] = useState<string>(parseStringTime(query.time));
  const [searchType, setSearchType] = useState<string>(query.searchType);
  const defaultMethods: string[] = ListItems.map((item) => item.id);
  const [checkedMethods, setCheckedMethods] = useState(query?.methods ?? defaultMethods); // all checked by default

  const { control } = useForm({
    defaultValues: { methods: defaultMethods },
  });

  function handleSelect(checkedMethod: string) {
    const newCheckedMethods = checkedMethods?.includes(checkedMethod)
      ? checkedMethods?.filter((name) => name !== checkedMethod)
      : [...(checkedMethods ?? []), checkedMethod];
    setCheckedMethods(newCheckedMethods);
    return newCheckedMethods;
  }

  function handleTimeChange(e: any) {
    setTime(e.target.value);
  }

  function handleSearchTypeChange(event: any) {
    console.info(event.target.value);
    setSearchType(event.target.value);
    if (event.target.value !== "departure" && !time) {
      const now = new Date();
      setTime(now.toDateString());
    }
  }

  const setOption = () => {
    let newQuery = { ...query };
    // set time
    if (time) {
      // override the browser's given timezone with JST
      newQuery.time = format(new Date(time), "yyyy-MM-dd'T'HH:mm:00+0900");
    } else {
      delete newQuery.time;
    }

    // set search type
    newQuery.searchType = searchType;
    if (searchType !== "departure" && !time) {
      const now = new Date();
      newQuery.time = format(now, "yyyy-MM-dd'T'HH:mm:00+0900");
    }
    // set checked methods
    if (anyUncheckedMethod(checkedMethods)) {
      newQuery.methods = checkedMethods;
    } else {
      delete newQuery.methods;
    }
    setQuery(newQuery);
    navigate("/search");
  };

  return (
    <>
      <TopBar title="経路検索オプション" hasMargin={true} showReturnIcon={true} />
      <Stack component="form" className="optionsForm" noValidate spacing={0}>
        <div className="block">
          <OutlinedInput
            type="datetime-local"
            onChange={(e) => {handleTimeChange(e)}}
            id="dateTimePicker"
            value={time}
            fullWidth
            inputProps={{ min: "2022-01-01T00:00", }}
            startAdornment={
              <InputAdornment position="start">
                <AccessTimeIcon />
              </InputAdornment>
            }
          />
          <Box sx={{ textAlign: 'right' }}>
            <Box
              sx={{ marginTop: '8px', fontSize: '0.9rem', color: '#3a1d0b', fontWeight: 'bold' }}
              onClick={() => {setTime(format(new Date(), "yyyy-MM-dd'T'HH:mm"))}}
            >
              現在時刻に戻す
            </Box>
          </Box>
        </div>
        <div className="block">
          <ToggleButtonGroup
            color="primary"
            fullWidth={true}
            value={searchType}
            exclusive
            onChange={handleSearchTypeChange}
            className="searchTypeGroup"
          >
            {SearchTypes.map((item, index) => (
              <ToggleButton key={item.id} value={item.id}>
                {item.text}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div>

        <div className="block">
          <FormGroup row={false}>
            {ListItems.map((item, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Controller
                    name="methods"
                    render={({ field: { onChange: onCheckChange } }) => {
                      return (
                        <Checkbox
                          checked={checkedMethods.includes(item.id)}
                          onChange={() => onCheckChange(handleSelect(item.id))}
                        />
                      );
                    }}
                    control={control}
                  />
                }
                label={item.text}
              />
            ))}
          </FormGroup>
        </div>
        <FixedBottomButton onClick={setOption}>決定</FixedBottomButton>
        <SwipeableBottomBar defaultOpen={true} />
      </Stack>
    </>
  );
};

export default RouteOptions;
