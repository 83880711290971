import React, { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import axios, { AxiosResponse } from "axios";
import { format } from "date-fns-tz";

import { InteractionRequiredAuthError } from "@azure/msal-common";
import { Box, Paper, Divider, List, ListItem, ListItemText, styled, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import TopBar from "./TopBar";
import BottomBar, { drawerHeight } from "./components/SwipeableBottomBar";

import { Reservation, SearchNoriaigo } from "./types/noriaigo";
// import { getGraphToken, getAdb2cUser } from "./utils/loginForNoriaigo";
import { ReservationsAtom, bottomBarAtom } from "./state/atoms";
import { getGraphToken, getAdb2cUser } from "./utils/loginForNoriaigo";

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

const APIM_ENDPOINT = process.env.REACT_APP_APIM_ENDPOINT!;

const ReservationListForNoriaigo = () => {
  const { instance, accounts } = useMsal();
  const [reservations, setReservations] = useRecoilState(ReservationsAtom);
  const open = useRecoilValue(bottomBarAtom);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const getReservations = async () => {
    const scopes: string[] = (process.env.REACT_APP_ADB2C_SCOPES ?? "").split(",");
    const accessTokenRequest = {
      scopes: scopes,
      account: accounts[0],
    };
    let authorization = "";
    await instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        authorization = `Bearer ${accessTokenResponse.accessToken}`;
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenPopup(accessTokenRequest).then((accessTokenResponse) => {
            authorization = `Bearer ${accessTokenResponse.accessToken}`;
          });
        }
      });
    try {
      const platformResponse: AxiosResponse = await axios.get(`${APIM_ENDPOINT}/noriaigo`, {
        timeout: 100000,
        headers: {
          Authorization: authorization,
        },
      });
      let platformData: any[] = [];
      if (platformResponse.status === 200) {
        platformData = platformResponse.data;
      }
      const local_id = accounts[0].localAccountId;
      const token = await getGraphToken(authorization).then(res => {
        return res.data.access_token;
      });
      const userInfo = await getAdb2cUser(local_id, token).then(res => {
        return res.data;
      });
      const key_arr = Object.keys(userInfo);
      const login_id_key = key_arr.find(element => element.includes("login_id"));
      const login_id = login_id_key && userInfo[login_id_key];

      const reservationResponse: AxiosResponse = await axios.get(`${APIM_ENDPOINT}/noriaigo/reservations`, {
        timeout: 100000,
        headers: {
          Authorization: authorization,
        },
        params: {
          login_id: APIM_ENDPOINT === "https://cirx-kaga-dev.azure-api.net" ? "09043510082" : login_id,
        },
      });
      if (reservationResponse.status === 200) {
        reservationResponse.data.forEach((element: any) => {
          element.departure_platform_name = platformData
            ? platformData[
                platformData.findIndex((location: SearchNoriaigo) => {
                  return location.id === element.departure_platform_id;
                })
              ].name
            : undefined;
        });
        reservationResponse.data.forEach((element: any) => {
          element.arrival_platform_name = platformData
            ? platformData[
                platformData.findIndex((location: SearchNoriaigo) => {
                  return location.id === element.arrival_platform_id;
                })
              ].name
            : undefined;
        });
      }
      return reservationResponse;
    } catch (e) {
      if (axios.isAxiosError(e) && e.response) {
        return e.response;
      }
    }
  };

  useEffect(() => {
    getReservations().then((res) => {
      if (res) {
        let futureReservation: Reservation[] = [];
        res.data.forEach((element: any) => {
          const now = new Date();
          const jstDateNow = new Date(format(now, "yyyy-MM-dd'T'HH:mm:00+0900"));
          if (new Date(element.departure_time) > jstDateNow) {
            futureReservation.push(element);
          }
        })
        setReservations(futureReservation);
      }
      setIsLoading(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ReservationListComponent = () => {
    return (
      <>
        {reservations &&
          (reservations.length > 0
            ? reservations.map((content, index) => (
                <React.Fragment key={index}>
                  <ListItem
                    button={true}
                    onClick={() => {
                      navigate(`/noriaigo/reservation/${content.id}`);
                    }}
                  >
                    <Paper variant="outlined" sx={{ width: "100%", textAlign: "center", borderColor: "#401708" }}>
                      <Box m={1}>
                        <Typography variant="h6" sx={{ fontWeight: "bold", color: "#401708" }}>
                          {content.departure_platform_name}
                        </Typography>
                        <Typography sx={{ color: "#401708" }}>{"↓"}</Typography>
                        <Typography variant="h6" sx={{ fontWeight: "bold", color: "#401708" }}>
                          {content.arrival_platform_name}
                        </Typography>
                      </Box>
                      <Divider variant="middle" sx={{ borderStyle: "dashed", borderColor: "#F2A762" }} />
                      <Box mt={1} mb={1} ml={2} mr={2}>
                        <Typography sx={{ textAlign: "left", color: "#401708" }}>
                          {`運行日：${new Date(content.departure_time).getFullYear()}年
                          ${new Date(content.departure_time).getMonth() + 1}月
                          ${new Date(content.departure_time).getDate()}日
                          （${["日", "月", "火", "水", "木", "金", "土"][new Date(content.departure_time).getDay()]}）`}
                        </Typography>
                      </Box>
                      <Divider variant="middle" sx={{ borderStyle: "dashed", borderColor: "#F2A762" }} />
                      <Box mt={1} mb={1} ml={2} mr={2}>
                        <Typography sx={{ textAlign: "left", color: "#401708" }}>
                          {`予定時刻（予約時点）：${new Date(content.departure_time)
                            .getHours()
                            .toString()
                            .padStart(2, "0")}
                          :${new Date(content.departure_time).getMinutes().toString().padStart(2, "0")}
                           → ${new Date(content.arrival_time).getHours().toString().padStart(2, "0")}:
                           ${new Date(content.arrival_time).getMinutes().toString().padStart(2, "0")}`}
                        </Typography>
                      </Box>
                      <Divider variant="middle" sx={{ borderStyle: "dashed", borderColor: "#F2A762" }} />
                      <Box mt={1} mb={1} ml={2} mr={2}>
                        <Typography sx={{ textAlign: "left", color: "#401708" }}>
                          {`乗客数：${content.passenger_count}人`}
                        </Typography>
                      </Box>
                    </Paper>
                  </ListItem>
                </React.Fragment>
              ))
            : <Typography variant="h6" sx={{ color: "#401708" }}>配車予定の予約は見つかりませんでした</Typography>
          )}
      </>
    );
  };

  return (
    <>
      <Box sx={{ overflow: "scroll", height: `${window.innerHeight}px` }}>
        <TopBar title={"デマンド予約"} hasMargin={false} showReturnIcon={true} opacity="1" />
        <List sx={{ top: "72px" }}>
          <ListItem>
            <StyledListItemText
              primary={"予約一覧"}
              primaryTypographyProps={{ fontWeight: "bold", fontSize: "18px" }}
            />
          </ListItem>
          {isLoading ? <ReservationListComponent /> : <CircularProgress />}
        </List>
        { 
          open &&
          <Box sx={{height: `${drawerHeight}px`}}></Box>
        }
        <BottomBar defaultOpen={true}/>
      </Box>
    </>
  );
};

export default ReservationListForNoriaigo;
