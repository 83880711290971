import React from "react";

import "./LocationSearchInput.css";

type LocationSearchInputProps = {
  value: string;
  onChangeText: any;
};

const LocationSearchInput = ({ value, onChangeText }: LocationSearchInputProps) => {
  React.useEffect(() => {
    let input = document.querySelector("input");
    input?.addEventListener("input", onChangeText);
    return input?.removeEventListener("input", onChangeText);
  }, [onChangeText]);

  return (
    <div className="search-container">
      <input type="text" value={value} onChange={onChangeText} placeholder="バス停名または施設名で検索してください" />
    </div>
  );
};

export default LocationSearchInput;
