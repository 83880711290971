import { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import axios, { AxiosResponse } from "axios";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-common";

import { Box, Button, List, ListItem, ListItemText, Divider, styled, Typography } from "@mui/material";

import TopBar from "./TopBar";
import BottomBar, { drawerHeight } from "./components/SwipeableBottomBar";
import ErrorDialog from "./components/ErrorDialog";

import { noriaigoOriginAtom, noriaigoDestinationAtom, ReservationCandidateAtom, bottomBarAtom } from "./state/atoms";
import { ReservationCandidate } from "./types/noriaigo";
import { categorizeVehicle, categorizeImg } from "./utils/categorizedVehicle";

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: "bold",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
}));

const FixedBottomButton = styled(StyledButton)({
  borderRadius: "30px",
  width: "70%",
});

const ReturnedBottomButton = styled(StyledButton)({
  borderRadius: "30px",
  minWidth: "50px",
  backgroundColor: "#FADFCC",
  "&:hover": {
    backgroundColor: "#FADFCC",
  },
});

const APIM_ENDPOINT = process.env.REACT_APP_APIM_ENDPOINT!;

const ReservationConfirmationForNoriaigo = () => {
  const { instance, accounts } = useMsal();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const noriaigoOrigin = useRecoilValue(noriaigoOriginAtom);
  const noriaigoDestination = useRecoilValue(noriaigoDestinationAtom);
  const noriaigoCandidate = useRecoilValue(ReservationCandidateAtom);
  const open = useRecoilValue(bottomBarAtom);
  const [selectedNoriaigoCandidate, setSelectedNoriaigoCandidate] = useState<ReservationCandidate | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isErrorDialog, setIsErrorDialog] = useState<boolean>(false);
  const number: Number = location.state.number;
  const date: Date = location.state.date;
  const dayOfWeekStr = ["日", "月", "火", "水", "木", "金", "土"][date.getDay()];

  const createReservation = async () => {
    const scopes: string[] = (process.env.REACT_APP_ADB2C_SCOPES ?? "").split(",");
    const accessTokenRequest = {
      scopes: scopes,
      account: accounts[0],
    };
    let authorization = "";
    await instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        authorization = `Bearer ${accessTokenResponse.accessToken}`;
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenPopup(accessTokenRequest).then((accessTokenResponse) => {
            authorization = `Bearer ${accessTokenResponse.accessToken}`;
          });
        }
      });
    try {
      const response: AxiosResponse = await axios.post(
        `${APIM_ENDPOINT}/noriaigo/reservations`,
        {
          job_id: selectedNoriaigoCandidate?.job_id,
          reservation_candidate_id: selectedNoriaigoCandidate?.id,
        },
        {
          timeout: 100000,
          headers: {
            Authorization: authorization,
          },
        }
      );
      return response;
    } catch (e) {
      if (axios.isAxiosError(e) && e.response) {
        return e.response;
      }
    }
  };

  const validateResponse = (res: any) => {
    let validationResult = true;
    if (res === undefined) validationResult = false;
    if (res.status === 401) {
      // 401 Unauthorized
      validationResult = false;
      setIsLoading(false);
    } else if (res.status === 404) {
      // 404 Not Found 指定したjob_idかreservation_candidate_idが見つからなかった場合
      validationResult = false;
      setIsLoading(false);
    } else if (res.status === 409) {
      // 409 Conflict 競合するほかの予約が入った場合
      validationResult = false;
      setIsLoading(false);
    } else if (res.status === 422) {
      // 422 Unprocessable Entity
      validationResult = false;
      setIsLoading(false);
    } else if (res.status === 201) {
      validationResult = true;
    } else {
      validationResult = false;
      setIsLoading(false);
    }
    return validationResult;
  };

  useEffect(() => {
    if (!noriaigoCandidate) {
      setIsErrorDialog(true);
    }
    noriaigoCandidate?.forEach((selectedReservation) => {
      if (Number(params.id) === selectedReservation.id) {
        setSelectedNoriaigoCandidate(selectedReservation);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ overflow: "scroll", height: `${window.innerHeight}px` }}>
      <TopBar title={"デマンド予約"} hasMargin={false} showReturnIcon={true} opacity="1" />
      <List sx={{ top: "72px" }}>
        <ListItem>
          <StyledListItemText primary={"内容確認"} primaryTypographyProps={{ fontWeight: "bold", fontSize: "18px" }} />
        </ListItem>
        <ListItem sx={{ paddingBottom: 0 }}>
          <StyledListItemText
            primary={"予約候補番号"}
            primaryTypographyProps={{ fontWeight: "bold", fontSize: "16px" }}
          />
        </ListItem>
        <Divider sx={{ borderStyle: "dashed", borderColor: "#F2A762" }} />
        <ListItem>
          <ListItemText primary={params.id} />
        </ListItem>
        <ListItem sx={{ paddingBottom: 0 }}>
          <StyledListItemText primary={"運行日"} primaryTypographyProps={{ fontWeight: "bold", fontSize: "16px" }} />
        </ListItem>
        <Divider sx={{ borderStyle: "dashed", borderColor: "#F2A762" }} />
        <ListItem>
          <ListItemText
            primary={`${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日（${dayOfWeekStr}）`}
          />
        </ListItem>
        <ListItem sx={{ paddingBottom: 0 }}>
          <StyledListItemText primary={"乗客数"} primaryTypographyProps={{ fontWeight: "bold", fontSize: "16px" }} />
        </ListItem>
        <Divider sx={{ borderStyle: "dashed", borderColor: "#F2A762" }} />
        <ListItem>
          <ListItemText primary={`${number}人`} />
        </ListItem>
        <ListItem sx={{ paddingBottom: 0 }}>
          <StyledListItemText primary={"経路"} primaryTypographyProps={{ fontWeight: "bold", fontSize: "16px" }} />
        </ListItem>
        <Divider sx={{ borderStyle: "dashed", borderColor: "#F2A762" }} />
        <ListItem>
          <ListItemText
            primary={`${noriaigoOrigin && noriaigoOrigin.name}→${noriaigoDestination && noriaigoDestination.name}`}
          />
        </ListItem>
        <ListItem sx={{ paddingBottom: 0 }}>
          <StyledListItemText primary={"予約時刻"} primaryTypographyProps={{ fontWeight: "bold", fontSize: "16px" }} />
        </ListItem>
        <Divider sx={{ borderStyle: "dashed", borderColor: "#F2A762" }} />
        <ListItem>
          <ListItemText
            primary={`現時点予定 ${
              selectedNoriaigoCandidate &&
              new Date(selectedNoriaigoCandidate.departure_time).getHours().toString().padStart(2, "0")
            }:${
              selectedNoriaigoCandidate &&
              new Date(selectedNoriaigoCandidate.departure_time).getMinutes().toString().padStart(2, "0")
            } → ${
              selectedNoriaigoCandidate &&
              new Date(selectedNoriaigoCandidate.arrival_time).getHours().toString().padStart(2, "0")
            }:${
              selectedNoriaigoCandidate &&
              new Date(selectedNoriaigoCandidate.arrival_time).getMinutes().toString().padStart(2, "0")
            }`}
          />
        </ListItem>
        <ListItem>
          <ListItemText primary={"※予約時刻は、前後の予約状況に応じて変更される可能性があります"} />
        </ListItem>
        <ListItem sx={{ paddingBottom: 0 }}>
          <StyledListItemText primary={"号車"} primaryTypographyProps={{ fontWeight: "bold", fontSize: "16px" }} />
        </ListItem>
        <Divider sx={{ borderStyle: "dashed", borderColor: "#F2A762" }} />
        <ListItem sx={{ justifyContent: "center" }}>
          {selectedNoriaigoCandidate && categorizeImg(categorizeVehicle(selectedNoriaigoCandidate.vehicle_id))}
        </ListItem>
      </List>
      <Box mt={10}>
        {isLoading ? (
          <FixedBottomButton disabled>
            <Typography variant="h6" fontWeight="bold">
              予約中
            </Typography>
          </FixedBottomButton>
        ) : (
          <FixedBottomButton
            onClick={() => {
              setIsLoading(true);
              createReservation().then((res) => {
                const result = validateResponse(res);
                if (res !== undefined && result) {
                  navigate(`/noriaigo/reservation/completion/${res.data.id}`, {
                    state: { number: number, date: date, candidateId: params.id },
                  });
                }
              });
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              この内容で予約を確定する
            </Typography>
          </FixedBottomButton>
        )}
      </Box>
      <Box mt={5} mb={5}>
        {isLoading ? (
          <ReturnedBottomButton disabled>
            <Typography variant="h6" fontWeight="bold">
              予約候補に戻る
            </Typography>
          </ReturnedBottomButton>
        ) : (
          <ReturnedBottomButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              予約候補に戻る
            </Typography>
          </ReturnedBottomButton>
        )}
      </Box>
      { 
        open &&
        <Box sx={{height: `${drawerHeight}px`}}></Box>
      }
      <BottomBar defaultOpen={true}/>
      {isErrorDialog ? (
        <ErrorDialog
          primaryText={"予期せぬエラーが発生しました。再度検索してください。"}
          handleClick={() => {
            navigate("/dispach-noriaigo");
          }}
        />
      ) : null}
    </Box>
  );
};

export default ReservationConfirmationForNoriaigo;
