export type PinType = {
  title: string;
  latitude: number;
  longitude: number;
  iconType?: string;
};

export type PositionType = { latitude: number; longitude: number };

export type ErrorType = { isError: boolean; title: string; message: string };
export const initialLError = { isError: false, title: "", message: "" };
