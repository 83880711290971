import React from "react";
import { GeolocateControl } from "react-map-gl";

type GeolocateControlStyle = {
  right: number;
  top: number;
};

type LocationControlType = {
  style: GeolocateControlStyle;
  onGeolocate?: Function;
};

function LocationControl(props: LocationControlType) {
  const { style, onGeolocate } = props;
  return (
    <GeolocateControl
      style={style}
      positionOptions={{ enableHighAccuracy: true }}
      trackUserLocation={true}
      auto
      onGeolocate={onGeolocate}
    />
  );
}

export default LocationControl;
