import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { utcToZonedTime, format } from "date-fns-tz";

import TopBar from "./TopBar";
import DialogCard from "./elements/DialogCard";
import TutorialDialog from "./components/TutorialDialog";
import SwipeableBottomBar from "./components/SwipeableBottomBar";
import { SearchLocation } from "./types/search";
import { queryState, routesState } from "./store";

import tutorial from "./img/routes-tutorial.png";

import "./RouteSearch.css";

const RouteSearch = () => {
  let navigate = useNavigate();

  const [query, setQuery] = useRecoilState(queryState);
  const setRoutes = useSetRecoilState(routesState);

  const [isLocations, setIsLocations] = useState(false);
  const [isTutorial, setIsTutorial] = useState<boolean>(true);

  useEffect(() => {
    if (
      query.startLocation.type === 1 &&
      query.destinationLocation.type === 1 &&
      query.startLocation.title &&
      query.destinationLocation.title
    ) {
      setIsLocations(true);
    } else if (
      (query.startLocation.type === 2 || query.destinationLocation.type === 2) &&
      (query.startLocation.title || query.destinationLocation.title)
    ) {
      setIsLocations(true);
    } else {
      setIsLocations(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  function swapLocations(e: any) {
    e.preventDefault();
    let startLocation = query.startLocation as SearchLocation;
    let destinationLocation = query.destinationLocation as SearchLocation;
    setQuery({
      ...query,
      startLocation: destinationLocation,
      destinationLocation: startLocation,
    });
  }

  function changeStartLocation(e: any) {
    e.preventDefault();
    navigate("/search/location/start");
  }

  function changeDestinationLocation(e: any) {
    e.preventDefault();
    navigate("/search/location/destination");
  }

  function showOptions(e: any) {
    e.preventDefault();
    navigate("/search/options");
  }

  function submitForm(e: any) {
    e.preventDefault();
    setRoutes([]);
    navigate("/search/results");
  }

  function formatTime(isoDate: string, displayFormat: string) {
    // convert given date string to Date => 2022-01-19T04:44:00+09:00
    const event = new Date(isoDate);
    // convert the time zone of the date to JST (force this timezone)
    const localizedDate = utcToZonedTime(event, "JST");
    // return formatted time in that time zone
    return format(localizedDate, displayFormat, { timeZone: "JST" });
  }

  function getTimeValue(timeQuery: string | undefined, type: string) {
    if (timeQuery) {
      // timeQueryが存在（時間が設定されている）
      let formatedTime: string;
      if (type === "firstTrain") {
        formatedTime = formatTime(timeQuery, "Y年M月d日");
        return `${formatedTime} 始発`;
      } else if (type === "lastTrain") {
        formatedTime = formatTime(timeQuery, "Y年M月d日");
        return `${formatedTime} 終電`;
      } else if (type === "departure") {
        formatedTime = formatTime(timeQuery, "Y年M月d日 HH時mm分");
        return `${formatedTime} 出発`;
      } else if (type === "arrival") {
        formatedTime = formatTime(timeQuery, "Y年M月d日 HH時mm分");
        return `${formatedTime} 到着`;
      }
    } else {
      return "今すぐ";
    }
  }

  return (
    <>
      <TopBar title="移動経路検索" />
      <div className="searchForm">
        <label>
          <div className="label">出発地</div>
          <input
            type="text"
            name="startLocation"
            placeholder="出発地を選択"
            value={query.startLocation.title}
            readOnly
            onFocus={changeStartLocation}
          />
        </label>

        <label>
          <button className="swapButton" onClick={swapLocations}>
            入れ替え
          </button>
        </label>

        <label>
          <div className="label">目的地</div>
          <input
            type="text"
            name="destinationLocation"
            placeholder="目的地を選択"
            value={query.destinationLocation.title}
            readOnly
            onFocus={changeDestinationLocation}
          />
        </label>

        <label>
          <div className="label">日時</div>
          <input
            type="text"
            name="time"
            value={getTimeValue(query.time, query.searchType)}
            readOnly
            onFocus={showOptions}
          />
        </label>

        <label>
          <button className="optionsButton" onClick={showOptions}>
            経路検索オプション
          </button>
        </label>
        {isLocations ? (
          <button className="searchButton" onClick={submitForm}>
            経路を検索する
          </button>
        ) : (
          <button className="searchButton" onClick={submitForm} disabled={true}>
            経路を検索する
          </button>
        )}
        <div className="warningMessage">※経路検索は加賀市内のバスが対象となります。</div>
      </div>
      <SwipeableBottomBar defaultOpen={true} />
      {!localStorage.getItem("KAGA-ROUTES-TUTORIAL") && isTutorial && (
        <TutorialDialog
          isChecked={false}
          title={"出発地と目的地、いつ出かけるかを入力すると、最適なお出かけルートがおすすめされます！"}
          description={"経路検索オプションで、より目的のお出かけルートを調べることもできます。試してみてくださいね！"}
          buttonText={"経路を検索する"}
          card={<DialogCard image={tutorial} />}
          handleClick={(isAgree) => {
            if (isAgree) {
              localStorage.setItem("KAGA-ROUTES-TUTORIAL", "agree");
            }
            setIsTutorial(false);
          }}
        />
      )}
    </>
  );
};

export default RouteSearch;
