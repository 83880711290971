import { useNavigate } from "react-router-dom";

import TopBar from "./TopBar";
import BottomBar from "./components/SwipeableBottomBar";
import { styled, Box, Typography } from "@mui/material";

import IconButton from "./elements/IconButton";
import searchIcon from "./img/search.svg";
import calendarIcon from "./img/calendar.svg";

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: "bold",
}));

const DispatchNoriaigo = () => {
  const navigate = useNavigate();
  function handleClickSearch() {
    navigate("/noriaigo/search");
  }

  function handleClickReservation() {
    navigate("/noriaigo/reservation");
  }

  return (
    <main>
      <TopBar title="デマンド予約" showReturnIcon={true} hasMargin={true} />
      <Box m={4}>
        <StyledTypography variant="h5">予約メニュー</StyledTypography>
      </Box>
      <Box my={4} mx={3}>
        <IconButton
          textElement={<StyledTypography>のりあい号の配車予約</StyledTypography>}
          iconElement={<img alt="searchIcon" src={searchIcon} />}
          onClick={() => {
            handleClickSearch();
          }}
          justifyContent="left"
          height="4rem"
        />
      </Box>
      <Box my={4} mx={3}>
        <IconButton
          textElement={<StyledTypography>予約の確認・取消</StyledTypography>}
          iconElement={<img alt="calendarIcon" src={calendarIcon} />}
          onClick={() => {
            handleClickReservation();
          }}
          justifyContent="left"
          height="4rem"
        />
      </Box>
      <BottomBar />
    </main>
  );
};

export default DispatchNoriaigo;
