import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { AppBar, Toolbar, Typography, IconButton, styled } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import TopBarTextField from "./components/TopBarTextField";
import DrawerMenu from "./components/DrawerMenu";
import { headerMarginTop, topBarHeight } from "./utils/constants";

interface TopBarProps {
  title?: string;
  hasMargin?: boolean;
  showReturnIcon?: boolean;
  opacity?: string;
}

interface AppBarProps {
  opacity: string;
}

const StyleAppBar = styled(AppBar)<AppBarProps>(({ theme, opacity }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.grey.A100,
  opacity: opacity,
  zIndex: theme.zIndex.drawer + 1,
}));

function TopBar(props: TopBarProps) {
  const { title, showReturnIcon, hasMargin, opacity } = props;
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const handleReturn = () => {
    navigate(-1);
  };

  const handleDrawerToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleDrawerClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <StyleAppBar
        position={hasMargin === false ? "fixed" : "relative"}
        sx={{ mb: 1 }}
        opacity={opacity ? opacity : "0.8"}
      >
        <Toolbar
          sx={{
            minHeight: topBarHeight + "px",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={showReturnIcon ? handleReturn : handleDrawerToggle}
            edge="start"
            sx={title ? { position: "absolute" } : {}}
          >
            {showReturnIcon ? (
              <>
                <ArrowBackIosIcon sx={{ color: "secondary.main" }} />
                <Typography
                  variant="button"
                  sx={{
                    fontWeight: "bold",
                    color: "secondary.main",
                  }}
                >
                  戻る
                </Typography>
              </>
            ) : (
              <MenuIcon sx={{ color: "secondary.main" }} />
            )}
          </IconButton>
          {title ? (
            <Typography
              variant="subtitle1"
              noWrap
              sx={{
                flexGrow: 1,
                fontWeight: "bold",
                color: "secondary.light",
                alignItems: "center",
              }}
            >
              {title}
            </Typography>
          ) : (
            <TopBarTextField />
          )}
        </Toolbar>
      </StyleAppBar>
      <DrawerMenu
        isOpen={isOpen}
        handleClose={handleDrawerClose}
        marginTop={hasMargin === false ? headerMarginTop : topBarHeight}
      />
    </>
  );
}

export default TopBar;
