import axios from "axios";

type IdentyType = {
  identities: SignInType[]
}

type SignInType = {
  signInType: string,
  issuer: string,
  issuerAssignedId: string,
}

export const getGraphToken = async (authorization: string) => {
  const APIM_ENDPOINT = process.env.REACT_APP_APIM_ENDPOINT!;

  const TENANT_ID = process.env.REACT_APP_GRAPH_TENANT_ID;
  const CLIENT_ID = process.env.REACT_APP_ADB2C_CLIENT_ID;
  const CLIENT_SECRET = process.env.REACT_APP_GRAPH_CLIENT_SECRET;
  const params = new URLSearchParams();

  params.append("grant_type", "client_credentials");
  CLIENT_ID && params.append("client_id", CLIENT_ID);
  CLIENT_SECRET && params.append("client_secret", CLIENT_SECRET);
  params.append("resource", "https://graph.microsoft.com");

  return await axios.post(`${APIM_ENDPOINT}/noriaigo/ad/${TENANT_ID}`, params, {
    headers: { Authorization: authorization },
  });
};

export const getAdb2cUser = async (local_id: string, access_token: string) => {
  return await axios.get(`https://graph.microsoft.com/beta/users/${local_id}`, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
};

export const updateMailAddress = async (
  localId: string,
  params: IdentyType,
  accessToken: string
) => {
  try {
    const res = await axios.patch(`https://graph.microsoft.com/beta/users/${localId}`, params, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res;
  }catch(e) {
    if (axios.isAxiosError(e) && e.response) {
      return e.response;
    }
  }
}

export const deleteUser = async (
  localId: string,
  accessToken: string
) => {
  try {
    const res = await axios.delete(`https://graph.microsoft.com/beta/users/${localId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    });
    return res;
  }catch(e) {
    if (axios.isAxiosError(e) && e.response) {
      return e.response;
    }
  }
}