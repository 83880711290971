import React from "react";
import { isIOS, isSafari } from "react-device-detect";

import TopBar from "./TopBar";
import BottomBar from "./components/SwipeableBottomBar";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Paper,
  styled,
  Typography,
} from "@mui/material";

import taxiIcon from "./img/taxi.svg";
import phoneIcon from "./img/phone.svg";

const DATA = [
  { companyName: "石川交通（株）", phoneNumber: "0761-22-2811" },
  { companyName: "石川相互タクシー", phoneNumber: "0761-74-0539" },
  { companyName: "加賀第一交通（株）", phoneNumber: "0761-76-0111" },
  { companyName: "大交日の出タクシー（株）", phoneNumber: "0761-76-0033" },
];

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: "bold",
}));

const StyledDiv = styled("div")(({ theme }) => ({
  background: theme.palette.primary.main,
  border: `1px solid ${theme.palette.primary.contrastText}`,
  borderRadius: "10px",
  height: "3rem",
  width: "3rem",
}));

const StyledTaxiIcon = styled("img")({
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "2.5rem",
  width: "2.5rem",
});

function handleClickPhone(phoneNumber: string) {
  window.location.href = `tel:${phoneNumber}`;
}

function DispachTaxi() {
  function Caption() {
    if (isIOS && isSafari) {
      return (
        <Paper sx={{ mt: 1, p: 1 }}>
          <Typography variant="caption">
            電話番号アイコンを3回以上タップしますと、ポップアップが表示されます。通話する場合は、「通話を許可」をタップして下さい。
          </Typography>
        </Paper>
      );
    } else {
      return <></>;
    }
  }

  return (
    <main>
      <TopBar title="タクシー配車" hasMargin={true} />
      <Box m={1}>
        <List>
          {DATA.map((company, index) => (
            <React.Fragment key={index}>
              <ListItem
                secondaryAction={
                  <IconButton edge="end" aria-label="phone" onClick={() => handleClickPhone(company.phoneNumber)}>
                    <img alt="phoneIcon" src={phoneIcon} />
                  </IconButton>
                }
              >
                <ListItemIcon>
                  <StyledDiv>
                    <StyledTaxiIcon alt="taxiIcon" src={taxiIcon} />
                  </StyledDiv>
                </ListItemIcon>
                <StyledListItemText primary={company.companyName} />
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
        <Caption />
      </Box>
      <BottomBar defaultOpen={true} />
    </main>
  );
}

export default DispachTaxi;
