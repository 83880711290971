import DirectionsCarFilledIcon from "@mui/icons-material/DirectionsCarFilled";
import DirectionsBusFilledIcon from "@mui/icons-material/DirectionsBusFilled";
import FlightIcon from "@mui/icons-material/Flight";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import DirectionsBoatFilledIcon from "@mui/icons-material/DirectionsBoatFilled";
import LocalTaxiIcon from "@mui/icons-material/LocalTaxi";
import DirectionsRailwayFilledIcon from "@mui/icons-material/DirectionsRailwayFilled";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";

export default function getIconForType(type: string, name: string) {
  switch (type) {
    case "car":
      return <DirectionsCarFilledIcon />;
    case "bus":
      return <DirectionsBusFilledIcon />;
    case "plane":
      return <FlightIcon />;
    case "cycle":
      return <DirectionsBikeIcon />;
    case "sharecycle":
      return <DirectionsBikeIcon />;
    case "ship":
      return <DirectionsBoatFilledIcon />;
    case "taxi":
      return <LocalTaxiIcon />;
    case "train":
      return <DirectionsRailwayFilledIcon />;
    case "walk":
      return <DirectionsWalkIcon />;
    default:
      return name;
  }
}
