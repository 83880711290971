import React, { ReactElement } from "react";

import { Box, Button, styled } from "@mui/material";

const StyledButton = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.main,
  border: "1px solid #f2a762",
  borderRadius: "10px",
  "&:hover": {
    background: theme.palette.primary.main,
  },
}));

interface IconButtonProps {
  textElement: ReactElement<any, any>;
  iconElement: ReactElement<any, any>;
  justifyContent: "left" | "right";
  height: string;
  onClick: () => void;
}

function IconButton(props: IconButtonProps) {
  const { textElement, iconElement, justifyContent, height, onClick } = props;
  return (
    <StyledButton
      variant="outlined"
      onClick={onClick}
      fullWidth
      sx={{ justifyContent: justifyContent, height: height }}
    >
      {justifyContent === "left" && iconElement}
      <Box sx={{ width: "100%" }}>{textElement}</Box>
      {justifyContent === "right" && iconElement}
    </StyledButton>
  );
}

export default IconButton;
