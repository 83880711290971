export const getLocalStorageItems = (key: string): string[] => {
  const items = localStorage.getItem(key);
  if (items) {
    return JSON.parse(items);
  } else {
    return [];
  }
};

export const appendLocalStorageItem = (key: string, value: string) => {
  let newValues: string[] = [];
  const currentValues = getLocalStorageItems(key);
  if (Array.isArray(currentValues)) {
    currentValues.forEach((currentValue) => {
      if (currentValue !== value) {
        newValues.push(currentValue);
      }
    });
    newValues.push(value);
    if (newValues.length >= 5) {
      newValues = newValues.slice(newValues.length - 5, newValues.length);
    }
  }
  localStorage.setItem(key, JSON.stringify(newValues));
};