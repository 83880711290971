import React, { useState, useEffect, useRef } from "react";
import { useRecoilState } from "recoil";

import { FormControl, IconButton, InputAdornment, OutlinedInput, styled } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import { topBarHeight } from "../utils/constants";
import { getLocalStorageItems, appendLocalStorageItem } from "../utils/localStorage";
import PoiSearchHistory from "./PoiSearchHistory";
import { poiSearchTextAtom } from "../state/atoms";

const StyledOutlineInput = styled(OutlinedInput)(({ theme }) => ({
  height: topBarHeight - 16,
  fontSize: "0.9rem",
  "& fieldset": {
    borderColor: theme.palette.primary.contrastText,
  },
  "&:hover fieldset": {
    borderColor: `${theme.palette.primary.contrastText} !important`,
  },
  "&.Mui-focused fieldset": {
    borderColor: `${theme.palette.primary.contrastText} !important`,
  },
}));

function TopBarTextField() {
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState("");
  const [poiSearchValue, setPoiSearchValue] = useRecoilState(poiSearchTextAtom);
  const [poiSearchHistories, setPoiSearchHistories] = useState<string[]>([]);

  const inputRef = useRef<HTMLDivElement>(null);

  const handleDrawerClose = () => {
    setVisible(false);
  };

  const updateSearchHistorie = () => {
    const currentHistories = getLocalStorageItems("KAGA-POI-SEARCH-HISTORY");
    setPoiSearchHistories(currentHistories.reverse());
  };

  useEffect(() => {
    updateSearchHistorie();
  }, []);

  useEffect(() => {
    setValue(poiSearchValue);
    updateSearchHistorie();
  }, [poiSearchValue]);

  return (
    <>
      <FormControl fullWidth variant="outlined">
        <StyledOutlineInput
          id="topbar-text-field"
          type="text"
          value={value}
          inputRef={inputRef}
          onClick={() => {
            setVisible(true);
          }}
          onChange={(event: any) => {
            setValue(event.target.value);
            setVisible(false);
          }}
          onKeyPress={(event: any) => {
            if (event.key === "Enter") {
              const newValue = event.target.value;
              if (newValue) {
                appendLocalStorageItem("KAGA-POI-SEARCH-HISTORY", newValue);
              }
              setVisible(false);
              setPoiSearchValue(newValue);
            }
          }}
          startAdornment={
            !visible &&
            !value && (
              <InputAdornment position="start" sx={{ padding: "0px" }}>
                <IconButton aria-label="search" edge="start">
                  <SearchIcon sx={{ color: "secondary.main" }} />
                </IconButton>
              </InputAdornment>
            )
          }
          endAdornment={
            value && (
              <InputAdornment position="end" sx={{ marginLeft: "-10px" }}>
                <IconButton
                  aria-label="clear"
                  onClick={() => {
                    setValue("");
                    setPoiSearchValue("");
                    if (inputRef.current != null) {
                      inputRef.current.focus();
                    }
                  }}
                  edge="end"
                >
                  <HighlightOffIcon fontSize="small" sx={{ color: "secondary.contrastText" }} />
                </IconButton>
              </InputAdornment>
            )
          }
          autoComplete="off"
          placeholder="施設名で検索してください"
          inputProps={{
            sx: {
              minWidth: "unset",
              width: "90%",
            },
          }}
        />
      </FormControl>
      {poiSearchHistories.length !== 0 && (
        <PoiSearchHistory
          isOpen={visible}
          handleClose={handleDrawerClose}
          marginTop={topBarHeight}
          searchHistories={poiSearchHistories}
        />
      )}
    </>
  );
}

export default TopBarTextField;
