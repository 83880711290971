import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
import { format } from "date-fns";

import { useRecoilState, useRecoilValue } from "recoil";

import {
  Box,
  Button,
  Divider,
  Dialog,
  DialogTitle,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  Typography,
  styled,
} from "@mui/material";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseIcon from "@mui/icons-material/Close";

import TopBar from "./TopBar";
import SwipeableBottomBar, { drawerHeight } from "./components/SwipeableBottomBar";
import PinLayer from "./components/PinLayer";
import Map from "./components/Map";
import MapNavigationControl from "./elements/MapNavigationControl";
import ImageNotFound from "./elements/ImageNotFound";
import phoneIcon from "./img/phone2.svg";
import { viewportAtom, bottomBarAtom } from "./state/atoms";
import { queryState } from "./store";
import { LocationType } from "./types/search";
import { topBarHeight } from "./utils/constants";

const CMS_ENDPOINT = process.env.REACT_APP_CMS_ENDPOINT || "https://kaga.cirx.dev/cms";

const ScrolableMain = styled("main")({
  overflow: "scroll",
  height: `${window.innerHeight - topBarHeight}px`,
});

const StyledDiv = styled("div")({
  position: "relative",
  bottom: 0,
  width: "100%",
});

const StyledListItem = styled(ListItem)({
  paddingTop: "4px",
});

const StyledPoiImage = styled("img")({
  width: "100%",
  height: "20vh",
  objectFit: "cover",
});

const StyledPhoneIcon = styled(ListItemIcon)({
  minWidth: "28px",
});

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  color: theme.palette.secondary.main,
  whiteSpace: "pre-wrap",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  height: "5vh",
  borderRadius: "30px",
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
}));

const FixedBottomButton = styled(StyledButton)({
  position: "absolute",
  bottom: 32,
  height: "6vh",
  width: "80%",
  left: "50%",
  transform: "translateX(-50%);",
});

const SecondaryTypography = styled(Typography)(({ theme }) => ({
  fontSize: "0.8em",
  color: theme.palette.secondary.main,
}));

function PoiDetails() {
  let { id } = useParams();
  let navigate = useNavigate();
  const [poi, setPoi] = useState<any>(null);
  const [viewport, setViewport] = useRecoilState(viewportAtom);
  const [isImageListDialogOpen, setIsImageListDialogOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [query, setQuery] = useRecoilState(queryState);
  const open = useRecoilValue(bottomBarAtom);

  useEffect(() => {
    const getPoi = async (id: string) => {
      const response: AxiosResponse = await axios.get(`${CMS_ENDPOINT}/events/${id}`, {
        timeout: 100000,
      });
      return response;
    };

    if (id) {
      getPoi(id).then((res) => {
        const newPoi = res.data;
        setPoi(newPoi);
        setViewport({
          ...viewport,
          latitude: newPoi.location.latitude,
          longitude: newPoi.location.longitude,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleClickRoute = () => {
    const newLocation = {
      title: poi.title,
      latitude: poi.location.latitude,
      longitude: poi.location.longitude,
      type: LocationType.Regular,
    };
    setQuery({
      ...query,
      destinationLocation: newLocation,
    });
    navigate("/search");
  };

  return (
    <>
      <ScrolableMain>
        <TopBar title="施設詳細情報" hasMargin={true} showReturnIcon={true} />
        {poi ? (
          <>
            <Box sx={{ backgroundColor: "white", mt: -1 }}>
              {poi.images.length > 0 && (
                <Box>
                  <StyledPoiImage src={poi.images[0].url} alt={poi.title} />
                </Box>
              )}
              <List sx={{ px: 1 }}>
                <StyledListItem>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "secondary.main",
                    }}
                  >
                    {poi.title}
                  </Typography>
                </StyledListItem>
                {poi.description && (
                  <>
                    <ListItem>
                      <StyledListItemText
                        primary={poi.description}
                        primaryTypographyProps={{ fontSize: "0.8rem" }}
                        secondaryTypographyProps={{ component: "div" }}
                      />
                    </ListItem>
                    <Divider />
                  </>
                )}
                {poi.important_information && (
                  <>
                    <ListItem>
                      <StyledListItemText
                        primary="開館時間・休館日"
                        primaryTypographyProps={{ fontWeight: "700" }}
                        secondary={
                          <React.Fragment>
                            <SecondaryTypography>{poi.important_information}</SecondaryTypography>
                          </React.Fragment>
                        }
                        secondaryTypographyProps={{ component: "div" }}
                      />
                    </ListItem>
                    <Divider />
                  </>
                )}
                {poi.admission && (
                  <>
                    <ListItem>
                      <StyledListItemText
                        primary="入館料"
                        primaryTypographyProps={{ fontWeight: "700" }}
                        secondary={
                          <React.Fragment>
                            <SecondaryTypography>{poi.admission}</SecondaryTypography>
                          </React.Fragment>
                        }
                        secondaryTypographyProps={{ component: "div" }}
                      />
                    </ListItem>
                    <Divider />
                  </>
                )}
                {poi.guidelines && (
                  <>
                    <ListItem>
                      <StyledListItemText
                        primary="その他事項"
                        primaryTypographyProps={{ fontWeight: "700" }}
                        secondary={
                          <React.Fragment>
                            <SecondaryTypography>{poi.guidelines}</SecondaryTypography>
                          </React.Fragment>
                        }
                        secondaryTypographyProps={{ component: "div" }}
                      />
                    </ListItem>
                    <Divider />
                  </>
                )}
                {poi.phone && (
                  <>
                    <ListItemButton
                      onClick={() => {
                        window.location.href = `tel:${poi.phone}`;
                      }}
                    >
                      <StyledPhoneIcon aria-label="phone">
                        <img alt="phoneIcon" src={phoneIcon} />
                      </StyledPhoneIcon>
                      <StyledListItemText
                        primary={poi.phone}
                        primaryTypographyProps={{ fontSize: "0.8rem" }}
                        secondaryTypographyProps={{ component: "div" }}
                      />
                    </ListItemButton>
                    <Divider />
                  </>
                )}
                {poi.url && (
                  <>
                    <ListItemButton
                      onClick={() => {
                        window.open(poi.url, "_blank");
                      }}
                    >
                      <StyledListItemText
                        primary={poi.url}
                        primaryTypographyProps={{ fontSize: "0.8rem" }}
                        secondaryTypographyProps={{ component: "div" }}
                      />
                    </ListItemButton>
                    <Divider />
                  </>
                )}
                <ListItem>
                  <StyledListItemText
                    primary={poi.location.zip_code && `〒${poi.location.zip_code}`}
                    secondary={poi.location.name}
                    primaryTypographyProps={{ fontSize: "0.8rem" }}
                    secondaryTypographyProps={{
                      fontSize: "0.8rem",
                      component: "div",
                    }}
                  />
                </ListItem>
                {poi.coupons.length > 0 && (
                  <ListItem style={{ justifyContent: "center" }}>
                    <StyledButton
                      onClick={() => {
                        setIsImageListDialogOpen(true);
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          color: "secondary.main",
                        }}
                        variant="body2"
                      >
                        クーポンを利用する
                      </Typography>
                    </StyledButton>
                  </ListItem>
                )}
              </List>
            </Box>
            <StyledDiv>
              { (!poi.location.latitude && !poi.location.longitude) ? 
                <Typography mt={10} mb={10} sx={{ color: "secondary.main" }}>位置情報が登録されていません</Typography> : 
              <>
                <Map height={poi.images.length > 0 ? 40 : 60}>
                  <MapNavigationControl style={{ right: 10, top: 10 }} />
                  <PinLayer
                    data={[
                      {
                        title: poi.title,
                        latitude: poi.location.latitude,
                        longitude: poi.location.longitude,
                      },
                    ]}
                  />
                </Map>
                <FixedBottomButton onClick={handleClickRoute}>
                  <Typography variant="h6" fontWeight="bold">
                    ルート詳細を確認する
                  </Typography>
                </FixedBottomButton>
              </>
            }
            </StyledDiv>
          </>
        ) : (
          <>{/* <Typography>データがありません</Typography> */}</>
        )}
        {
          open &&
          <Box sx={{height: `${drawerHeight}px`}}></Box>
        }
        <SwipeableBottomBar defaultOpen={true} />
      </ScrolableMain>
      <Dialog
        onClose={() => {
          setIsImageListDialogOpen(false);
        }}
        open={isImageListDialogOpen}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <IconButton
            aria-label="close"
            onClick={() => {
              setIsImageListDialogOpen(false);
            }}
            sx={{
              position: "absolute",
              right: 4,
              top: 4,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {poi !== null && poi.coupons && poi.coupons.length !== 0 ? (
          <ImageList>
            {poi.coupons.map((coupon: any, index: number) => (
              <ImageListItem
                key={index}
                cols={2}
                rows={poi.coupons.length}
                onClick={() => {
                  if (coupon.image) {
                    setImageUrl(coupon.image.url);
                    setIsImageListDialogOpen(false);
                  }
                }}
              >
                {coupon.image ? (
                  <img src={coupon.image.url} alt={coupon.image.name} loading="lazy" />
                ) : (
                  <ImageNotFound width={268} height={162} />
                )}
                <ImageListItemBar
                  title={coupon.title}
                  subtitle={`有効期間:${format(new Date(coupon.valid_from), "yyyy/MM/dd")}~${format(
                    new Date(coupon.valid_until),
                    "yyyy/MM/dd"
                  )}`}
                  actionIcon={
                    <IconButton
                      sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                      aria-label={`info about ${coupon.title}`}
                      onClick={() => {
                        if (coupon.image) {
                          setImageUrl(coupon.image.url);
                          setIsImageListDialogOpen(false);
                        }
                      }}
                      disabled={coupon.image == null}
                    >
                      <OpenInFullIcon fontSize="small" />
                    </IconButton>
                  }
                />
              </ImageListItem>
            ))}
          </ImageList>
        ) : (
          <Box m={1}>
            <Typography>データがありません</Typography>
          </Box>
        )}
      </Dialog>
      <Dialog
        onClose={() => {
          setImageUrl("");
        }}
        open={imageUrl !== ""}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <IconButton
            aria-label="close"
            onClick={() => {
              setImageUrl("");
              setIsImageListDialogOpen(true);
            }}
            sx={{
              position: "absolute",
              right: 4,
              top: 4,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Box
          component="img"
          mt={2}
          alt="coupon"
          src={imageUrl}
          onClick={() => {
            setImageUrl("");
            setIsImageListDialogOpen(true);
          }}
        />
      </Dialog>
    </>
  );
}

export default PoiDetails;
