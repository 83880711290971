import React, { ReactElement } from "react";

import { Box, Button, styled } from "@mui/material";

const StyledButton = styled(Button)(({ theme }) => ({
  background: "#eeeeee",
  color: "rgba(58, 29, 11, 0.6)",
  borderRadius: "10px",
  "&:hover": {
    background: "#eeeeee",
  },
}));

interface IconButtonProps {
  textElement: ReactElement<any, any>;
  iconElement: ReactElement<any, any>;
  justifyContent: "left" | "right";
  height: string;
}

function DisabledIconButton(props: IconButtonProps) {
  const { textElement, iconElement, justifyContent, height } = props;
  return (
    <StyledButton
      variant="outlined"
      fullWidth
      sx={{ justifyContent: justifyContent, height: height }}
      disabled
    >
      {justifyContent === "left" && iconElement}
      <Box sx={{ width: "100%" }}>{textElement}</Box>
      {justifyContent === "right" && iconElement}
    </StyledButton>
  );
}

export default DisabledIconButton;
