import { useNavigate } from "react-router-dom";
import { useRecoilValue,  } from "recoil";
import { bottomBarAtom } from "./state/atoms";

import { Box, Typography, Button, styled } from "@mui/material";

import TopBar from "./TopBar";
import BottomBar, { drawerHeight } from "./components/SwipeableBottomBar";

const StyledBottomButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.primary.main,
  borderRadius: "30px",
  minWidth: "50px",
  width: "70%",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
}));

const ReservationCancelForNoriaigo = () => {
  const navigate = useNavigate();
  const open = useRecoilValue(bottomBarAtom);

  return (
    <>
      <TopBar title={"デマンド予約"} hasMargin={false} showReturnIcon={false} />
      <Box sx={{ position: "fixed", top: "80px", width: "100%" }}>
        <Typography m={2} sx={{ color: "#3A1D0B" }}>
          {"予約がキャンセルされました。"}
          <br />
          {"再び予約する場合は、予約メニューから再度予約してください。"}
        </Typography>
      </Box>
      <Box sx={{ position: "fixed", bottom: "100px", width: "100%" }}>
        <StyledBottomButton
          onClick={() => {
            navigate("/dispach-noriaigo");
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            OK
          </Typography>
        </StyledBottomButton>
        { 
          open &&
          <Box sx={{height: `${drawerHeight}px`}}></Box>
        }
        <BottomBar defaultOpen={true}/>
      </Box>
    </>
  );
};

export default ReservationCancelForNoriaigo;
