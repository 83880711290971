import { utcToZonedTime, format } from "date-fns-tz";

const TICKET_HOST = process.env.REACT_APP_TICKET_HOST!;

export function formattedTimeFromISODate(isoDate: Date) {
  // convert given date string to Date => 2022-01-19T04:44:00+09:00
  const event = new Date(isoDate);
  // convert the time zone of the date to JST (force this timezone)
  const localizedDate = utcToZonedTime(event, "JST");
  // return formatted time in that time zone
  return format(localizedDate, "HH:mm", { timeZone: "JST" });
}

export const goToTicketService = (accessToken: string) => {
  const url = `${TICKET_HOST}/?jwt=Bearer%20${accessToken}`;

  let a = document.createElement("a");
  if (a.click) {
    // HTML5 browsers and IE support click() on <a>, early FF does not.
    a.setAttribute("href", url);
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
  } else {
    // Early FF can, however, use this usual method
    // where IE cannot with secure links.
    window.location.href = url;
  }
};
