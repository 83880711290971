import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  Typography,
} from "@mui/material";

interface Props {
  isOpen: boolean;
  message: string;
  buttonText: string;
  handleClick: () => void;
}

const ModificationMailDialog = (props: Props) => {
  const { isOpen, message, buttonText, handleClick } = props;

  return (
    <>
      <Dialog
        open={isOpen}
      >
        <DialogTitle sx={{ textAlign: "center", color: "#3A1D0B", fontWeight: "bold" }}>
          {message}
        </DialogTitle>
        <DialogActions sx={{ flexFlow: "column" }}>
          <Button
            onClick={() => handleClick()}
            sx={{
              height: "5vh",
              width: "70%", 
              borderRadius: "30px",
              color: "#3A1D0B",
              backgroundColor: "#E0A86E",
              "&:hover": {
                backgroundColor: "#E0A86E",
              }
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              {buttonText}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ModificationMailDialog;