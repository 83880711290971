import headerBackgroundImage from "./loginHeaderBackground.svg";
import HomeGuidanceiOS from "./components/HomeGuidanceiOS";
import HomeGuidanceAndroid from "./components/HomeGuidanceAndroid";
import MobileOS from "./utils/MobileOS";
import "./Guidance.css";

const Guidance = () => {
  const mobileOS = new MobileOS();
  return (
    <>
      <div className="header">
        <div className="background" style={{ backgroundImage: `url(${headerBackgroundImage}` }}>
          <div className="tagline">さあ、自由に移動しよう</div>
          <div className="heading">NoluDay</div>
        </div>
      </div>
      <div className="guidanceHeader">Noludayをホーム画面に置くには？</div>
      {
        mobileOS.get() === "iOS" ? <HomeGuidanceiOS /> : <HomeGuidanceAndroid />
      }
    </>
  )
};

export default Guidance;