import { useNavigate } from "react-router-dom";
import HomeGuidance from "../elements/HomeGuidance";
import BackButton from "../elements/BackButton";
import StyledButton from "../elements/StyledButton";
import safari from "../img/safari.png";
import guidanceiOSTop from "../img/guidanceiOSTop.png";
import guidanceiOSSelect from "../img/guidanceiOSSelect.png";
import guidanceiOSAdd from "../img/guidanceiOSAdd.png";
import guidanceiOSResult from "../img/guidanceiOSResult.png";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const HomeGuidanceiOS = () => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/");
  }

  return (
    <>
      <BackButton onClick={handleButtonClick}/>
      <HomeGuidance
        mainText="1:safariでNoludayを開きます"
        img={safari}
        sx={{width: "25%", height: "25%", marginLeft: "auto", marginRight: "auto"}}
      />
      <ArrowDropDownIcon sx={{ width: "20%", height: "20%"}} />
      <HomeGuidance
        mainText="2:中央の共有アイコンをタップします"
        img={guidanceiOSTop}
      />
      <ArrowDropDownIcon sx={{ width: "20%", height: "20%"}} />
      <HomeGuidance
        mainText="3:「ホーム画面に追加」をタップ"
        img={guidanceiOSSelect}
      />
      <ArrowDropDownIcon sx={{ width: "20%", height: "20%"}} />
      <HomeGuidance
        mainText="4:続いて右上の「追加」をタップ"
        img={guidanceiOSAdd}
      />
      <ArrowDropDownIcon sx={{ width: "20%", height: "20%"}} />
      <HomeGuidance
        mainText="5:アイコンが表示されたら成功です"
        img={guidanceiOSResult}
      />
      <StyledButton
        text="戻る"
        onClick={handleButtonClick} />
    </>
  )
};

export default HomeGuidanceiOS;