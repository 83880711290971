import React, { useEffect } from "react";
import { Global } from "@emotion/react";
import { Link } from "react-router-dom";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useRecoilState } from "recoil";
import { bottomBarAtom } from "../state/atoms";

import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Grid, Stack } from "@mui/material";

import walkIcon from "../walkIcon.svg";
import dispatchIcon from "../dispatchIcon.svg";
import ticketIcon from "../ticketIcon.svg";
import busStopListIcon from "../busStopListIcon.svg";
import homeIcon from "../homeIcon.svg";
import eduNewsIcon from "../eduNewsIcon.svg";
import { goToTicketService } from "../utility";

type SwipeableBottomBarProps = {
  defaultOpen?: boolean;
};

export const drawerBleeding = 30;
export const drawerHeight = 210;

const StyledBox = styled(Box)({
  backgroundColor: grey[50],
});

const StyledGrid = styled(Grid)({
  padding: "8px",
});
const StyledLink = styled(Link)(({ theme }) => ({
  display: "inline-block",
  width: "100%",
  height: "100%",
  textAlign: "center",
  backgroundColor: theme.palette.primary.main,
  border: `1px solid ${theme.palette.primary.contrastText}`,
  boxSizing: "border-box",
  borderRadius: "10px",
  textDecoration: "none",
}));

const StyledImg = styled("img")({
  height: "50px",
  padding: "4px",
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "12.8px",
  lineHeight: "16px",
  textAlign: "center",
  color: theme.palette.secondary.main,
}));

const Puller = styled(Box)({
  width: 30,
  height: 6,
  backgroundColor: grey[400],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
});

export default function BottomMenu(props: SwipeableBottomBarProps) {
  const { defaultOpen } = props;
  const { instance, accounts } = useMsal();
  const [open, setOpen] = useRecoilState(bottomBarAtom);

  useEffect(() => {
    if (defaultOpen !== undefined) setOpen(defaultOpen);
  }, [defaultOpen, setOpen]);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const handleClick = () => {
    const scopes: string[] = (process.env.REACT_APP_ADB2C_SCOPES ?? "").split(",");
    const accessTokenRequest = {
      scopes: scopes,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        goToTicketService(accessTokenResponse.accessToken);
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance
            .acquireTokenPopup(accessTokenRequest)
            .then(function (accessTokenResponse) {
              goToTicketService(accessTokenResponse.accessToken);
            })
            .catch(function (error) {
              alert(error);
            });
        }
      });
  };

  return (
    <>
      <Global
        styles={{
          ".MuiDrawer-root > .MuiDrawer-paperAnchorBottom": {
            height: `${drawerHeight}px`,
            overflow: "visible",
          },
        }}
      />
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        hideBackdrop={true}
        ModalProps={{
          keepMounted: true,
          disableEnforceFocus: true
        }}
        sx={{ top: "unset" }}
      >
        <StyledBox
          sx={{
            position: "absolute",
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            right: 0,
            left: 0,
            width: "100%",
            height: drawerBleeding,
          }}
        >
          <Puller sx={{ m: 1, color: "text.secondary" }} />
        </StyledBox>
        <StyledGrid container spacing={{ xs: 1 }} columns={{ xs: 6, sm: 6, md: 6 }}>
          <Grid item xs={2} sm={2} md={2}>
            {/* 経路検索 Route Search */}
            <StyledLink to="/search">
              <Stack spacing={1} sx={{ padding: 1 }}>
                <StyledImg src={walkIcon} alt="routes" />
                <StyledTypography>経路検索</StyledTypography>
              </Stack>
            </StyledLink>
          </Grid>
          <Grid item xs={2} sm={2} md={2}>
            {/* 配車 Dispatch Taxi/Bus */}
            <StyledLink to="/dispach-menu">
              <Stack spacing={1} sx={{ padding: 1 }}>
                <StyledImg src={dispatchIcon} alt="dispatch" />
                <StyledTypography>配車</StyledTypography>
              </Stack>
            </StyledLink>
          </Grid>
          <Grid item xs={2} sm={2} md={2}>
            {/* チケット Ticket */}
            <StyledLink to="" onClick={handleClick}>
              <Stack spacing={1} sx={{ padding: 1 }}>
                <StyledImg src={ticketIcon} alt="ticket" />
                <StyledTypography>チケット</StyledTypography>
              </Stack>
            </StyledLink>
          </Grid>
          <Grid item xs={2} sm={2} md={2}>
            {/* 乗り場情報 Bus Stop List */}
            <StyledLink to="/busstops">
              <Stack spacing={1} sx={{ padding: 1 }}>
                <StyledImg src={busStopListIcon} alt="busstops" />
                <StyledTypography>乗り場情報</StyledTypography>
              </Stack>
            </StyledLink>
          </Grid>
          <Grid item xs={2} sm={2} md={2}>
            {/* ホーム画面 Home Screen */}
            <StyledLink to="/">
              <Stack spacing={1} sx={{ padding: 1 }}>
                <StyledImg src={homeIcon} alt="home" />
                <StyledTypography>ホーム画面</StyledTypography>
              </Stack>
            </StyledLink>
          </Grid>
          <Grid item xs={2} sm={2} md={2}>
            {/* 施設情報 Edu News */}
            <StyledLink to="/pois">
              <Stack spacing={1} sx={{ padding: 1 }}>
                <StyledImg src={eduNewsIcon} alt="spots" />
                <StyledTypography>施設アクセス</StyledTypography>
              </Stack>
            </StyledLink>
          </Grid>
        </StyledGrid>
      </SwipeableDrawer>
    </>
  );
}
