import React, { useEffect } from 'react';
import { useRecoilState } from "recoil";

import { Navigate, Outlet } from 'react-router-dom';
import axios from "axios";
import { InteractionRequiredAuthError } from "@azure/msal-common";
import { useMsal } from "@azure/msal-react";

import Typography from '@mui/material/Typography';

import TopBar from "../TopBar";

import { getGraphToken, getAdb2cUser } from "../utils/loginForNoriaigo";

import { KagaAddressAtom } from "../state/atoms";

const AuthKagaRoute = () => {
    const { instance, accounts } = useMsal();
    const [isKagaAddress, setIsKagaAddress] = useRecoilState(KagaAddressAtom);
    const local_id = accounts[0].localAccountId;

    const getComponent = (auth: boolean | undefined) => {
        if (auth === undefined) {
            return (
                <main>
                    <TopBar title="デマンド予約" hasMargin={true} />
                    <Typography sx={{textAlign: "center"}}>居住地を確認中です。<br />しばらくお待ちください。</Typography>
                </main>
            )
        } else if (auth) {
            return <Outlet />;
        } else {
            return <Navigate to="/dispach-menu" />;
        }
    };

    useEffect(() => {
        if (isKagaAddress === undefined) {
            const getAccessToken = async () => {
                const scopes: string[] = (process.env.REACT_APP_ADB2C_SCOPES ?? "").split(",");
                const accessTokenRequest = {
                    scopes: scopes,
                    account: accounts[0],
                };
                let authorization = "";
                await instance
                    .acquireTokenSilent(accessTokenRequest)
                    .then((accessTokenResponse) => {
                    authorization = `Bearer ${accessTokenResponse.accessToken}`;
                    })
                    .catch((error) => {
                        if (error instanceof InteractionRequiredAuthError) {
                            instance.acquireTokenPopup(accessTokenRequest).then((accessTokenResponse) => {
                                authorization = `Bearer ${accessTokenResponse.accessToken}`;
                            });
                        }
                    });
                try {
                    const response = getGraphToken(authorization).then((res) => {
                        return res;
                    });

                    return response;
                } catch (e) {
                    if (axios.isAxiosError(e) && e.response) {
                        return e.response;
                    }
                }
            };

            const setIsKagaAddressAtom = async (token: string) => {
                const userInfo = await getAdb2cUser(local_id, token).then((res: any) => {
                    return res.data;
                });
                const key_arr = Object.keys(userInfo);
                const extension_key = key_arr.find((element) => element.includes("Gender"))?.split("_")[1];
                const is_kaga_address_flag = userInfo[`extension_${extension_key}_is_kaga_address`]
                setIsKagaAddress(is_kaga_address_flag === undefined ? false : is_kaga_address_flag);
            };

            getAccessToken().then(res => {
                if (res && res.status === 200) {
                    const token = res.data.access_token;
                    setIsKagaAddressAtom(token);
                } else {
                    setIsKagaAddress(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {getComponent(isKagaAddress)}
        </>
    );
}

export default AuthKagaRoute;