import React from "react";
// import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material";
import { useRecoilState } from "recoil";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
// import axios from "axios";

import TopBar from "./TopBar";
import SwipeableBottomBar from "./components/SwipeableBottomBar";

import getIconForType from "./RouteStepIcons";
import { selectedCourseAtom } from "./state/atoms";
import { Course, Step, GeoPoint } from "./types/routing";
import { formattedTimeFromISODate, goToTicketService } from "./utility";
import { topBarHeight } from "./utils/constants";
// import { getGraphToken, getAdb2cUser } from "./utils/loginForNoriaigo";

import "./RouteDetails.css";

const ScrolableMain = styled("main")({
  overflow: "scroll",
  height: `${window.innerHeight - topBarHeight}px`,
});

export function formattedLineColor(color: string) {
  if (color == null || color.length < 9) {
    return "rgb(0,0,0)";
  }
  // color in Mixway is given in RRRGGGBBB value,
  // whereas each block of 3 digits goes from 000 to 255.
  const rSubstring: number = parseInt(color.substr(0, 3));
  const gSubstring: number = parseInt(color.substr(3, 3));
  const bSubstring: number = parseInt(color.substr(6, 3));
  return "rgb(" + rSubstring + "," + gSubstring + "," + bSubstring + ")";
}

type RouteStepPair = {
  geoPoint: GeoPoint;
  name: string;
  step?: Step;
};

type RouteStepProps = {
  index: number;
  station: GeoPoint;
  name: string;
  step?: Step;
  course: Course;
};

function RouteStep(props: RouteStepProps) {
  if (props.step) {
    let countGeoPoint = 0;
    props.course.Route.Steps.forEach((el) => {
      const isPoint = Object.keys(el).some((val) => val === "GeoPoint");
      isPoint && (countGeoPoint += 1);
    });
    return (
      <>
        <div className="routeStep">
          <div className="info">
            {props.index ? (
              <>
                <div className="transferDepartureTime">
                  {formattedTimeFromISODate(props.step?.DepartureState.Datetime.text)}発
                </div>
                <div className="dot" style={{ backgroundColor: formattedLineColor(props.step?.Color) }}></div>
              </>
            ) : (
              <>
                <div className="time">{formattedTimeFromISODate(props.step?.DepartureState.Datetime.text)}発</div>
                <div className="dot" style={{ backgroundColor: formattedLineColor(props.step?.Color) }}></div>
              </>
            )}
          </div>
          <div className="instructions" style={{ borderLeftColor: formattedLineColor(props.step?.Color) }}>
            <div className="location">{props.name}</div>
            <div className="methodContainer">
              <div className="methodIcon">{getIconForType(props.step?.Type, props.step?.Name)}</div>
              <div className="method">
                {props.step?.Name} {props.step?.timeOnBoard !== "0" ? `${props.step?.timeOnBoard}分` : ""}
                {props.step?.fare ? <div>{props.step?.fare}円</div> : <></>}
              </div>
            </div>
          </div>
          {props.index < countGeoPoint - 2 && (
            <div className="info">
              <div className="transferArrivalTime">
                {formattedTimeFromISODate(props.step?.ArrivalState.Datetime.text)}着
              </div>
            </div>
          )}
        </div>
      </>
    );
  } else {
    // destination
    return (
      <>
        <div className="routeStep routeStepLast">
          <div className="info">
            <div className="time">{props.course.LastArrival}着</div>
            <div className="dot"></div>
          </div>
          <div className="instructionsLast">
            <div className="location">{props.name}</div>
          </div>
        </div>
      </>
    );
  }
}

function RouteDetails() {
  const [selectedCourse] = useRecoilState<Course>(selectedCourseAtom);
  // const [isKagaAddress, setIsKagaAddress] = useRecoilState(KagaAddressAtom);
  const { instance, accounts } = useMsal();
  // const local_id = accounts[0].localAccountId;
  // const navigate = useNavigate();

  function handleOpenTicket(e: any) {
    const scopes: string[] = (process.env.REACT_APP_ADB2C_SCOPES ?? "").split(",");
    const accessTokenRequest = {
      scopes: scopes,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        goToTicketService(accessTokenResponse.accessToken);
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance
            .acquireTokenPopup(accessTokenRequest)
            .then(function (accessTokenResponse) {
              goToTicketService(accessTokenResponse.accessToken);
            })
            .catch(function (error) {
              alert(error);
            });
        }
      });
  }

  /*
  function handleOpenNoriai(e: any) {
    handleKagaAddress();
    isKagaAddress && navigate("/dispach-noriaigo");
  }
  */

  /*
  const handleKagaAddress = () => {
    if (isKagaAddress === undefined) {
      const getAccessToken = async () => {
        const scopes: string[] = (process.env.REACT_APP_ADB2C_SCOPES ?? "").split(",");
        const accessTokenRequest = {
          scopes: scopes,
          account: accounts[0],
        };
        let authorization = "";
        await instance
          .acquireTokenSilent(accessTokenRequest)
          .then((accessTokenResponse) => {
            authorization = `Bearer ${accessTokenResponse.accessToken}`;
          })
          .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
              instance.acquireTokenPopup(accessTokenRequest).then((accessTokenResponse) => {
                authorization = `Bearer ${accessTokenResponse.accessToken}`;
              });
            }
          });
        try {
          const response = getGraphToken(authorization).then((res) => {
            return res;
          });

          return response;
        } catch (e: any) {
          if (axios.isAxiosError(e) && e.response) {
            return e.response;
          }
        }
      };
      

      const setIsKagaAddressAtom = async (token: string) => {
        const userInfo = await getAdb2cUser(local_id, token).then((res) => {
          return res.data;
        });
        const key_arr = Object.keys(userInfo);
        const extension_key = key_arr.find((element) => element.includes("Gender"))?.split("_")[1];
        const is_kaga_address_flag = userInfo[`extension_${extension_key}_is_kaga_address`];
        setIsKagaAddress(is_kaga_address_flag === undefined ? false : is_kaga_address_flag);
      };

      getAccessToken().then((res) => {
        if (res && res.status === 200) {
          const token = res.data.access_token;
          setIsKagaAddressAtom(token);
        } else {
          setIsKagaAddress(false);
        }
      });
    }
  };
  */

  const routeSegments = selectedCourse?.Route?.Steps?.reduce((list: Array<RouteStepPair>, value, index, source) => {
    if (index === source.length - 1) {
      // destination station
      const point: Step = source[index];
      list.push({
        geoPoint: point.GeoPoint,
        name: point.Name,
      });
    } else if (index % 2 === 0) {
      const point: Step = source[index];
      const step: Step = source[index + 1];
      list.push({
        geoPoint: point.GeoPoint,
        name: point.Name,
        step: step,
      });
    }
    return list;
  }, []).map((pair: RouteStepPair, index: number) => (
    <RouteStep
      key={index}
      index={index}
      station={pair.geoPoint}
      name={pair.name}
      step={pair.step}
      course={selectedCourse}
    />
  ));

  return (
    <ScrolableMain>
      <TopBar title="移動経路検索" hasMargin={false} showReturnIcon={true} />
      <div className="results-div1">
        <div className="results-div2">
          <div className="routeDetailsList">
            <div className="info">
              {selectedCourse.Duration}分　{selectedCourse.Cost}円
            </div>

            <div className="segmentList">{routeSegments}</div>

            <button className="mainButton" onClick={handleOpenTicket}>
              チケットの購入はこちら
            </button>
            <>
              <div className="notKagaCivil">のりあい号は只今休止中です</div>
              <button className="mainButton disabledButton" disabled>
                のりあい号の予約はこちら
              </button>
            </>
          </div>
        </div>
        <SwipeableBottomBar defaultOpen={true} />
        <div className="bottomBar"></div>
      </div>
    </ScrolableMain>
  );
}

export default RouteDetails;
