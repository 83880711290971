import { Box, Button, IconButton, Typography, styled } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const StyledButton = styled(Button)({
  backgroundColor: "#AE522D",
  color: "#FFFFFF",
  "&:hover": {
    backgroundColor: "#AE522D",
  },
});

type SelectLocationButtonProps = {
  onClick: any;
};

const SelectLocationButton = (props: SelectLocationButtonProps) => {
  const { onClick } = props;

  return (
    <Box m={1}>
      <StyledButton variant="outlined" sx={{ width: "100%" }} onClick={onClick}>
        <IconButton edge="start">{<LocationOnIcon />}</IconButton>
        <Typography sx={{ fontWeight: "bold" }}>ここを選択する</Typography>
      </StyledButton>
    </Box>
  );
};

export default SelectLocationButton;
