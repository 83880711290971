import { Button } from "@mui/material";

type Props = {
  onClick: () => void;
}

const BackButton = (props: Props) => {
  const { onClick } = props;
  return (
    <>
      <Button
        onClick={() => onClick()}
        sx={{ 
          color: '#3A1D0B',
          fontSize: '16px',
          fontWeight: 'bold',
          display: "block",
          margin: "8px",
        }}
      >
        ＜戻る
      </Button>
    </>
  )
};

export default BackButton;