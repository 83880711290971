class MobileOS {
  get() {
    const userAgent = navigator.userAgent;
    if ((/iPad|iPhone|iPod/.test(userAgent)) || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)) {
      return "iOS";
    }else if (/android/i.test(userAgent)){
      return "Android";
    }else {
      return "Other";
    }
  }
}

export default MobileOS;