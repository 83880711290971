import { Dialog, DialogContent, DialogActions, List, ListItem, ListItemText, Button } from "@mui/material";

type PropsType = {
  open: boolean;
  onClose: () => void;
  handleClick: () => void;
  primaryText: string;
  secondaryText?: string;
};
const CancelDialog = (props: PropsType) => {
  const { open, primaryText, secondaryText, onClose, handleClick } = props;
  return (
    <>
      <Dialog open={open}>
        <DialogContent>
          <List>
            <ListItem sx={{ textAlign: "center" }}>
              <ListItemText primary={primaryText} secondary={secondaryText} />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{"キャンセル"}</Button>
          <Button onClick={handleClick}>{"OK"}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CancelDialog;
