import { Button, Typography } from "@mui/material";

type Props = {
  text: string;
  onClick: () => void;

}

const StyledButton = (props: Props) => {
  const { text, onClick } = props;
  return (
    <>
      <Button
        onClick={() => onClick()}
        sx={{
          height: "5vh",
          width: "70%",
          margin: "20px",
          borderRadius: "30px",
          color: "secondary.main",
          backgroundColor: "primary.main",
          "&:hover": {
            backgroundColor: "primary.main",
          }
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          {text}
        </Typography>
      </Button>
    </>
  )
};

export default StyledButton;