import React, { useState } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { useParams, useNavigate } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-common";

import {
  OutlinedInput,
  InputAdornment,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Box,
  Divider,
  ListItemIcon,
  Typography,
  styled
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from "@mui/icons-material/Search";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import RestoreIcon from "@mui/icons-material/Restore";

import TopBar from "./TopBar";
import BottomBar, { drawerHeight, drawerBleeding } from "./components/SwipeableBottomBar";

import { SearchNoriaigo } from "../src/types/noriaigo";
import { noriaigoOriginAtom, noriaigoDestinationAtom, bottomBarAtom } from "./state/atoms";
import { getLocalStorageItems, appendLocalStorageItem } from "./utils/localStorage";
import { topBarHeight } from "./utils/constants";

const APIM_ENDPOINT = process.env.REACT_APP_APIM_ENDPOINT!;

const ScrolableMain = styled("main")({
  overflow: "scroll",
  height: `${window.innerHeight - topBarHeight - 160}px`,
});

const RouteLocationChangeForNoriaigo = () => {
  const { instance, accounts } = useMsal();
  const { id } = useParams();
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [noriaigoLocations, setNoriaigoLocations] = useState<SearchNoriaigo[] | null>(null);
  const setNoriaigoOrigin = useSetRecoilState(noriaigoOriginAtom);
  const setNoriaigoDestination = useSetRecoilState(noriaigoDestinationAtom);
  const open = useRecoilValue(bottomBarAtom);

  let navigate = useNavigate();

  const searchNoriaigo = async (history?: string) => {
    const scopes: string[] = (process.env.REACT_APP_ADB2C_SCOPES ?? "").split(",");
    const accessTokenRequest = {
      scopes: scopes,
      account: accounts[0],
    };
    let authorization = "";
    await instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        authorization = `Bearer ${accessTokenResponse.accessToken}`;
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenPopup(accessTokenRequest).then((accessTokenResponse) => {
            authorization = `Bearer ${accessTokenResponse.accessToken}`;
          });
        }
      });
    const response: AxiosResponse = await axios.get(`${APIM_ENDPOINT}/noriaigo`, {
      timeout: 100000,
      headers: {
        Authorization: authorization,
      },
      params: {
        search: history ? history : value,
      },
    });
    return response;
  };

  const SearchHistoryList = () => {
    let currentHistories = getLocalStorageItems("KAGA-NORIAIGO-SEARCH-HISTORY");
    currentHistories = currentHistories.reverse();
    return (
      <>
        <List sx={{ paddingTop: 0 }}>
          {currentHistories.map((history: string, index) => (
            <React.Fragment key={index}>
              <ListItemButton
                sx={{ minHeight: "48px" }}
                onClick={() => {
                  setValue(history);
                  setNoriaigoLocations(null);
                  setIsLoading(true);
                  searchNoriaigo(history).then((res) => {
                    setIsLoading(false);
                    setNoriaigoLocations(res.data);
                    appendLocalStorageItem("KAGA-NORIAIGO-SEARCH-HISTORY", history);
                  });
                }}
              >
                <ListItemIcon sx={{ minWidth: "40px", margin: 0 }}>
                  <RestoreIcon />
                </ListItemIcon>
                <ListItemText primary={history}></ListItemText>
              </ListItemButton>
              {index !== currentHistories.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </>
    );
  };

  const SearchResultList = () => {
    return (
      <ScrolableMain>
        <List sx={{ paddingTop: 0 }}>
          {noriaigoLocations &&
            (noriaigoLocations.length > 0 ? (
              noriaigoLocations.map((location, index) => (
                <React.Fragment key={index}>
                  <ListItemButton
                    onClick={() => {
                      id === "origin" ? setNoriaigoOrigin(location) : setNoriaigoDestination(location);
                      navigate("/noriaigo/search");
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: "40px" }}>
                      <LocationOnIcon />
                    </ListItemIcon>
                    <ListItemText primary={location.name}></ListItemText>
                  </ListItemButton>
                  {index !== noriaigoLocations.length - 1 && <Divider />}
                </React.Fragment>
              ))
            ) : (
              <Typography sx={{ position: "fixed", marginTop: "10px", left: 0, right: 0 }}>
                {"検索結果はありませんでした"}
              </Typography>
            ))}
          <Box sx={{height: `${drawerBleeding}px`}}></Box>
        </List>
        {
          open &&
          <Box sx={{height: `${drawerHeight}px`}}></Box>
        }
      </ScrolableMain>
    );
  };

  return (
    <>
      <TopBar title={id === "origin" ? "出発地" : "目的地"} hasMargin={false} showReturnIcon={true} opacity="1" />
      <Box>
        <Box sx={{ position: "absolute", height: "160px", width: "100%", top: `${window.innerWidth > 600 ? "64px" : "50px"}`, bgcolor: "#FFFFFF" }}>
          <OutlinedInput
            sx={{ position: "relative", width: "90%", height: "40px", marginTop: "16px" }}
            value={value}
            type="text"
            placeholder="バス停名で検索してください"
            onChange={(event: any) => {
              setValue(event.target.value);
              setNoriaigoLocations(null);
            }}
            onKeyPress={(event: any) => {
              if (event.key === "Enter") {
                if (value === "") {
                  setNoriaigoLocations(null);
                } else {
                  setIsLoading(true);
                  searchNoriaigo().then((res) => {
                    setIsLoading(false);
                    setNoriaigoLocations(res.data);
                    appendLocalStorageItem("KAGA-NORIAIGO-SEARCH-HISTORY", value);
                  });
                }
              }
            }}
            startAdornment={
              <InputAdornment position="start" sx={{ padding: "0px" }}>
                <IconButton edge="start">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
          <List>
            <ListItemButton
              onClick={() => {
                navigate(`/noriaigo/search/map/${id}`);
              }}
            >
              <IconButton edge="start">
                <LocationOnIcon />
              </IconButton>
              <ListItemText
                primary={"地図から検索する"}
                primaryTypographyProps={{
                  color: "secondary.main",
                }}
              />
            </ListItemButton>
          </List>
          <Box sx={{ height: "28px", width: "100%", bgcolor: "#3A1D0B" }}>
          {value === "" ? 
              <Typography 
                sx={{ 
                  height: "28px",
                  color: "#F9E1C9",
                  fontWeight: "bold",
                  fontSize: "18px"
                }}>
                検索履歴
              </Typography>
            :
              <Typography
                sx={{
                  height: "28px",
                  color: "#F9E1C9",
                  fontWeight: "bold",
                  fontSize: "18px"
                }}>
                検索結果
              </Typography>
          }
          </Box>
        </Box>
        <Box sx={{ position: "absolute", width: "100%", top: `${window.innerWidth > 600 ? "224px" : "210px"}` }}>
          {value === "" ? (
            <>
              <Box sx={{ width: "100%" }}>
                <SearchHistoryList />
              </Box>
            </>
          ) : (
            <>
              {isLoading ? (
                <CircularProgress sx={{ position: "fixed", top: "250px", left: 0, right: 0, margin: "auto" }} />
              ) : (
                <Box>
                  <SearchResultList />
                </Box>
              )}
            </>
          )}
        </Box>
        <BottomBar defaultOpen={true} />
      </Box>
    </>
  );
};

export default RouteLocationChangeForNoriaigo;
