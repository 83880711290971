import React from "react";

import ReactMapGL from "react-map-gl";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { viewportAtom } from "../state/atoms";

import { useRecoilState } from "recoil";

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const MAPBOX_STYLE = process.env.REACT_APP_MAPBOX_STYLE || "mapbox://styles/mtj/cktm7a4fd31hw17qwbfehgef8";

export type MapProps = {
  height?: number;
  children: React.ReactNode;
};

function Map(props: MapProps) {
  const { height, children } = props;
  const [viewport, setViewport] = useRecoilState(viewportAtom);

  return (
    <ReactMapGL
      {...viewport}
      height={height ? `${height}vh` : "100vh"}
      width="100%"
      onViewportChange={setViewport}
      mapStyle={MAPBOX_STYLE}
    >
      {children}
    </ReactMapGL>
  );
}

export default Map;
