import headerIcon from "../img/loginHeaderBackground.svg";
import { Box } from "@mui/material";

const BackgroundHeader = () => {
  return (
    <>
      <Box className="header" sx={{
          width: "100%",
          height: "233px",
          background: "linear-gradient(110.46deg,rgba(248, 235, 169, 0.79) 1.52%,#e0a86e 97.13%)"
        }}>
        <Box className="background" sx={{
            width: "100%",
            height: "100%",
            backgroundImage: `url(${headerIcon})`
          }}>
          <Box className="tagline" sx={{
              fontFamily: "Roboto",
              textAlign: "center",
              color: "#3a1d0b",
              paddingTop: "40pt",
              fontWeight: "400",
              fontSize: "18px"
            }}>{"さあ、自由に移動しよう"}</Box>
          <Box className="heading">{"NoluDay"}</Box>
        </Box>
      </Box>
    </>
  )
}

export default BackgroundHeader;