import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";

import axios, { AxiosResponse } from "axios";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-common";

import { Alert, Box, Collapse, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import TopBar from "./TopBar";
import BottomBar from "./components/SwipeableBottomBar";
import Map from "./components/Map";
import LocationControl from "./elements/LocationControl";
import MapNavigationControl from "./elements/MapNavigationControl";
import { headerMarginTop } from "./utils/constants";
import { getGraphToken, getAdb2cUser, updateAdb2cUser, generate_login_id } from "./utils/loginForNoriaigo";

import "./Home.css";

import { KagaAddressAtom } from "./state/atoms";
import TutorialDialog from "./components/TutorialDialog";

function Home() {
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const { instance, accounts } = useMsal();
  const setIsKagaAddress = useSetRecoilState(KagaAddressAtom);
  const local_id = accounts[0].localAccountId;
  const APIM_ENDPOINT = process.env.REACT_APP_APIM_ENDPOINT!;
  const [isTutorial, setIsTutorial] = useState<boolean>(true);

  const getAccessToken = async () => {
    const scopes: string[] = (process.env.REACT_APP_ADB2C_SCOPES ?? "").split(",");
    const accessTokenRequest = {
      scopes: scopes,
      account: accounts[0],
    };
    let authorization = "";
    await instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        authorization = `Bearer ${accessTokenResponse.accessToken}`;
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenPopup(accessTokenRequest).then((accessTokenResponse) => {
            authorization = `Bearer ${accessTokenResponse.accessToken}`;
          });
        }
      });
    try {
      const response = getGraphToken(authorization).then((res) => {
        return res;
      });

      return response;
    } catch (e) {
      if (axios.isAxiosError(e) && e.response) {
        return e.response;
      }
    }
  };

  const createUser = async (
    phone_number: string,
    sur_name: string,
    sur_name_yomi: string,
    given_name: string,
    given_name_yomi: string
  ) => {
    const scopes: string[] = (process.env.REACT_APP_ADB2C_SCOPES ?? "").split(",");
    const accessTokenRequest = {
      scopes: scopes,
      account: accounts[0],
    };
    let authorization = "";
    await instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        authorization = `Bearer ${accessTokenResponse.accessToken}`;
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenPopup(accessTokenRequest).then((accessTokenResponse) => {
            authorization = `Bearer ${accessTokenResponse.accessToken}`;
          });
        }
      });
    try {
      const response: AxiosResponse = await axios.post(
        `${APIM_ENDPOINT}/noriaigo/users`,
        {
          login_id: phone_number,
          last_name: sur_name,
          first_name: given_name,
          last_name_reading: sur_name_yomi,
          first_name_reading: given_name_yomi,
          memo: "MaaSアプリ経由",
        },
        {
          timeout: 100000,
          headers: {
            Authorization: authorization,
          },
        }
      );
      return response;
    } catch (e) {
      if (axios.isAxiosError(e) && e.response) {
        if (e.response.status === 422) {
          // 予約番号重複時対応
          const secondary_id = generate_login_id();
          const secondary_response = await axios.post(
            `${APIM_ENDPOINT}/noriaigo/users`,
            {
              login_id: secondary_id,
              last_name: sur_name,
              first_name: given_name,
              last_name_reading: sur_name_yomi,
              first_name_reading: given_name_yomi,
              memo: "MaaSアプリ経由",
            },
            {
              timeout: 100000,
              headers: {
                Authorization: authorization,
              },
            }
          );
          return secondary_response;
        }

        return e.response;
      }
    }
  };

  const handleNoriaigoLogin = async (token: string) => {
    const userInfo = await getAdb2cUser(local_id, token).then((res) => {
      return res.data;
    });
    const key_arr = Object.keys(userInfo);
    const login_id_key = key_arr.find((element) => element.includes("login_id"));
    const extension_key = key_arr.find((element) => element.includes("Gender"))?.split("_")[1];
    if (userInfo) {
      // 加賀市フラグを取ってくる
      let is_kaga_address_flag = userInfo[`extension_${extension_key}_is_kaga_address`];
      const account: any = accounts;
      let prefecture: string = "";
      let city: string = "";
      const edit_account = account.filter((ele: any) => ele.homeAccountId.includes("editprofile"));
      if (accounts.length === 1) {
        // 住所を取ってくる
        prefecture = account[0].idTokenClaims.state;
        city = account[0].idTokenClaims.city;
      } else if (edit_account.length === 1) {
        // accountの中にあるうちeditprofileが含まれるオブジェクトを選択する
        const edit_account = account.filter((ele: any) => ele.homeAccountId.includes("editprofile"));
        prefecture = edit_account[0].idTokenClaims.state;
        city = edit_account[0].idTokenClaims.city;
      } else {
        const signinup_account = account.filter((ele: any) => ele.homeAccountId.includes("signinup"));
        prefecture = signinup_account[0].idTokenClaims.state;
        city = signinup_account[0].idTokenClaims.city;
      }
      // 石川県加賀市かどうか判定
      let is_kaga_address: boolean = false;
      if (prefecture === "石川県" && (city.startsWith("加賀市") || city.startsWith("かがし"))) {
        is_kaga_address = true;
      } else {
        is_kaga_address = false;
      }
      setIsKagaAddress(is_kaga_address);
      // フラグと比較して、差があれば更新
      let params: any = {};
      if (is_kaga_address !== is_kaga_address_flag) {
        params[`extension_${extension_key}_is_kaga_address`] = is_kaga_address;
      }

      let phone_number_key = key_arr.find((element) => element.includes("PhoneNumber"));
      if (!phone_number_key) {
        phone_number_key = generate_login_id();
      }

      if (!login_id_key) {
        const phone_number = phone_number_key && userInfo[phone_number_key];
        const sur_name = userInfo["surname"];
        const sur_name_yomi_key = key_arr.find((element) => element.includes("surName_yomi"));
        let sur_name_yomi = sur_name_yomi_key && userInfo[sur_name_yomi_key];
        const given_name = userInfo["givenName"];
        const given_name_yomi_key = key_arr.find((element) => element.includes("givenName_yomi"));
        let given_name_yomi = given_name_yomi_key && userInfo[given_name_yomi_key];
        if (!sur_name_yomi && !given_name_yomi) {
          sur_name_yomi = sur_name;
          given_name_yomi = given_name;
        }
        const login_id =
          sur_name_yomi &&
          given_name_yomi &&
          (await createUser(phone_number, sur_name, sur_name_yomi, given_name, given_name_yomi).then((res) => {
            if (res !== undefined && res.status === 201) {
              return res.data.login_id;
            }
          }));

        params[`extension_${extension_key}_login_id`] = login_id;
      }
      Object.keys(params).length &&
        (await updateAdb2cUser(local_id, params, token).then((res) => {
          // console.log(res.status);
        }));
    }
  };

  useEffect(() => {
    getAccessToken().then((res) => {
      if (res && res.status === 200) {
        const token = res.data.access_token;
        handleNoriaigoLogin(token);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <main>
        <TopBar title="ホーム" hasMargin={false} />
        <Map>
          {/* {pois.length >= 0 && <PinLayer data={pois} />} */}
          <LocationControl style={{ right: 10, top: 60 }} />
          <MapNavigationControl style={{ right: 10, top: 100 }} />
        </Map>
        <BottomBar />
      </main>
      <Box
        sx={{
          width: "100%",
          p: 2,
          top: headerMarginTop,
          position: "absolute",
        }}
      >
        <Collapse in={isOpenAlert}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setIsOpenAlert(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            該当データがありません
          </Alert>
        </Collapse>
      </Box>
      {!localStorage.getItem("KAGA-CLOSE-DIALOG") && isTutorial && (
        <TutorialDialog
          isChecked={false}
          title={"MaaSアプリ「Noluday」の休止について"}
          description={`MaaSアプリ「NoluDay」は、次の期間運用を休止します。\n
            休止期間：令和6年10月7日から令和7年3月31日まで\n
            皆様にはご不便をおかけしますが、ご了承のほどお願いいたします。`}
          link="https://www.city.kaga.ishikawa.jp/soshiki/seisaku_senryaku/seisaku_suishin/5/12799.html"
          buttonText={"閉じる"}
          handleClick={(isAgree) => {
            if (isAgree) {
              localStorage.setItem("KAGA-CLOSE-DIALOG", "agree");
            }
            setIsTutorial(false);
          }}
        />
      )}
    </>
  );
}

export default Home;
