import { atom } from "recoil";
import { SearchQuery, LocationType } from "./types/search";

export const ListItems = [
  { id: "bus", text: "バス" },
  { id: "walk", text: "徒歩" },
  // bus subtypes:
  // {id: "bus", text: "路線バス"},
  // {id: "bus:local", text: "路線バス"},
  // {id: "bus:connection", text: "連絡バス"},
  // {id: "bus:highway", text: "高速バス"},
  // {id: "bus:midnight", text: "深夜急行バス"},
  // {id: "paidbus", text: "有料特急"},
  // {id: "highwaybus", text: "高速バス"},
  // {id: "strange", text: "特殊"},
];

export const SearchTypes = [
  { id: "departure", text: "出発" },
  { id: "arrival", text: "到着" },
  { id: "firstTrain", text: "始発" },
  { id: "lastTrain", text: "終電" },
];

export const PoiCategories = [
  {
    id: 2,
    position: 300,
    displayName: "グルメ・飲食",
    name: "グルメ・飲食",
    identifier: "restaurant",
    isOthers: false,
  },
  { id: 3, position: 400, displayName: "ショッピング", name: "ショッピング", identifier: "shopping", isOthers: false },
  { id: 5, position: 500, displayName: "病院", name: "病院・医院", identifier: "hospital", isOthers: false },
  { id: 4, position: 100, displayName: "ホテル・旅館", name: "旅行・宿泊", identifier: "sightseeing", isOthers: false },
  { id: 18, position: 1700, displayName: "公共機関", name: "公共機関・団体", identifier: "public", isOthers: false },
  {
    id: 17,
    position: 1600,
    displayName: "教育・習い事",
    name: "教育・習い事",
    identifier: "education",
    isOthers: false,
  },
  { id: 16, position: 1500, displayName: "趣味", name: "趣味", identifier: "hobby", isOthers: false },
  // 以下「その他」タブ
  {
    id: 1,
    position: 200,
    displayName: "冠婚葬祭・イベント",
    name: "冠婚葬祭・イベント",
    identifier: "event",
    isOthers: true,
  },
  { id: 7, position: 700, displayName: "暮らし", name: "暮らし", identifier: "living", isOthers: true },
  { id: 8, position: 800, displayName: "住まい", name: "住まい", identifier: "house", isOthers: true },
  { id: 9, position: 900, displayName: "健康・介護", name: "健康・介護", identifier: "health", isOthers: true },
  {
    id: 10,
    position: 1000,
    displayName: "美容・ファッション",
    name: "美容・ファッション",
    identifier: "beauty",
    isOthers: true,
  },
  { id: 11, position: 1100, displayName: "ビジネス", name: "ビジネス", identifier: "business", isOthers: true },
  { id: 13, position: 1200, displayName: "ペット", name: "ペット", identifier: "pet", isOthers: true },
  {
    id: 14,
    position: 1300,
    displayName: "レジャー・スポーツ",
    name: "レジャー・スポーツ",
    identifier: "leisure/sports",
    isOthers: true,
  },
  {
    id: 15,
    position: 1400,
    displayName: "自動車・バイク",
    name: "自動車・バイク",
    identifier: "cars/motorcycles",
    isOthers: true,
  },
  {
    id: 99,
    position: 9999,
    displayName: "その他",
    name: "その他",
    identifier: "others",
    isOthers: false,
  },
];

export const DefaultPoiCategory = "グルメ・飲食";

function initDefaultSearchQuery(): SearchQuery {
  return {
    startLocation: {
      title: "",
      type: LocationType.Regular,
    },
    destinationLocation: {
      title: "",
      type: LocationType.Regular,
    },
    searchType: "departure",
    // methods: ListItems.map((item) => item.id),
    // time: "2022-02-18T06:30:00+0900"
  };
}

export const queryState = atom({
  key: "query",
  default: initDefaultSearchQuery() as SearchQuery,
});

function initDefaultRouteResults() {
  return [];
}

export const routesState = atom({
  key: "route-results",
  default: initDefaultRouteResults() as any,
});

function initDefaultSuggestionsResults() {
  return [];
}

export const suggestionsState = atom({
  key: "suggestions-results",
  default: initDefaultSuggestionsResults() as any,
});
