import React, { useEffect, useState, useRef } from "react";

import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import axios from "axios";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { debounce } from "lodash";
import { List, Box, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import TopBar from "./TopBar";
import SwipeableBottomBar from "./components/SwipeableBottomBar";
import LocationSearchInput from "./components/LocationSearchInput";

import { queryState } from "./store";
import { LocationType } from "./types/search";
import { RootObject, Suggestion } from "./types/suggestions";
import { PositionType } from "./types";
import { defaultLocatioin } from "./utils/constants";
import { getLocalStorageItems, appendLocalStorageItem } from "./utils/localStorage";

import "./RouteLocationChange.css";

type SuggestionItemProps = {
  value: Suggestion;
};

const formatSuggestion = (suggestion: Suggestion) => {
  if (suggestion.type === "train" && suggestion.name.slice(-1) !== "駅") {
    return { ...suggestion, name: `${suggestion.name}駅` };
  } else if (suggestion.name.substring(suggestion.name.lastIndexOf("／")) === "／加賀第一交通のりあい号") {
    return { ...suggestion, name: suggestion.name.replace("／加賀第一交通のりあい号", "／のりあい号") };
  }
  return suggestion;
};

class SuggestionItem extends React.Component<SuggestionItemProps, {}> {
  render() {
    return (
      <>
        <div className="suggestion">{this.props.value.name}</div>
      </>
    );
  }
}

const RouteLocationChange = () => {
  const { id } = useParams();

  const { instance, accounts } = useMsal();

  let navigate = useNavigate();

  const [query, setQuery] = useRecoilState(queryState);

  const [suggestions, setSuggestions] = useState<Array<Suggestion>>([]);
  const [poiSuggestions, setPoiSuggestions] = useState<Array<Suggestion>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [criteria, setCriteria] = useState("");

  const [isGeolocationAvailable, setGeolocationAvailable] = useState(false);
  const [currentPosition, setCurrentPosition] = useState<PositionType>(defaultLocatioin);
  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setCurrentPosition({ latitude, longitude });
        setGeolocationAvailable(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGeolocationAvailable]);

  function onSelectSuggestion(suggestion: Suggestion) {
    appendLocalStorageItem("KAGA-SEARCH-HISTORY", JSON.stringify(suggestion));
    let newQuery = { ...query };
    const newLocation = {
      title: suggestion.name,
      code: suggestion.code,
      latitude: suggestion.latitude,
      longitude: suggestion.longitude,
      type: LocationType.Regular,
    };
    if (id === "start") {
      newQuery.startLocation = newLocation;
    } else {
      newQuery.destinationLocation = newLocation;
    }
    setQuery(newQuery);

    navigate("/search");
  }

  function chooseCurrentLocation() {
    let newQuery = { ...query };
    const newLocation = {
      title: "現在地",
      latitude: currentPosition.latitude,
      longitude: currentPosition.longitude,
      type: LocationType.CurrentLocation,
    };
    if (id === "start") {
      newQuery.startLocation = newLocation;
    } else {
      newQuery.destinationLocation = newLocation;
    }
    setQuery(newQuery);

    navigate("/search");
  }

  async function getPlacemarkSuggestions(accessToken: string, criteria: string) {
    const host = process.env.REACT_APP_ROUTING_HOST;
    const url = `${host}/suggestions/${criteria}`;
    axios
      .get(url, { headers: { Authorization: `Bearer ${accessToken}` } })
      .then((res) => {
        const results: RootObject = res.data;
        const suggestions: Array<Suggestion> = results.suggestions;
        setIsLoading(false);
        setSuggestions(suggestions);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
        alert(error);
      });
  }

  // poi情報検索対応
  async function getPoiSuggestions(criteria: string) {
    const cmsHost = process.env.REACT_APP_CMS_ENDPOINT;
    const url = `${cmsHost}/events?title_contains=${criteria}`;
    if (criteria !== "") {
      axios
        .get(url, { timeout: 100000 })
        .then((res) => {
          if (res) {
            const results = res.data;
            let poiSuggestions: Array<Suggestion> = [];
            results.forEach((r: any, i: number) => {
              let tempPoiSuggestion: Suggestion = {
                latitude: r.location.latitude,
                longitude: r.location.longitude,
                name: r.title,
                yomi: r.title,
                type: String(LocationType.Regular),
              };
              poiSuggestions.push(tempPoiSuggestion);
            });
            setPoiSuggestions(poiSuggestions);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error(error);
          alert(error);
        });
    }
  }

  async function search(criteria: string) {
    const scopes: string[] = (process.env.REACT_APP_ADB2C_SCOPES ?? "").split(",");
    const accessTokenRequest = {
      scopes: scopes,
      account: accounts[0],
    };
    setIsLoading(true);
    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        getPlacemarkSuggestions(accessTokenResponse.accessToken, criteria);
        getPoiSuggestions(criteria);
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance
            .acquireTokenPopup(accessTokenRequest)
            .then(function (accessTokenResponse) {
              getPlacemarkSuggestions(accessTokenResponse.accessToken, criteria);
            })
            .catch(function (error) {
              console.error(error);
              alert(error);
              setIsLoading(false);
            });
        }
        console.error(error);
      });
  }

  const SearchHistoryList = () => {
    const currentHistories = getLocalStorageItems("KAGA-SEARCH-HISTORY").reverse();
    const recentSuggestions: Array<Suggestion> = currentHistories.map((history: string) => JSON.parse(history));
    return (
      <>
        <List>
          {recentSuggestions.map((suggestion: Suggestion, index: number) => (
            <React.Fragment key={index}>
              <div key={index} onClick={() => onSelectSuggestion(formatSuggestion(suggestion))}>
                <SuggestionItem value={formatSuggestion(suggestion)} />
              </div>
            </React.Fragment>
          ))}
        </List>
      </>
    );
  };

  const debouncedSearch = useRef(
    debounce(async (name) => {
      search(name);
    }, 600)
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setCriteria(e.target.value);
    debouncedSearch(e.target.value);
  }

  const SuggestionList = () => {
    let suggestionItems: Array<Suggestion> = [];
    suggestions.length > 0 && suggestions.forEach((el: Suggestion) => suggestionItems.push(el));
    poiSuggestions.length > 0 && poiSuggestions.forEach((el: Suggestion) => suggestionItems.push(el));
    return suggestionItems.length > 0 ? (
      <>
        {suggestionItems?.map((suggestion: Suggestion, index: number) => (
          <div key={index} onClick={() => onSelectSuggestion(formatSuggestion(suggestion))}>
            <SuggestionItem value={formatSuggestion(suggestion)} />
          </div>
        ))}
      </>
    ) : (
      <>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography>
            適当な場所がありません。
            <br />
            異なる文言で再検索してください。
          </Typography>
        </Box>
      </>
    );
  };

  const defaultItemsList = () => {
    return (
      <>
        <div className="locationSearchHeader">検索履歴</div>
        {isGeolocationAvailable ? (
          <div className="suggestion" onClick={chooseCurrentLocation}>
            現在地
          </div>
        ) : (
          <div className="suggestion disabled">現在地（不可）</div>
        )}
        <SearchHistoryList />
      </>
    );
  };

  const loadingIndicator = () => {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    );
  };

  return (
    <>
      <TopBar title={id === "start" ? "出発地" : "目的地"} hasMargin={true} showReturnIcon={true} />
      <LocationSearchInput value={criteria} onChangeText={handleChange} />
      <div className="suggestionsList">
        {criteria ? isLoading ? loadingIndicator() : <SuggestionList /> : defaultItemsList()}
      </div>
      <SwipeableBottomBar defaultOpen={true} />
    </>
  );
};

export default RouteLocationChange;
