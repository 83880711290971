import { Box, Typography } from "@mui/material";
import DialogCard from "./DialogCard";

type Sx = {
  width?: string;
  height?: string;
  marginRight?: string;
  marginLeft?: string;
}

type Props = {
  mainText: string;
  img: any;
  sx?: Sx;
}

const HomeGuidance = (props: Props) => {
  const { mainText, img, sx } = props;
  return (
    <Box m={1} sx={{border: "2px solid #3a1d0b", borderRadius: "10px"}}>
      <Typography m={1} sx={{color: "secondary.main", fontWeight: "bold" }}>{mainText}</Typography>
      <DialogCard image={img} sx={sx} />
    </Box>
  )
};

export default HomeGuidance;