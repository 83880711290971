import React, { useEffect } from "react";

import { Routes, Route } from "react-router-dom";

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ja from "date-fns/locale/ja";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider, responsiveFontSizes } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { jaJP } from "@mui/material/locale";

import { EventType, InteractionType } from "@azure/msal-browser";
import { b2cPolicies } from "./authConfig";

import Login from "./Login";
import Home from "./Home";
import Guidance from "./Guidance";
import RouteSearch from "./RouteSearch";
import RouteLocationChange from "./RouteLocationChange";
import RouteOptions from "./RouteOptions";
import RouteSearchResults from "./RouteSearchResults";
import RouteDetails from "./RouteDetails";
import About from "./About";
import ModificationMailAddress from "./ModificationMailAddress";
import Pois from "./Pois";
import PoiDetails from "./PoiDetails";
import DispachMenu from "./DispachMenu";
import DispachTaxi from "./DispachTaxi";
import DispachNoriaigo from "./DispachNoriaigo";
import RouteSearchForNoriaigo from "./RouteSearchForNoriaigo";
import LocationMapForNoriaigo from "./LocationMapForNoriaigo";
import ReservationCandidateForNoriaigo from "./ReservationCandidateForNoriaigo";
import ReservationConfirmationForNoriaigo from "./ReservationConfirmationForNoriaigo";
import ReservationCompletionForNoriaigo from "./ReservationCompletionForNoriaigo";
import RouteLocationChangeForNoriaigo from "./RouteLocationChangeForNoriaigo";
import ReservationListForNoriaigo from "./ReservationListForNoriaigo";
import ReservationDetailForNoriaigo from "./ReservationDetailForNoriaigo";
import ReservationCancelForNoriaigo from "./ReservationCancelForNoriaigo";
import BusStops from "./BusStops";
import ResignMembership from "./ResignMembership";
import AuthKagaRoute from "./components/AuthKagaRoute";

import "./App.css";

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './utils/applicationInsights';

function App() {
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  // const theme = React.useMemo(
  //   () =>
  //     createTheme({
  //     }),
  //   [prefersDarkMode],
  // );
  let theme = createTheme(
    {
      palette: {
        mode: "light",
        primary: {
          main: "#E0A86E",
          light: "#E86100",
          contrastText: "#F2A762",
        },
        secondary: {
          main: "#3A1D0B",
          light: "#745F5F",
          contrastText: "#744A2F",
        },
        grey: {
          A100: "#FAFAFA",
          A200: "#EEEEEE",
          A400: "#E5E5E5",
        },
      },
    },
    jaJP
  );

  theme = responsiveFontSizes(theme);

  const { instance } = useMsal();

  useEffect(() => {
    const callbackId = instance.addEventCallback((event: any) => {
      if (event.eventType === EventType.LOGIN_FAILURE) {
        if (
          event.error &&
          (event.error.errorMessage.indexOf("AADB2C90037") > -1 || event.error.errorMessage.indexOf("AADB2C90118") > -1)
        ) {
          if (event.interactionType === InteractionType.Redirect) {
            instance.loginRedirect(b2cPolicies.authorities.forgotPassword);
          } else if (event.interactionType === InteractionType.Popup) {
            instance.loginPopup(b2cPolicies.authorities.forgotPassword).catch((e) => {
              return;
            });
          }
        } else if (
          event.error &&
          (event.error.errorMessage.indexOf("AADB2C90091") > -1 || event.error.errorMessage.indexOf("AADB2C90276") > -1)
        ) {
          if (event.interactionType === InteractionType.Redirect) {
            instance.loginRedirect();
          } else if (event.interactionType === InteractionType.Popup) {
            instance.loginPopup().catch((e) => {
              return;
            });
          }
        }
      }

      if (event.eventType === EventType.LOGIN_SUCCESS) {
        if (event?.payload) {
          if (event.payload.idTokenClaims["acr"] === b2cPolicies.names.forgotPassword) {
            window.alert("パスワードは正常にリセットされました。 \n新しいパスワードでサインインしてください。");
            return instance.logout();
          }
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance]);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ja}>
          <CssBaseline />

          <UnauthenticatedTemplate>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/guidance" element={<Guidance />} />
            </Routes>
          </UnauthenticatedTemplate>

          <AuthenticatedTemplate>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/search" element={<RouteSearch />} />
              <Route path="/search/options" element={<RouteOptions />} />
              <Route path="/search/location/:id" element={<RouteLocationChange />} />
              <Route path="/search/results" element={<RouteSearchResults />} />
              <Route path="/search/results/:id" element={<RouteDetails />} />
              <Route path="/about" element={<About />} />
              <Route path="/mail" element={<ModificationMailAddress />}/>
              <Route path="/pois" element={<Pois />} />
              <Route path="/pois/:id" element={<PoiDetails />} />
              <Route path="/dispach-menu" element={<DispachMenu />} />
              <Route path="/dispach-taxi" element={<DispachTaxi />} />
              <Route path='/' element={<AuthKagaRoute/>}>
                <Route path="/dispach-noriaigo" element={<DispachNoriaigo />} />
                <Route path="/noriaigo/search" element={<RouteSearchForNoriaigo />} />
                <Route path="/noriaigo/search/map/:id" element={<LocationMapForNoriaigo />} />
                <Route path="/noriaigo/search/location/:id" element={<RouteLocationChangeForNoriaigo />} />
                <Route path="/noriaigo/reservation/candidate" element={<ReservationCandidateForNoriaigo />} />
                <Route path="/noriaigo/reservation/confirmation/:id" element={<ReservationConfirmationForNoriaigo />} />
                <Route path="/noriaigo/reservation/completion/:id" element={<ReservationCompletionForNoriaigo />} />
                <Route path="/noriaigo/reservation" element={<ReservationListForNoriaigo />} />
                <Route path="/noriaigo/reservation/:id" element={<ReservationDetailForNoriaigo />} />
                <Route path="/noriaigo/reservation/cancel" element={<ReservationCancelForNoriaigo />} />
              </Route>
              <Route path="/busstops" element={<BusStops />} />
              <Route path="/resign" element={<ResignMembership />} />
            </Routes>
          </AuthenticatedTemplate>
        </LocalizationProvider>
      </ThemeProvider>
    </div>
  );
}

export default withAITracking(reactPlugin, App);
