import { Dialog, DialogContent, DialogActions, List, ListItem, ListItemText, Button } from "@mui/material";

type PropsType = {
  primaryText: string;
  handleClick: () => void;
};

const ErrorDialog = (props: PropsType) => {
  const { primaryText, handleClick } = props;
  return (
    <>
      <Dialog open={true}>
        <DialogContent>
          <List>
            <ListItem>
              <ListItemText primary={primaryText} />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClick}>{"OK"}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ErrorDialog;
